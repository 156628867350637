import { useApi } from '@/api';
import { HealthRate, Link } from '@/components';
import CurrencyValue from '@/components/CurrencyValue/CurrencyValue';
import PoolName from '@/components/PoolName/PoolName';
import RelativeDateTime from '@/components/RelativeDateTime/RelativeDateTime';
import WalletAddress from '@/components/WalletAddress/WalletAddress';
import TableLoader from '@/components/loaders/TableLoader';
import Table from '@/components/table/Table';
import { useTableProps } from '@blockanalitica/ui';

export default function Positions({ daysAgo, filters, searchText }) {
  const { sorting, pagination } = useTableProps({
    pageSize: 10,
    initialSorting: '-borrow_usd'
  });
  const [data, error] = useApi({
    url: '/positions/',
    queryParams: {
      p: pagination.currentPage,
      p_size: pagination.pageSize,
      days_ago: daysAgo,
      order: sorting.currentSorting,
      type: 'borrowers',
      search: searchText
    }
  });

  if (error) {
    throw error;
  }

  if (data) {
    const columns = [
      {
        header: 'WALLET',
        getCell: (row) => (
          <WalletAddress
            options={{
              size: 'xSmall'
            }}
            address={row.wallet_address}
          />
        )
      },
      {
        header: 'MARKET',
        getCell: (row) => (
          <PoolName
            loanSymbol={row.loan_token_symbol}
            collateralSymbol={row.collateral_token_symbol}
            lltv={row.lltv}
          />
        )
      },
      {
        header: 'COLLATERAL',
        getCell: (row) => (
          <>
            {row.collateral_token_symbol ? (
              <>
                {filters.filtersApplied.currency === 'USD' ? (
                  <CurrencyValue value={row.collateral_usd} currency="USD" />
                ) : (
                  <CurrencyValue
                    value={row.collateral}
                    currency={row.collateral_token_symbol}
                    iconSize="small"
                  />
                )}
              </>
            ) : (
              '-'
            )}
          </>
        ),
        getCellBottom: (row) => (
          <>
            {filters.filtersApplied.currency === 'USD' ? (
              <CurrencyValue
                value={row[`collateral_change_${daysAgo}d_usd`]}
                currency="USD"
                trend
              />
            ) : (
              <CurrencyValue
                value={row[`collateral_change_${daysAgo}d`]}
                currency={row.collateral_token_symbol}
                iconSize="small"
                trend
              />
            )}
          </>
        ),
        sortingField: 'collateral_usd',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      },
      {
        header: 'BORROW',
        getCell: (row) => (
          <>
            {row.collateral_token_symbol ? (
              <>
                {filters.filtersApplied.currency === 'USD' ? (
                  <CurrencyValue value={row.borrow_usd} currency="USD" />
                ) : (
                  <CurrencyValue
                    value={row.borrow}
                    currency={row.loan_token_symbol}
                    iconSize="small"
                  />
                )}
              </>
            ) : (
              '-'
            )}
          </>
        ),
        getCellBottom: (row) => (
          <>
            {filters.filtersApplied.currency === 'USD' ? (
              <CurrencyValue
                value={row[`borrow_change_${daysAgo}d_usd`]}
                currency="USD"
                trend
              />
            ) : (
              <CurrencyValue
                value={row[`borrow_change_${daysAgo}d`]}
                currency={row.loan_token_symbol}
                iconSize="small"
                trend
              />
            )}
          </>
        ),
        sortingField: 'borrow_usd',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      },
      {
        header: 'HEALTH RATE',
        getCell: (row) => <HealthRate value={row.health_rate} />,
        sortingField: 'health_rate',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      },
      {
        header: 'LATEST ACTIVITY',
        getCell: (row) => <RelativeDateTime value={row.datetime} />,
        sortingField: 'datetime',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      }
    ];

    return (
      <Table
        rows={data['results']}
        columns={columns}
        getKey={(row) => `${row.market_uid}${row.wallet_address}`}
        getLinkWrapper={(row) => (
          <Link to={`/markets/${row.market_uid}/wallets/${row.wallet_address}`} />
        )}
        sorting={sorting}
        pagination={{
          ...pagination,
          totalRecords: data.count
        }}
      />
    );
  }
  return <TableLoader />;
}
