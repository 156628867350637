import { useApi } from '@/api';
import { Page } from '@/components';
import { SearchInput } from '@/components/forms';
import { TimeFilter, TimeOptions } from '@/components/global';
import { useSearchParams } from '@/hooks';
import { Flex, useTableProps } from '@blockanalitica/ui';
import { useParams } from 'react-router-dom';
import DepositorsTable from './DepositorsTable';
import VaultName from '@/components/vault/VaultName';
import { useMemo } from 'react';
import TableLoader from '@/components/loaders/TableLoader';

export default function VaultDepositors() {
  const { vaultAddress } = useParams();
  const [daysAgo] = useSearchParams('days_ago', 1);
  const [searchText, setSearchText] = useSearchParams('search', '');
  const { sorting, pagination } = useTableProps({
    pageSize: 15,
    initialSorting: '-balance'
  });
  const [data, error] = useApi({
    url: `/vaults/${vaultAddress}/depositors/`,
    queryParams: {
      p: pagination.currentPage,
      p_size: pagination.pageSize,
      days_ago: daysAgo,
      order: sorting.currentSorting,
      search: searchText
    }
  });

  const content = useMemo(() => {
    if (data) {
      return <DepositorsTable tableProps={{ data, sorting, pagination, daysAgo }} />;
    }
    return <TableLoader />;
  }, [data, sorting, pagination, daysAgo]);

  if (error) {
    throw error;
  }

  return (
    <Page
      title={
        <Flex gap="space-xs">
          {data ? (
            <VaultName
              assetSymbol={data.asset_symbol}
              symbol={data.symbol}
              name={data.name}
              iconSize={40}
            />
          ) : null}
          Depositors
        </Flex>
      }
      actions={<TimeFilter defaultValue={TimeOptions['1D']} />}>
      <Flex
        gap="space-xs"
        direction={['row', 'row', 'row', 'column']}
        justifyContent={['flex-end', 'flex-end', 'flex-end', 'space-between']}>
        <SearchInput
          placeholder="Search by address"
          defaultValue={searchText}
          onValueChange={(value) => setSearchText(value)}
        />
      </Flex>
      {content}
    </Page>
  );
}
