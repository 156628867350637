import { CryptoIcon } from '@/components';
import { Flex } from '@blockanalitica/ui';

export default function TokenIcons({ tokens }) {
  const num = 4;
  let tokensToShow = tokens?.slice(0, num);

  return (
    <Flex direction={['row']} gap="space-3xs" alignItems="center">
      {tokensToShow.map((token) => (
        <CryptoIcon key={token} name={token} size="medium" />
      ))}

      {tokens.length > num ? (
        <span className="text-1"> +{tokens.length - num} more</span>
      ) : null}
    </Flex>
  );
}
