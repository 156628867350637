import { useApi } from '@/api';
import { Page } from '@/components';
import SelectFilter from '@/components/filters/SelectFilter';
import TableLoader from '@/components/loaders/TableLoader';
import { Flex, useTableProps } from '@blockanalitica/ui';
import { VaultName } from '@/components/vault';
import { VAULT_ALLOCATION_MAPPING, VAULT_ALLOCATION_EVENTS } from '@/constants';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import AllocationEventsTable from './AllocatioinEventsTable';
export default function AllocationEvents() {
  const { vaultAddress } = useParams();
  const selectFilterOptions = useMemo(() => {
    const vaultEvents = VAULT_ALLOCATION_EVENTS.map((event) => ({
      label: VAULT_ALLOCATION_MAPPING[event],
      value: event
    }));

    const options = [{ label: 'All events', value: '' }, ...vaultEvents];

    return options;
  }, []);

  const { sorting, filters, pagination } = useTableProps({
    pageSize: 15,
    initialSorting: '-order_index',
    filtersDebounceTime: 50
  });
  const [data, error] = useApi({
    url: `/vaults/${vaultAddress}/allocation/`,
    queryParams: {
      p: pagination.currentPage,
      p_size: pagination.pageSize,
      order: sorting.currentSorting,
      event: filters.filtersApplied.eventType
    }
  });

  const content = useMemo(() => {
    if (data) {
      return <AllocationEventsTable tableProps={{ data, sorting, pagination }} />;
    }
    return <TableLoader />;
  }, [data, sorting, pagination]);

  if (error) {
    throw error;
  }

  return (
    <Page
      title={
        <Flex gap="space-xs">
          {data ? (
            <VaultName
              assetSymbol={data.asset_symbol}
              symbol={data.symbol}
              name={data.name}
              iconSize={40}
            />
          ) : null}
          Allocation Events
        </Flex>
      }>
      <Flex gap="space-xs" justifyContent="flex-end">
        <SelectFilter
          key="event-filter"
          placeholder="Showing all events"
          filterField="eventType"
          options={selectFilterOptions}
          onPageChange={pagination.onPageChange}
          {...filters}
        />
      </Flex>

      {content}
    </Page>
  );
}
