import {
  Box,
  CopyButton,
  DebankPictogram,
  EtherScanPictogram,
  Flex,
  ZapperPictogram,
  addressShortener
} from '@blockanalitica/ui';
import classnames from 'classnames';
import makeBlockie from 'ethereum-blockies-base64';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './WalletAddress.module.scss';

export type TSize = 'xSmall' | 'small' | 'medium' | 'large' | 'xLarge';

export type TUrl = 'ethereum' | 'sepolia' | 'base';

type WalletAddressOptions = {
  name?: string;
  identicon?: boolean;
  inline?: boolean;
  size?: TSize;
  showCopyBtn?: boolean;
  showEthScanLink?: boolean;
  showDebankLink?: boolean;
  showZapperLink?: boolean;
  network?: TUrl;
};

type WalletAddressProps = {
  address: string;
  options: WalletAddressOptions;
};

const addressSizeMap = {
  xSmall: 'text-2',
  small: 'text-3',
  medium: 'text-4',
  large: 'text-5',
  xLarge: 'text-6'
};

const nameSizeMap = {
  xSmall: 'text-1',
  small: 'text-1',
  medium: 'text-2',
  large: 'text-3',
  xLarge: 'text-4'
};

const buttonsSizeMap = {
  xSmall: 10,
  small: 12,
  medium: 12,
  large: 14,
  xLarge: 16
};

const urlMap = {
  ethereum: 'https://etherscan.io/address/',
  sepolia: 'https://sepolia.etherscan.io/address/',
  base: 'https://basescan.org/address/'
};

export default function WalletAddress({
  address,
  options: incomingOptions
}: WalletAddressProps) {
  const options = useMemo(
    () => ({
      identicon: true,
      inline: false,
      size: 'medium',
      showCopyBtn: false,
      showEthScanLink: false,
      showDebankLink: false,
      showZapperLink: false,
      network: 'ethereum',
      ...incomingOptions
    }),
    [incomingOptions]
  );

  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  options.network = pathSegments[1];

  return (
    <Flex gap="space-xs" alignItems="center">
      {options.identicon && !options.inline ? (
        <img
          alt="Copy address"
          src={makeBlockie(address)}
          className={classnames(styles.blockie, {
            [styles.blockieXSmall]: options.size === 'xSmall',
            [styles.blockieSmall]: options.size === 'small',
            [styles.blockieMedium]: options.size === 'medium',
            [styles.blockieLarge]: options.size === 'large',
            [styles.blockieXLarge]: options.size === 'xLarge'
          })}
        />
      ) : null}

      <Flex
        direction={options.inline ? 'row' : 'column'}
        gap={options.inline ? 'space-2xs' : 'space-0'}
        alignItems={options.inline ? 'baseline' : undefined}>
        <Flex gap="space-2xs" alignItems="baseline">
          <Flex direction="column">
            <Box className={addressSizeMap[options.size as TSize]}>
              {addressShortener({ address })}
            </Box>
            {options.name ? (
              <Box
                className={classnames(
                  'text-muted',
                  nameSizeMap[options.size as TSize]
                )}>
                {options.name}
              </Box>
            ) : null}
          </Flex>

          {!options.showCopyBtn || options.inline ? null : (
            <CopyButton
              size={buttonsSizeMap[options.size as TSize]}
              textToCopy={address}
            />
          )}
        </Flex>

        <Flex gap="space-2xs">
          {options.showCopyBtn && options.inline ? (
            <CopyButton
              size={buttonsSizeMap[options.size as TSize]}
              textToCopy={address}
            />
          ) : null}

          {options.showEthScanLink ? (
            <a
              href={urlMap[options.network as TUrl] + address}
              target="_blank"
              rel="noreferrer">
              <EtherScanPictogram
                className={classnames(styles.linkIcon)}
                size={buttonsSizeMap[options.size as TSize]}
              />
            </a>
          ) : null}

          {options.showDebankLink ? (
            <a
              href={'https://debank.com/profile/' + address}
              target="_blank"
              rel="noreferrer">
              <DebankPictogram size={buttonsSizeMap[options.size as TSize]} />
            </a>
          ) : null}

          {options.showZapperLink ? (
            <a
              href={'https://zapper.xyz/account/' + address}
              target="_blank"
              rel="noreferrer">
              <ZapperPictogram size={buttonsSizeMap[options.size as TSize]} />
            </a>
          ) : null}
        </Flex>
      </Flex>
    </Flex>
  );
}
