import { useApi } from '@/api';
import { Link } from '@/components';
import CurrencyValue from '@/components/CurrencyValue/CurrencyValue';
import RelativeDateTime from '@/components/RelativeDateTime/RelativeDateTime';
import WalletAddress from '@/components/WalletAddress/WalletAddress';
import TableLoader from '@/components/loaders/TableLoader';
import Table from '@/components/table/Table';
import { useTableProps } from '@blockanalitica/ui';
import TokenIcons from './TokenIcons';

export default function SuppliersTable({ daysAgo, searchText }) {
  const { sorting, pagination } = useTableProps({
    pageSize: 10,
    initialSorting: '-supply'
  });
  const [data, error] = useApi({
    url: '/wallets/',
    queryParams: {
      p: pagination.currentPage,
      p_size: pagination.pageSize,
      type: 'suppliers',
      order: sorting.currentSorting,
      search: searchText
    }
  });

  if (error) {
    throw error;
  }

  if (data) {
    const columns = [
      {
        header: 'WALLET',
        getCell: (row) => (
          <WalletAddress
            options={{
              size: 'xSmall'
            }}
            address={row.wallet_address}
          />
        ),
        sortingField: 'wallet_address'
      },
      {
        header: 'TOKENS SUPPLIED',
        getCell: (row) => <TokenIcons tokens={row.tokens} />,
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      },
      {
        header: 'SUPPLY',
        getCell: (row) => <CurrencyValue value={row.supply} currency="USD" />,
        getCellBottom: (row) => (
          <CurrencyValue
            value={row[`supply_change_${daysAgo}d_usd`]}
            currency="USD"
            trend
          />
        ),
        sortingField: 'supply',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      },
      {
        header: 'LATEST ACTIVITY',
        getCell: (row) => <RelativeDateTime value={row.latest_activity} />,
        sortingField: 'latest_activity',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      }
    ];
    return (
      <Table
        rows={data['results']}
        getKey={(row) => row.wallet_address}
        getLinkWrapper={(row) => <Link to={`/wallets/${row.wallet_address}`}></Link>}
        columns={columns}
        sorting={sorting}
        pagination={{
          ...pagination,
          totalRecords: data.count
        }}
      />
    );
  }

  return <TableLoader />;
}
