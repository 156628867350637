import { useApi } from '@/api';
import { Card } from '@/components';
import CurrencyValue from '@/components/CurrencyValue/CurrencyValue';
import DisplaySwitch from '@/components/DisplaySwitch/DisplaySwitch';
import { BarChart, LineChart, TimeSelector } from '@/components/charts';
import { formatCurreny } from '@/utils';
import { CardLoader, Flex, formatPercentage } from '@blockanalitica/ui';
import { useMemo, useState } from 'react';

export default function HistoricTabs({ vaultAddress, assetSymbol }) {
  const [daysAgo, setDaysAgo] = useState(30);
  const [activeDisplayOption, setActiveDisplayOption] = useState('total_supply');
  const [data, error] = useApi({
    url: `/vaults/${vaultAddress}/historic/`,
    queryParams: {
      days_ago: daysAgo
    }
  });

  if (error) {
    throw error;
  }

  const displayOptions = useMemo(
    () => [
      { key: 'total_supply', value: 'Deposits' },
      { key: 'supply_rate_apy', value: 'APY' },
      { key: 'total_rate_apy', value: 'NET APY' },
      { key: 'volume', value: 'Volume' },
      { key: 'deposit_withdraw', value: 'Deposit / Withdraw' }
    ],
    []
  );

  if (data) {
    let content = undefined;
    let valueFormatter = (value) => (
      <CurrencyValue value={value} currency={assetSymbol} />
    );
    let yScaleValueFormatter = (value) => formatCurreny(value, assetSymbol);
    let fields = undefined;
    switch (activeDisplayOption) {
      case 'total_supply':
        content = (
          <LineChart
            key={activeDisplayOption}
            data={data}
            field={activeDisplayOption}
            valueFormatter={valueFormatter}
            yScaleValueFormatter={yScaleValueFormatter}
            aspectRatio={3}
          />
        );
        break;
      case 'supply_rate_apy':
        valueFormatter = (value) => formatPercentage(value);
        yScaleValueFormatter = (value) => formatPercentage(value);
        content = (
          <LineChart
            key={activeDisplayOption}
            data={data}
            field={activeDisplayOption}
            valueFormatter={valueFormatter}
            yScaleValueFormatter={yScaleValueFormatter}
            aspectRatio={3}
          />
        );
        break;
      case 'total_rate_apy':
        valueFormatter = (value) => formatPercentage(value);
        yScaleValueFormatter = (value) => formatPercentage(value);
        content = (
          <LineChart
            key={activeDisplayOption}
            data={data}
            field={activeDisplayOption}
            valueFormatter={valueFormatter}
            yScaleValueFormatter={yScaleValueFormatter}
            aspectRatio={3}
          />
        );
        break;
      case 'volume':
        fields = [
          {
            field: 'volume',
            label: 'Volume',
            backgroundColor: '#5792ff'
          }
        ];
        content = (
          <BarChart
            key={activeDisplayOption}
            data={data}
            fields={fields}
            valueFormatter={valueFormatter}
            yScaleValueFormatter={yScaleValueFormatter}
            aspectRatio={3}
          />
        );
        break;
      case 'deposit_withdraw':
        fields = [
          {
            field: 'volume_deposit',
            label: 'Deposit',
            backgroundColor: '#008000'
          },
          {
            field: 'volume_withdraw',
            label: 'Withdraw',
            backgroundColor: '#FF0000'
          }
        ];
        content = (
          <BarChart
            key={activeDisplayOption}
            data={data}
            fields={fields}
            valueFormatter={valueFormatter}
            yScaleValueFormatter={yScaleValueFormatter}
            aspectRatio={3}
          />
        );
        break;
    }

    return (
      <Card style={{ width: '100%' }}>
        <Flex direction="column" gap="space-xs">
          <Flex
            direction={['row', 'row', 'column']}
            alignItems={['flex-start', 'flex-start', 'center']}
            justifyContent="space-between"
            gap="space-xs">
            <DisplaySwitch
              options={displayOptions}
              activeOption={activeDisplayOption}
              onChange={setActiveDisplayOption}
            />
            <TimeSelector
              activeOption={daysAgo}
              onChange={(e) => setDaysAgo(e.target.value)}
            />
          </Flex>
          {content}
        </Flex>
      </Card>
    );
  }

  return <CardLoader headerHeight="3rem" bodyHeight="7rem" />;
}
