import Svg from './Svg';

export default function MarketIcon({ size = 32, color = 'currentColor', ...props }) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 14 14"
      width={size + 'px'}
      height={size + 'px'}
      {...props}>
      <g id="dashboard-circle--app-application-dashboard-home-layout-circle">
        <path
          id="Vector"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M3.25 6C4.76878 6 6 4.76878 6 3.25S4.76878 0.5 3.25 0.5 0.5 1.73122 0.5 3.25 1.73122 6 3.25 6Z"
          strokeWidth={1}
        />
        <path
          id="Vector_2"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M10.75 6c1.5188 0 2.75 -1.23122 2.75 -2.75S12.2688 0.5 10.75 0.5C9.23122 0.5 8 1.73122 8 3.25S9.23122 6 10.75 6Z"
          strokeWidth={1}
        />
        <path
          id="Vector_3"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M3.25 13.5C4.76878 13.5 6 12.2688 6 10.75 6 9.23122 4.76878 8 3.25 8S0.5 9.23122 0.5 10.75c0 1.5188 1.23122 2.75 2.75 2.75Z"
          strokeWidth={1}
        />
        <path
          id="Vector_4"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M10.75 13.5c1.5188 0 2.75 -1.2312 2.75 -2.75C13.5 9.23122 12.2688 8 10.75 8 9.23122 8 8 9.23122 8 10.75c0 1.5188 1.23122 2.75 2.75 2.75Z"
          strokeWidth={1}
        />
      </g>
    </Svg>
  );
}
