export const EVENT_TYPE_MAPPING = {
  Borrow: 'Borrow',
  Repay: 'Repay',
  Supply: 'Supply',
  SupplyCollateral: 'Supply Collateral',
  Withdraw: 'Withdraw',
  WithdrawCollateral: 'Withdraw Collateral'
};

export const VAULT_DEPOSITORS_MAPPING = {
  Deposit: 'Deposit',
  Withdraw: 'Withdraw'
};

export const VAULT_DEPOSITORS_EVENTS = ['Deposit', 'Withdraw'];

export const WALLET_EVENTS = [
  'Borrow',
  'Repay',
  'Supply',
  'SupplyCollateral',
  'Withdraw',
  'WithdrawCollateral'
];

export const CONFIG_EVENTS = [
  'OwnershipTransferred',
  'ReallocateSupply',
  'ReallocateWithdraw',
  'RevokePendingCap',
  'RevokePendingGuardian',
  'RevokePendingMarketRemoval',
  'RevokePendingTimelock',
  'SetCap',
  'SetCurator',
  'SetFee',
  'SetFeeRecipient',
  'SetGuardian',
  'SetIsAllocator',
  'SetSkimRecipient',
  'SetSupplyQueue',
  'SetTimelock',
  'SetWithdrawQueue',
  'SubmitCap',
  'SubmitGuardian',
  'SubmitMarketRemoval',
  'SubmitTimelock'
];

export const CONFIG_EVENT_TYPE_MAPPING = {
  OwnershipTransferred: 'Ownership Transferred',
  ReallocateSupply: 'Reallocate Supply',
  ReallocateWithdraw: 'Reallocate Withdraw',
  RevokePendingCap: 'Revoke Pending Cap',
  RevokePendingGuardian: 'Revoke Pending Guardian',
  RevokePendingMarketRemoval: 'Revoke Pending Market Removal',
  RevokePendingTimelock: 'Revoke Pending Timelock',
  SetCap: 'Set Cap',
  SetCurator: 'Set Curator',
  SetFee: 'Set Fee',
  SetFeeRecipient: 'Set Fee Recipient',
  SetGuardian: 'Set Guardian',
  SetIsAllocator: 'Set Is Allocator',
  SetSkimRecipient: 'Set Skim Recipient',
  SetSupplyQueue: 'Set Supply Queue',
  SetTimelock: 'Set Timelock',
  SetWithdrawQueue: 'Set Withdraw Queue',
  SubmitCap: 'Submit Cap',
  SubmitGuardian: 'Submit Guardian',
  SubmitMarketRemoval: 'Submit Market Removal',
  SubmitTimelock: 'Submit Timelock'
};

export const VAULT_ALLOCATION_MAPPING = {
  Supply: 'Supply',
  Withdraw: 'Withdraw'
};

export const VAULT_ALLOCATION_EVENTS = ['Supply', 'Withdraw'];

export const LST = ['weETH', 'osETH', 'rETH', 'wstETH'];

export const STABLECOINS = [
  'DAI',
  'USDC',
  'USDT',
  'TUSD',
  'sUSD',
  'BUSD',
  'PAX',
  'GUSD',
  'HUSD',
  'sDAI',
  'wbIB01'
];
