import { ReactNode, useCallback, useMemo, useState } from 'react';
import styles from './Tabs.module.scss';
import classnames from 'classnames';
import { Flex } from '@blockanalitica/ui';

export type Tab = {
  label: string;
  content: ReactNode;
};

export type TabsOptions = {
  externalStateHandler?: {
    activeTab: string;
    onTabClick: (tab: Tab) => void;
  };
};

export type TabsProps = {
  data: Tab[];
  options?: TabsOptions;
};

export default function Tabs({ data, options }: TabsProps) {
  const [internalActiveTabState, setInternalActiveTabState] = useState(data[0].label);

  const handleClick = useCallback(
    (tab: Tab) => {
      if (options?.externalStateHandler) {
        options.externalStateHandler.onTabClick(tab);

        return;
      }

      setInternalActiveTabState(tab.label);
    },
    [options?.externalStateHandler]
  );

  const activeTab = useMemo(() => {
    if (options?.externalStateHandler) {
      return options.externalStateHandler.activeTab;
    }

    return internalActiveTabState;
  }, [internalActiveTabState, options?.externalStateHandler]);

  return (
    <Flex direction="column" gap="space-xs-s" className={styles.container}>
      <Flex className={styles.wrapper} gap="space-l">
        {data.map((tab, index) => (
          <div
            key={tab.label + index}
            className={classnames(styles.tab, {
              [styles.active]: tab.label === activeTab
            })}
            onClick={() => handleClick(tab)}>
            {tab.label}
          </div>
        ))}
      </Flex>

      {data.map(({ label, content }, index) => (
        <TabContent key={label + index} active={label === activeTab}>
          {content}
        </TabContent>
      ))}
    </Flex>
  );
}

type TabContentProps = {
  active: boolean;
  children: ReactNode;
};

function TabContent({ active, children }: TabContentProps) {
  if (active) {
    return children;
  }

  return null;
}
