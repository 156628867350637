import { ErrorBoundary } from '@/components';
import HomeIcon from '@/components/icons/HomeIcon';
import { Flex, useIsMobile } from '@blockanalitica/ui';
import * as Sentry from '@sentry/react';
import { ReactNode, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import NetworkSwitch from '../NetworkSwitch/NetworkSwitch';

type PageProps = {
  title: string | ReactNode;
  actions?: ReactNode;
  children: ReactNode;
};

export default function Page({ title, actions, children }: PageProps) {
  const { pathname } = useLocation();

  const crumbs = useMemo(() => {
    const pathArray = pathname.split('/').filter((v) => v.length > 0);
    const crumbsList = [{ href: '/', text: <HomeIcon size={16} /> }];

    crumbsList.push(
      ...pathArray.map((subPath, index) => {
        const href = '/' + pathArray.slice(1, index + 1).join('/');
        const text = subPath[0].toUpperCase() + subPath.slice(1);
        return { href, text };
      })
    );

    return crumbsList;
  }, [pathname]);

  const isMobile = useIsMobile();

  return (
    <Sentry.ErrorBoundary fallback={<ErrorBoundary />}>
      <Flex direction="column" gap="space-xs">
        <Flex direction="column" gap="space-xs">
          <Flex direction="row" justifyContent="space-between">
            {!isMobile && crumbs.length > 1 ? <Breadcrumbs crumbs={crumbs} /> : null}
            {!isMobile && crumbs.length > 1 && <NetworkSwitch />}
          </Flex>
          {isMobile && crumbs.length > 1 && (
            <Flex direction="row" justifyContent="flex-end">
              <NetworkSwitch />
            </Flex>
          )}

          <Flex justifyContent="space-between">
            <h1>{title}</h1>
            {actions ? <Flex alignItems="center">{actions}</Flex> : null}
          </Flex>
        </Flex>

        {children}
      </Flex>
    </Sentry.ErrorBoundary>
  );
}
