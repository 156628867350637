import { useApi } from '@/api';
import { Card } from '@/components';
import CurrencyValue from '@/components/CurrencyValue/CurrencyValue';
import DisplaySwitch from '@/components/DisplaySwitch/DisplaySwitch';
import { LineChart, TimeSelector } from '@/components/charts';
import { formatCurreny } from '@/utils';
import { CardLoader, Flex, formatNumber } from '@blockanalitica/ui';
import { useMemo, useState } from 'react';

export default function HistoricTabs({
  poolUid,
  loanSymbol,
  collateralSymbol,
  switchValue
}) {
  const [daysAgo, setDaysAgo] = useState(30);
  const [activeDisplayOption, setActiveDisplayOption] = useState('available_liquidity');
  const [data, error] = useApi({
    url: `/markets/${poolUid}/historic/`,
    queryParams: {
      days_ago: daysAgo
    }
  });

  if (error) {
    throw error;
  }

  const displayOptions = useMemo(
    () => [
      { key: 'available_liquidity', value: 'Liquidity' },
      { key: 'total_supply', value: 'Supply' },
      { key: 'total_borrow', value: 'Borrow' },
      { key: 'total_collateral', value: 'Collateral' },
      { key: 'tvl', value: 'TVL' }
    ],
    []
  );

  if (data) {
    let valueFormatter = undefined;
    let yScaleValueFormatter = undefined;
    switch (activeDisplayOption) {
      case 'available_liquidity':
        valueFormatter = (value) =>
          switchValue == 'token' ? (
            <CurrencyValue value={value} currency={loanSymbol} iconSize={30} />
          ) : (
            <CurrencyValue value={value} currency="USD" />
          );
        yScaleValueFormatter = (value) =>
          switchValue == 'token'
            ? formatCurreny(value, loanSymbol)
            : formatCurreny(value);
        break;
      case 'tvl':
        valueFormatter = (value) => <CurrencyValue value={value} currency="USD" />;
        break;
      case 'total_supply':
        valueFormatter = (value) =>
          switchValue == 'token' ? (
            <CurrencyValue value={value} currency={loanSymbol} iconSize={30} />
          ) : (
            <CurrencyValue value={value} currency="USD" />
          );
        yScaleValueFormatter = (value) =>
          switchValue == 'token'
            ? formatCurreny(value, loanSymbol)
            : formatCurreny(value);
        break;
      case 'total_borrow':
        valueFormatter = (value) =>
          switchValue == 'token' ? (
            <CurrencyValue value={value} currency={loanSymbol} iconSize={30} />
          ) : (
            <CurrencyValue value={value} currency="USD" />
          );
        yScaleValueFormatter = (value) =>
          switchValue == 'token'
            ? formatCurreny(value, loanSymbol)
            : formatCurreny(value);
        break;
      case 'total_collateral':
        valueFormatter = (value) =>
          switchValue == 'token' ? (
            <CurrencyValue value={value} currency={collateralSymbol} iconSize={30} />
          ) : (
            <CurrencyValue value={value} currency="USD" />
          );
        yScaleValueFormatter = (value) =>
          switchValue == 'token'
            ? formatCurreny(value, collateralSymbol)
            : formatCurreny(value);
        break;
      default:
        valueFormatter = (value) => formatNumber(value, null);
        yScaleValueFormatter = (value) => formatNumber(value);
    }

    return (
      <Card>
        <Flex direction="column" gap="space-xs">
          <Flex
            direction={['row', 'row', 'column']}
            alignItems={['flex-start', 'flex-start', 'center']}
            justifyContent="space-between"
            gap="space-xs">
            <DisplaySwitch
              options={displayOptions}
              activeOption={activeDisplayOption}
              onChange={setActiveDisplayOption}
            />

            <TimeSelector
              activeOption={daysAgo}
              onChange={(e) => setDaysAgo(e.target.value)}
            />
          </Flex>
          <LineChart
            key={activeDisplayOption}
            data={data}
            field={activeDisplayOption}
            valueFormatter={valueFormatter}
            yScaleValueFormatter={yScaleValueFormatter}
            switchValue={switchValue}
            daysAgo={daysAgo}
          />
        </Flex>
      </Card>
    );
  }

  return <CardLoader headerHeight="3rem" bodyHeight="7rem" />;
}
