import Svg from './Svg';

export default function SearchIcon({ size = 32, color = 'currentColor', ...props }) {
  return (
    <Svg
      x="0px"
      y="0px"
      viewBox="-0.5 -0.5 14 14"
      width={size + 'px'}
      height={size + 'px'}
      fill="none"
      {...props}>
      <g id="magnifying-glass--glass-search-magnifying">
        <path
          id="Vector"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M5.571428571428571 10.678571428571429c2.8206007142857143 0 5.107142857142858 -2.286542142857143 5.107142857142858 -5.107142857142858S8.392029285714287 0.4642857142857143 5.571428571428571 0.4642857142857143 0.4642857142857143 2.7508278571428573 0.4642857142857143 5.571428571428571s2.286542142857143 5.107142857142858 5.107142857142858 5.107142857142858Z"
          strokeWidth="1"></path>
        <path
          id="Vector_2"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12.535714285714286 12.535714285714286 9.285714285714286 9.285714285714286"
          strokeWidth="1"></path>
      </g>
    </Svg>
  );
}
