import { useApi } from '@/api';
import { Card } from '@/components';
import CurrencyValue from '@/components/CurrencyValue/CurrencyValue';
import DisplaySwitch from '@/components/DisplaySwitch/DisplaySwitch';
import { BarChart, LineChart, TimeSelector } from '@/components/charts';
import { CardLoader, Flex } from '@blockanalitica/ui';
import { useMemo, useState } from 'react';

export default function HistoricTabs() {
  const [daysAgo, setDaysAgo] = useState(30);
  const [activeDisplayOption, setActiveDisplayOption] = useState('total_deposits');
  const [data, error] = useApi({
    url: '/overall/historic/',
    queryParams: {
      days_ago: daysAgo
    }
  });

  if (error) {
    throw error;
  }

  const displayOptions = useMemo(
    () => [
      { key: 'total_deposits', value: 'Deposits' },
      { key: 'tvl', value: 'TVL' },
      { key: 'total_supply', value: 'Supply' },
      { key: 'total_borrow', value: 'Borrow' },
      { key: 'total_collateral', value: 'Collateral' }
      // { key: 'volume', value: 'Volume' }
    ],
    []
  );

  if (data) {
    let content = null;
    let valueFormatter = (value) => <CurrencyValue value={value} currency="USD" />;

    if (activeDisplayOption === 'volume') {
      const fields = [
        {
          field: 'volume',
          label: 'Volume',
          backgroundColor: '#5792ff'
        }
      ];
      content = (
        <BarChart
          key={activeDisplayOption}
          data={data}
          fields={fields}
          valueFormatter={valueFormatter}
          aspectRatio={4}
        />
      );
    } else {
      content = (
        <LineChart
          key={activeDisplayOption}
          data={data}
          field={activeDisplayOption}
          valueFormatter={valueFormatter}
          aspectRatio={4}
          daysAgo={daysAgo}
        />
      );
    }
    return (
      <Card>
        <Flex direction="column" gap="space-xs">
          <Flex
            direction={['row', 'row', 'column']}
            alignItems={['flex-start', 'flex-start', 'center']}
            justifyContent="space-between"
            gap="space-xs">
            <DisplaySwitch
              options={displayOptions}
              activeOption={activeDisplayOption}
              onChange={setActiveDisplayOption}
            />
            <TimeSelector
              activeOption={daysAgo}
              onChange={(e) => setDaysAgo(e.target.value)}
            />
          </Flex>
          {content}
        </Flex>
      </Card>
    );
  }

  return <CardLoader headerHeight="3rem" bodyHeight="7rem" />;
}
