import { useApi } from '@/api';
import { Link, Page } from '@/components';
import CurrencyValue from '@/components/CurrencyValue/CurrencyValue';

import { TimeFilter, TimeOptions } from '@/components/global';
import MorphoLogoWhite from '@/components/icons/MorphoLogoWhite';
import KpiCard from '@/components/kpi/KpiCard';
import { useSearchParams } from '@/hooks';
import { Box, Flex } from '@blockanalitica/ui';
import ChainTable from './ChainTable';
import HistoricTabsMorpho from './HistoricTabsMorpho';

export default function Morpho() {
  const [daysAgo] = useSearchParams('days_ago', 1);
  const [data, error] = useApi({
    url: `/`,
    queryParams: {
      days_ago: daysAgo
    }
  });

  if (error) {
    throw error;
  }

  if (data) {
    return (
      <Page
        title={
          <Link to="/">
            <MorphoLogoWhite />
          </Link>
        }
        actions={
          <Flex gap="space-m" width="100%" justifyContent={['space-between']}>
            <TimeFilter defaultValue={TimeOptions['1D']} />
          </Flex>
        }>
        <Flex direction={['row', 'column', 'column']} gap="space-xs">
          <Box flexBasis={['67%', '100%', '100%']}>
            <KpiCard
              title="Total Deposits"
              value={
                <CurrencyValue
                  value={data.total_deposits}
                  currency={'USD'}
                  notation="standard"
                />
              }
              trendValue={
                <CurrencyValue
                  value={data.total_deposits - data.previous_total_deposits}
                  currency={'USD'}
                  trend
                />
              }
              options={{
                valueSize: 'text-6',
                trendValueSize: 'text-3',
                trendValueIcon: true,
                footerSize: 'text-2',
                trendValueFormat: 'currency'
              }}
            />
          </Box>

          <Box flexBasis={['33%', '100%', '100%']}>
            <KpiCard
              title="Total Value Locked"
              value={<CurrencyValue value={data.tvl} currency={'USD'} />}
              trendValue={
                <CurrencyValue
                  value={data.tvl - data.previous_tvl}
                  currency={'USD'}
                  trend
                />
              }
              options={{
                valueSize: 'text-6',
                trendValueSize: 'text-3',
                trendValueIcon: true,
                footerSize: 'text-2',
                trendValueFormat: 'currency'
              }}
            />
          </Box>
        </Flex>
        <Flex direction={['row', 'column', 'column']} gap="space-xs">
          <Box flexBasis={['67%', '100%', '100%']}>
            <HistoricTabsMorpho />
          </Box>

          <Flex flexBasis={['33%', '100%', '100%']} direction="column" gap="space-xs">
            <KpiCard
              title="Total Borrow"
              value={<CurrencyValue value={data.total_borrow} currency={'USD'} />}
              trendValue={
                <CurrencyValue
                  value={data.total_borrow - data.previous_total_borrow}
                  currency={'USD'}
                  trend
                />
              }
              options={{
                valueSize: 'text-5',
                trendValueSize: 'text-3',
                trendValueIcon: true,
                footerSize: 'text-2',
                trendValueFormat: 'currency'
              }}
            />
            <KpiCard
              title="Total Supply"
              value={<CurrencyValue value={data.total_supply} currency={'USD'} />}
              trendValue={
                <CurrencyValue
                  value={data.total_supply - data.previous_total_supply}
                  currency={'USD'}
                  trend
                />
              }
              options={{
                valueSize: 'text-5',
                trendValueSize: 'text-3',
                trendValueIcon: true,
                footerSize: 'text-2',
                trendValueFormat: 'currency'
              }}
            />

            <KpiCard
              style={{ flex: '1' }}
              title="Total Collateral"
              value={<CurrencyValue value={data.total_collateral} currency="USD" />}
              trendValue={
                <CurrencyValue
                  value={data.total_collateral - data.previous_total_collateral}
                  currency={'USD'}
                  trend
                />
              }
              options={{
                valueSize: 'text-5',
                trendValueSize: 'text-3',
                trendValueIcon: true,
                footerSize: 'text-2',
                trendValueFormat: 'currency'
              }}
            />
          </Flex>
        </Flex>
        <ChainTable daysAgo={daysAgo} />
      </Page>
    );
  }

  return <></>;
}
