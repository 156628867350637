import { useApi } from '@/api';
import { Card, KpiCard, Page } from '@/components';
import CurrencyValue from '@/components/CurrencyValue/CurrencyValue';
import WalletAddress from '@/components/WalletAddress/WalletAddress';
import { TimeFilter, TimeOptions } from '@/components/global';
import { KpiSwitchCard } from '@/components/kpi';
import { TokenName } from '@/components/token';
import { useSearchParams } from '@/hooks';
import { CurrencyFilter, Flex, PageLoader, useTableProps } from '@blockanalitica/ui';
import { useParams } from 'react-router-dom';
import PoolsTable from './PoolsTable';

export default function Token() {
  const { address } = useParams();
  const [daysAgo] = useSearchParams('days_ago', 1);
  const [data, error] = useApi({
    url: `/tokens/${address}/`,
    queryParams: {
      days_ago: daysAgo
    }
  });

  const { filters } = useTableProps({
    initialFilters: { currency: 'USD' },
    filtersDebounceTime: 50
  });

  if (error) {
    throw error;
  }

  if (data) {
    return (
      <Page
        title={
          <div className="text-3">
            <TokenName symbol={data.symbol} name={data.name} iconSize={40} />
          </div>
        }
        actions={
          <Flex
            gap="space-xs"
            justifyContent={['flex-end', 'flex-end', 'space-between']}>
            <TimeFilter defaultValue={TimeOptions['1D']} />
          </Flex>
        }>
        <Flex alignItems="center" justifyContent={'flex-end'}>
          <CurrencyFilter
            key="currency-filter"
            width="8rem"
            height="1.65rem"
            currency2={{ value: 'token', displayValue: 'TOKEN' }}
            currency1={{ value: 'USD', displayValue: 'USD' }}
            {...filters}
          />
        </Flex>
        <Flex direction={['row', 'column', 'column']} gap="space-m">
          <Flex flexBasis={['33%', '100%', '100%']}>
            <KpiSwitchCard
              title="Supply"
              values={{
                USD: (
                  <CurrencyValue
                    value={data.supply_amount * data.price}
                    currency="USD"
                  />
                ),
                token: (
                  <CurrencyValue value={data.supply_amount} currency={data.symbol} />
                )
              }}
              trendValues={{
                USD: (
                  <CurrencyValue
                    value={(data.supply_amount - data.prev_supply_amount) * data.price}
                    currency="USD"
                    trend
                  />
                ),
                token: (
                  <CurrencyValue
                    value={data.supply_amount - data.prev_supply_amount}
                    trend
                    iconSize="small"
                  />
                )
              }}
              footers={{
                USD: (
                  <CurrencyValue
                    value={data.supply_amount}
                    currency={data.symbol}
                    iconSize="small"
                  />
                ),
                token: (
                  <CurrencyValue
                    value={data.supply_amount * data.price}
                    currency="USD"
                  />
                )
              }}
              switchValue={filters.filtersApplied.currency}
            />
          </Flex>
          <Flex flexBasis={['33%', '100%', '100%']}>
            <KpiSwitchCard
              title="Borrow"
              values={{
                USD: (
                  <CurrencyValue
                    value={data.borrow_amount * data.price}
                    currency="USD"
                  />
                ),
                token: (
                  <CurrencyValue value={data.borrow_amount} currency={data.symbol} />
                )
              }}
              trendValues={{
                USD: (
                  <CurrencyValue
                    value={(data.borrow_amount - data.prev_borrow_amount) * data.price}
                    currency="USD"
                    trend
                  />
                ),
                token: (
                  <CurrencyValue
                    value={data.borrow_amount - data.prev_borrow_amount}
                    trend
                    iconSize="small"
                  />
                )
              }}
              footers={{
                USD: (
                  <CurrencyValue
                    value={data.borrow_amount}
                    currency={data.symbol}
                    iconSize="small"
                  />
                ),
                token: (
                  <CurrencyValue
                    value={data.borrow_amount * data.price}
                    currency="USD"
                  />
                )
              }}
              switchValue={filters.filtersApplied.currency}
            />
          </Flex>
          <Flex flexBasis={['33%', '100%', '100%']}>
            <KpiSwitchCard
              title="Collateral"
              values={{
                USD: (
                  <CurrencyValue
                    value={data.collateral_amount * data.price}
                    currency="USD"
                  />
                ),
                token: (
                  <CurrencyValue
                    value={data.collateral_amount}
                    currency={data.symbol}
                  />
                )
              }}
              trendValues={{
                USD: (
                  <CurrencyValue
                    value={
                      (data.collateral_amount - data.prev_collateral_amount) *
                      data.price
                    }
                    currency="USD"
                    trend
                  />
                ),
                token: (
                  <CurrencyValue
                    value={data.collateral_amount - data.prev_collateral_amount}
                    trend
                    iconSize="small"
                  />
                )
              }}
              footers={{
                USD: (
                  <CurrencyValue
                    value={data.collateral_amount}
                    currency={data.symbol}
                    iconSize="small"
                  />
                ),
                token: (
                  <CurrencyValue
                    value={data.collateral_amount * data.price}
                    currency="USD"
                  />
                )
              }}
              switchValue={filters.filtersApplied.currency}
            />
          </Flex>
        </Flex>
        <Flex direction={['row', 'column', 'column']} gap="space-m">
          <Flex flexBasis={['33%', '100%', '100%']}>
            <KpiCard
              title="Total Value Locked"
              value={<CurrencyValue value={data.tvl} currency="USD" />}
              trendValue={<CurrencyValue value={data.tvl - data.prev_tvl} trend />}
            />
          </Flex>
          <Flex flexBasis={['33%', '100%', '100%']}>
            <KpiCard
              title="# of Markets"
              value={data.markets_count}
              trendValue={
                <CurrencyValue
                  value={data.markets_count - data.prev_markets_count}
                  trend
                />
              }
            />
          </Flex>
          <Flex flexBasis={['33%', '100%', '100%']}>
            <Card style={{ width: '100%' }}>
              <Flex
                direction="column"
                gap="space-3xs"
                justifyContent="space-between"
                style={{ height: '100%' }}>
                <Flex justifyContent="space-between" alignItems="center">
                  <div className="text-2">Price:</div>
                  <Flex direction="column" alignItems="flex-end">
                    <CurrencyValue
                      value={data.price}
                      currency="USD"
                      notation="standard"
                    />
                  </Flex>
                </Flex>

                <div>
                  <Flex alignItems="baseline" justifyContent="space-between">
                    <span className="text-2">Address</span>
                    <WalletAddress
                      options={{
                        size: 'xSmall',
                        inline: true,
                        showCopyBtn: true,
                        showEthScanLink: true
                      }}
                      address={data.address}
                    />
                  </Flex>
                </div>
              </Flex>
            </Card>
          </Flex>
        </Flex>

        <PoolsTable address={address} daysAgo={daysAgo} />
      </Page>
    );
  }

  return <PageLoader />;
}
