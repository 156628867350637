import { useMemo } from 'react';
import {
  BarChart as BarChartComponent,
  formatNumber,
  formatPercentage
} from '@blockanalitica/ui';
import { EmptyBox } from '@/components/empty';
export default function BarChartCategory({
  data,
  fields,
  valueFormatter,
  labelField,
  subValueFormatter = undefined,
  aspectRatio = 4,
  xScaleValueFormatter = undefined,
  yScaleValueFormatter = undefined
}) {
  const chartData = useMemo(() => {
    if (data.length > 0) {
      return {
        labels: data.map((item) => item[labelField]),
        datasets: fields.map((fieldInfo) => ({
          label: fieldInfo.label,
          data: data.map((item) => ({
            x: item[labelField],
            y: item[fieldInfo.field]
          })),
          backgroundColor: fieldInfo.backgroundColor
        }))
      };
    }
    return null;
  }, [data, fields, labelField]);

  const kpisConfig = useMemo(() => {
    if (chartData) {
      const defaultData = chartData.datasets.map((ds) => ({
        y: ds.data[ds.data.length - 1].y,
        x: ds.data[ds.data.length - 1].x,
        label: ds.label
      }));
      return {
        valueFormatter: valueFormatter,
        subValueFormatter: subValueFormatter
          ? subValueFormatter
          : (value) => <span className="text-muted">{formatPercentage(value)}</span>,
        defaultData: defaultData
      };
    }
    return null;
  }, [chartData, valueFormatter, subValueFormatter]);

  const config = useMemo(() => {
    if (chartData) {
      return {
        options: {
          aspectRatio: aspectRatio,
          onResize: (chart) => {
            const ratio = chart.config.options.aspectRatio;
            if (window.innerWidth < 576 && ratio !== 1.5) {
              chart.config.options.oldAspectRatio = ratio;
              chart.config.options.aspectRatio = 1.5;
            } else if (
              window.innerWidth >= 576 &&
              chart.config.options.oldAspectRatio > 0 &&
              ratio !== chart.config.options.oldAspectRatio
            ) {
              chart.config.options.aspectRatio = chart.config.options.oldAspectRatio;
            }
          },
          scales: {
            x: {
              stacked: true,
              type: 'category',
              ticks: {
                callback: xScaleValueFormatter
                  ? xScaleValueFormatter
                  : (value, index) => formatPercentage(chartData.labels[index])
              }
            },
            y: {
              type: 'linear',
              beginAtZero: true,
              stacked: true,
              ticks: {
                callback:
                  yScaleValueFormatter ||
                  ((value) => formatNumber(value, { maximumFractionDigits: 2 }))
              }
            }
          }
        }
      };
    }
    return null;
  }, [aspectRatio, xScaleValueFormatter, yScaleValueFormatter, chartData]);

  if (chartData) {
    return (
      <BarChartComponent data={chartData} kpisConfig={kpisConfig} config={config} />
    );
  }

  return <EmptyBox />;
}
