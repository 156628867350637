import { useApi } from '@/api';
import { Page } from '@/components';
import SelectFilter from '@/components/filters/SelectFilter';
import { EVENT_TYPE_MAPPING, WALLET_EVENTS } from '@/constants';
import { Flex, useTableProps } from '@blockanalitica/ui';
import { useMemo } from 'react';
import ActivityTable from './ActivityTable';

export default function Activity() {
  const selectFilterOptions = useMemo(() => {
    let options = [{ label: 'All events', value: '' }];
    options.push(
      ...WALLET_EVENTS.map((event) => ({
        label: EVENT_TYPE_MAPPING[event],
        value: event
      }))
    );
    return options;
  }, []);

  const tableProps = useTableProps({
    pageSize: 15,
    initialSorting: '-order_index',
    filtersDebounceTime: 50
  });

  const [data, error] = useApi({
    url: `/activity/`,
    queryParams: {
      p: String(tableProps.pagination.currentPage),
      p_size: String(tableProps.pagination.pageSize),
      order: tableProps.sorting.currentSorting,
      event: tableProps.filters.filtersApplied.eventType
    }
  });

  if (error) {
    throw error;
  }

  return (
    <Page title={<Flex gap="space-xs">Activity</Flex>}>
      <Flex gap="space-xs" justifyContent="flex-end">
        <SelectFilter
          key="event-filter"
          placeholder="Showing all events"
          filterField="eventType"
          options={selectFilterOptions}
          onPageChange={tableProps.pagination.onPageChange}
          {...tableProps.filters}
        />
      </Flex>

      <ActivityTable data={data} tableProps={tableProps} />
    </Page>
  );
}
