import { useApi } from '@/api';
import { HealthRate, Link } from '@/components';
import CurrencyValue from '@/components/CurrencyValue/CurrencyValue';
import PoolName from '@/components/PoolName/PoolName';
import RelativeDateTime from '@/components/RelativeDateTime/RelativeDateTime';
import TableLoader from '@/components/loaders/TableLoader';
import Table from '@/components/table/Table';
import { useSearchParams } from '@/hooks';
import { CurrencyFilter, Flex, useTableProps } from '@blockanalitica/ui';

export default function PoolsTable({ walletAddress }) {
  const [daysAgo] = useSearchParams('days_ago', 1);
  const { sorting, filters, pagination } = useTableProps({
    pageSize: 10,
    initialSorting: '-total_usd',
    initialFilters: { currency: 'USD' },
    filtersDebounceTime: 50
  });
  const [data, error] = useApi({
    url: `/wallets/${walletAddress}/markets/`,
    queryParams: {
      p: pagination.currentPage,
      p_size: pagination.pageSize,
      order: sorting.currentSorting
    }
  });

  if (error) {
    throw error;
  }

  if (data) {
    const columns = [
      {
        header: 'MARKET',
        getCell: (row) => (
          <PoolName
            loanSymbol={row.loan_token_symbol}
            collateralSymbol={row.collateral_token_symbol}
            lltv={row.lltv}
          />
        )
      },
      {
        header: 'SUPPLY',
        getCell: (row) => (
          <>
            {filters.filtersApplied.currency === 'USD' ? (
              <CurrencyValue value={row.supply_usd} currency="USD" />
            ) : (
              <CurrencyValue
                value={row.supply}
                currency={row.loan_token_symbol}
                iconSize="small"
              />
            )}
          </>
        ),
        getCellBottom: (row) => (
          <>
            {filters.filtersApplied.currency === 'USD' ? (
              <CurrencyValue
                value={row[`supply_change_${daysAgo}d_usd`]}
                currency="USD"
                trend
              />
            ) : (
              <CurrencyValue
                value={row[`supply_change_${daysAgo}d`]}
                currency={row.loan_token_symbol}
                iconSize="small"
                trend
              />
            )}
          </>
        ),
        sortingField: 'supply',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      },
      {
        header: 'COLLATERAL',
        getCell: (row) => (
          <>
            {row.collateral_token_symbol ? (
              <>
                {filters.filtersApplied.currency === 'USD' ? (
                  <CurrencyValue value={row.collateral_usd} currency="USD" />
                ) : (
                  <CurrencyValue
                    value={row.collateral}
                    currency={row.collateral_token_symbol}
                    iconSize="small"
                  />
                )}
              </>
            ) : (
              '-'
            )}
          </>
        ),
        getCellBottom: (row) => (
          <>
            {filters.filtersApplied.currency === 'USD' ? (
              <CurrencyValue
                value={row[`collateral_change_${daysAgo}d_usd`]}
                currency="USD"
                trend
              />
            ) : (
              <CurrencyValue
                value={row[`collateral_change_${daysAgo}d`]}
                currency={row.loan_token_symbol}
                iconSize="small"
                trend
              />
            )}
          </>
        ),
        sortingField: 'collateral',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      },
      {
        header: 'BORROW',
        getCell: (row) => (
          <>
            {row.collateral_token_symbol ? (
              <>
                {filters.filtersApplied.currency === 'USD' ? (
                  <CurrencyValue value={row.borrow_usd} currency="USD" />
                ) : (
                  <CurrencyValue
                    value={row.borrow}
                    currency={row.loan_token_symbol}
                    iconSize="small"
                  />
                )}
              </>
            ) : (
              '-'
            )}
          </>
        ),
        getCellBottom: (row) => (
          <>
            {filters.filtersApplied.currency === 'USD' ? (
              <CurrencyValue
                value={row[`borrow_change_${daysAgo}d_usd`]}
                currency="USD"
                trend
              />
            ) : (
              <CurrencyValue
                value={row[`borrow_change_${daysAgo}d`]}
                currency={row.loan_token_symbol}
                iconSize="small"
                trend
              />
            )}
          </>
        ),
        sortingField: 'borrow',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      },
      {
        header: 'HEALTH RATE',
        getCell: (row) => <HealthRate value={row.health_rate} />,
        sortingField: 'health_rate',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      },
      {
        header: 'LATEST ACTIVITY',
        getCell: (row) => <RelativeDateTime value={row.datetime} />,
        sortingField: 'datetime',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      }
    ];
    return (
      <>
        <Flex justifyContent="space-between" alignItems="center">
          <h4>Markets</h4>
          <CurrencyFilter
            key="currency-filter"
            width="8rem"
            currency2={{ value: 'token', displayValue: 'TOKEN' }}
            currency1={{ value: 'USD', displayValue: 'USD' }}
            {...filters}
          />
        </Flex>
        <Table
          rows={data['results']}
          columns={columns}
          sorting={sorting}
          pagination={{
            ...pagination,
            totalRecords: data.count
          }}
          getKey={(row) => row.uid}
          getLinkWrapper={(row) => (
            <Link to={`/markets/${row.market_uid}/wallets/${row.wallet_address}`} />
          )}
        />
      </>
    );
  }

  return <TableLoader />;
}
