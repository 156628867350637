import { formatCurreny } from '@/utils';
import {
  EmptyBox,
  Flex,
  LineChart as LineChartComponent,
  formatDatetime
} from '@blockanalitica/ui';
import { useMemo } from 'react';

export default function LineChart({
  data,
  field,
  valueFormatter,
  aspectRatio = 3.4,
  xScaleValueFormatter = undefined,
  yScaleValueFormatter = undefined,
  switchValue = 'token',
  daysAgo = 30,
  ...rest
}) {
  const switchField = switchValue == 'token' ? field : `${field}_usd`;
  const datasets = useMemo(
    () => [
      {
        data: data.map((item) => ({
          x: item['date'],
          y: item[switchField],
          label: 'Total'
        })),
        borderColor: '#5792FF',
        borderWidth: 4,
        backgroundColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            // This case happens on initial chart load
            return null;
          }
          const gradient = ctx.createLinearGradient(
            0,
            chartArea.bottom,
            0,
            chartArea.top
          );
          gradient.addColorStop(0, 'rgba(0, 0, 0, 0)');
          gradient.addColorStop(1, 'rgba(87, 146, 255, .4)');

          return gradient;
        }
      }
    ],
    [data, switchField]
  );

  const kpisConfig = useMemo(
    () => ({
      valueFormatter: valueFormatter,
      subValueFormatter: (value) => (
        <span className="text-muted">{formatDatetime(value)}</span>
      ),
      defaultData: [datasets[0].data[datasets[0].data.length - 1]]
    }),
    [datasets, valueFormatter]
  );

  const config = useMemo(
    () => ({
      options: {
        fill: true,
        aspectRatio: aspectRatio,
        onResize: (chart) => {
          const ratio = chart.config.options.aspectRatio;
          if (window.innerWidth < 576 && ratio !== 1.5) {
            chart.config.options.oldAspectRatio = ratio;
            chart.config.options.aspectRatio = 1.5;
          } else if (
            window.innerWidth >= 576 &&
            chart.config.options.oldAspectRatio > 0 &&
            ratio !== chart.config.options.oldAspectRatio
          ) {
            chart.config.options.aspectRatio = chart.config.options.oldAspectRatio;
          }
        },
        plugins: {
          legend: {
            display: false
          }
        },
        scales: {
          x: {
            type: 'time',
            time: {
              unit: daysAgo < 30 ? 'day' : 'week'
            },
            ticks: {
              callback: xScaleValueFormatter
                ? xScaleValueFormatter
                : (value) => formatDatetime(value, { format: 'MMM d' })
            }
          },
          y: {
            type: 'linear',
            ticks: {
              format: { notation: 'compact' },
              callback: yScaleValueFormatter
                ? yScaleValueFormatter
                : (value) => formatCurreny(value)
            }
          }
        }
      }
    }),
    [aspectRatio, xScaleValueFormatter, yScaleValueFormatter, daysAgo]
  );

  if (data.length == 0) {
    return <EmptyBox />;
  }

  return (
    <Flex direction="column" key={switchField} {...rest}>
      <LineChartComponent data={{ datasets }} kpisConfig={kpisConfig} config={config} />
    </Flex>
  );
}
