import { useApi } from '@/api';
import { LineChart } from '@/components/charts';
import { CardLoader, formatPercentage } from '@blockanalitica/ui';

export default function HistoricRatesTabs({ poolUid, activeDisplayOption, daysAgo }) {
  const [data, error] = useApi({
    url: `/markets/${poolUid}/historic/`,
    queryParams: {
      days_ago: daysAgo
    }
  });

  if (error) {
    throw error;
  }

  if (data) {
    return (
      <LineChart
        key={activeDisplayOption}
        data={data}
        field={activeDisplayOption}
        valueFormatter={(value) => formatPercentage(value)}
        yScaleValueFormatter={(value) => formatPercentage(value)}
        daysAgo={daysAgo}
      />
    );
  }

  return <CardLoader headerHeight="3rem" bodyHeight="7rem" />;
}
