import { useApi } from '@/api';
import { Card } from '@/components';
import CurrencyValue from '@/components/CurrencyValue/CurrencyValue';
import DisplaySwitch from '@/components/DisplaySwitch/DisplaySwitch';
import { BarChart, TimeSelector } from '@/components/charts';
import { formatCurreny } from '@/utils';
import { CardLoader, Flex } from '@blockanalitica/ui';
import { useMemo, useState } from 'react';
import { EmptyBox } from '@/components/empty';

export default function HistoricVolumeTabs({ poolUid, loanSymbol, collateralSymbol }) {
  const [daysAgo, setDaysAgo] = useState(30);
  const [activeDisplayOption, setActiveDisplayOption] = useState('volume');

  const [data, error] = useApi({
    url: `/markets/${poolUid}/historic/`,
    queryParams: {
      days_ago: daysAgo
    }
  });

  if (error) {
    throw error;
  }

  const displayOptions = useMemo(
    () => [
      { key: 'volume', value: 'Volume' },
      { key: 'collateral', value: 'Collateral' },
      { key: 'supply', value: 'Supply' },
      { key: 'borrow', value: 'Borrow' }
    ],
    []
  );

  if (data) {
    let valueFormatter = null;
    let fields = null;
    let yScaleValueFormatter = undefined;
    switch (activeDisplayOption) {
      case 'volume':
        valueFormatter = (value) => <CurrencyValue value={value} currency="USD" />;
        fields = [
          {
            field: 'volume',
            label: 'Volume',
            backgroundColor: '#5792ff'
          }
        ];

        break;
      case 'collateral':
        valueFormatter = (value) => (
          <CurrencyValue value={value} currency={collateralSymbol} iconSize={14} />
        );
        yScaleValueFormatter = (value) => formatCurreny(value, collateralSymbol);
        fields = [
          {
            field: 'volume_supply_collateral',
            label: 'Supply',
            backgroundColor: '#008000'
          },
          {
            field: 'volume_withdraw_collateral',
            label: 'Withdraw',
            backgroundColor: '#FF0000'
          }
        ];

        break;
      case 'supply':
        valueFormatter = (value) => (
          <CurrencyValue value={value} currency={loanSymbol} iconSize={14} />
        );
        yScaleValueFormatter = (value) => formatCurreny(value, loanSymbol);
        fields = [
          {
            field: 'volume_supply',
            label: 'Supply',
            backgroundColor: '#008000'
          },
          {
            field: 'volume_withdraw',
            label: 'Withdraw',
            backgroundColor: '#FF0000'
          }
        ];
        break;
      case 'borrow':
        valueFormatter = (value) => (
          <CurrencyValue value={value} currency={loanSymbol} iconSize={14} />
        );
        yScaleValueFormatter = (value) => formatCurreny(value, loanSymbol);
        fields = [
          {
            field: 'volume_borrow',
            label: 'Borrow',
            backgroundColor: '#008000'
          },
          {
            field: 'volume_repay',
            label: 'Repay',
            backgroundColor: '#FF0000'
          }
        ];
        break;

      default:
      // pass
    }

    if (data && data.length > 0) {
      return (
        <Card style={{ width: '100%' }}>
          <Flex direction="column" gap="space-xs">
            <Flex
              direction={['row', 'row', 'column']}
              alignItems={['flex-start', 'flex-start', 'center']}
              justifyContent="space-between"
              gap="space-xs">
              <DisplaySwitch
                options={displayOptions}
                activeOption={activeDisplayOption}
                onChange={setActiveDisplayOption}
              />
              <TimeSelector
                activeOption={daysAgo}
                onChange={(e) => setDaysAgo(e.target.value)}
              />
            </Flex>
            <Flex direction="column">
              <BarChart
                key={activeDisplayOption}
                data={data}
                fields={fields}
                valueFormatter={valueFormatter}
                yScaleValueFormatter={yScaleValueFormatter}
              />
            </Flex>
          </Flex>
        </Card>
      );
    } else {
      return (
        <Card style={{ width: '100%' }}>
          <Flex direction="column" gap="space-xs">
            <Flex
              direction={['row', 'row', 'column']}
              alignItems={['flex-start', 'flex-start', 'center']}
              justifyContent="space-between"
              gap="space-xs">
              <DisplaySwitch
                options={displayOptions}
                activeOption={activeDisplayOption}
                onChange={setActiveDisplayOption}
              />
              <TimeSelector
                activeOption={daysAgo}
                onChange={(e) => setDaysAgo(e.target.value)}
              />
            </Flex>
            <Flex direction="column">
              <EmptyBox />
            </Flex>
          </Flex>
        </Card>
      );
    }
  }

  return <CardLoader headerHeight="3rem" bodyHeight="7rem" />;
}
