import Svg from './Svg';

export default function CoinsStackIcon({
  size = 32,
  color = 'currentColor',
  ...props
}) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="-0.5 -0.5 14 14"
      width={size + 'px'}
      height={size + 'px'}
      {...props}>
      <g id="coins-stack--accounting-billing-payment-stack-cash-coins-currency-money-finance">
        <path
          id="Vector"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M8.357142857142858 6.964285714285714c2.3077785714285715 0 4.178571428571429 -0.8314707142857143 4.178571428571429 -1.8571428571428572s-1.8707928571428571 -1.8571428571428572 -4.178571428571429 -1.8571428571428572c-2.30776 0 -4.178571428571429 0.8314707142857143 -4.178571428571429 1.8571428571428572s1.8708114285714288 1.8571428571428572 4.178571428571429 1.8571428571428572Z"
          strokeWidth="1"></path>
        <path
          id="Vector_2"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M4.178571428571429 5.107142857142858v5.571428571428571c0 1.0214285714285716 1.8571428571428572 1.8571428571428572 4.178571428571429 1.8571428571428572s4.178571428571429 -0.8357142857142857 4.178571428571429 -1.8571428571428572v-5.571428571428571"
          strokeWidth="1"></path>
        <path
          id="Vector_3"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12.535714285714286 7.892857142857143c0 1.0214285714285716 -1.8571428571428572 1.8571428571428572 -4.178571428571429 1.8571428571428572s-4.178571428571429 -0.8357142857142857 -4.178571428571429 -1.8571428571428572"
          strokeWidth="1"></path>
        <path
          id="Vector_4"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M8.264285714285714 1.40101C7.179240714285714 0.7313911428571429 5.916235 0.4075425714285714 4.642857142857143 0.47243485714285716c-2.3121428571428573 0 -4.178571428571429 0.8357180000000001 -4.178571428571429 1.8571465714285715 0 0.5478571428571428 0.5385714285714286 1.04 1.3928571428571428 1.3928571428571428"
          strokeWidth="1"></path>
        <path
          id="Vector_5"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M1.8571428571428572 9.293885714285715C1.002857142857143 8.941019285714287 0.4642857142857143 8.448876428571428 0.4642857142857143 7.901019285714286v-5.571428571428571"
          strokeWidth="1"></path>
        <path
          id="Vector_6"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M1.8571428571428572 6.5081621428571435c-0.8542857142857143 -0.35285714285714287 -1.3928571428571428 -0.8450000000000001 -1.3928571428571428 -1.3928571428571428"
          strokeWidth="1"></path>
      </g>
    </Svg>
  );
}
