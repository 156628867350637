import { useApi } from '@/api';
import { Link, Page } from '@/components';
import CurrencyValue from '@/components/CurrencyValue/CurrencyValue';
import PoolName from '@/components/PoolName/PoolName';
import SearchInput from '@/components/forms/SearchInput';
import { TimeFilter, TimeOptions } from '@/components/global';
import TableLoader from '@/components/loaders/TableLoader';
import Table from '@/components/table/Table';
import { useSearchParams } from '@/hooks';
import {
  CurrencyFilter,
  Flex,
  TrendValue,
  formatPercentage,
  useTableProps
} from '@blockanalitica/ui';

export default function Pools() {
  const [daysAgo] = useSearchParams('days_ago', 1);
  const [searchText, setSearchText] = useSearchParams('search', '');
  // const [idle] = useSearchParams('idle');

  const { filters, sorting, pagination } = useTableProps({
    pageSize: 15,
    initialSorting: '-liquidity_usd',
    initialFilters: { currency: 'USD' },
    filtersDebounceTime: 50
  });
  const [data, error] = useApi({
    url: '/markets/',
    queryParams: {
      p: pagination.currentPage,
      p_size: pagination.pageSize,
      days_ago: daysAgo,
      order: sorting.currentSorting,
      search: searchText,
      idle: true
    }
  });

  if (error) {
    throw error;
  }

  let content;
  if (data) {
    const columns = [
      {
        header: 'MARKET',
        getCell: (row) => (
          <PoolName
            loanSymbol={row.loan_token_symbol}
            collateralSymbol={row.collateral_token_symbol}
            lltv={row.lltv}
          />
        )
      },
      {
        header: 'LIQUIDITY',
        getCell: (row) => (
          <>
            {filters.filtersApplied.currency === 'USD' ? (
              <CurrencyValue value={row.liquidity_usd} currency="USD" />
            ) : (
              <CurrencyValue
                value={row.liquidity}
                currency={row.loan_token_symbol}
                iconSize="small"
              />
            )}
          </>
        ),
        getCellBottom: (row) => (
          <>
            {filters.filtersApplied.currency === 'USD' ? (
              <CurrencyValue
                value={row.liquidity_usd - row.prev_liquidity_usd}
                currency="USD"
                trend
              />
            ) : (
              <CurrencyValue
                value={row.liquidity - row.prev_liquidity}
                currency={row.loan_token_symbol}
                iconSize="small"
                trend
              />
            )}
          </>
        ),
        sortingField: 'liquidity_usd',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      },
      {
        header: 'SUPPLY',
        getCell: (row) => (
          <>
            {filters.filtersApplied.currency === 'USD' ? (
              <CurrencyValue value={row.total_supply_usd} currency="USD" />
            ) : (
              <CurrencyValue
                value={row.total_supply}
                currency={row.loan_token_symbol}
                iconSize="small"
              />
            )}
          </>
        ),
        getCellBottom: (row) => (
          <>
            {filters.filtersApplied.currency === 'USD' ? (
              <CurrencyValue
                value={row.total_supply_usd - row.prev_total_supply_usd}
                currency="USD"
                trend
              />
            ) : (
              <CurrencyValue
                value={row.total_supply - row.prev_total_supply}
                currency={row.loan_token_symbol}
                iconSize="small"
                trend
              />
            )}
          </>
        ),
        sortingField: 'total_supply_usd',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      },
      {
        header: 'BORROW',
        getCell: (row) => (
          <>
            {row.collateral_token_symbol ? (
              <>
                {filters.filtersApplied.currency === 'USD' ? (
                  <CurrencyValue value={row.total_borrow_usd} currency="USD" />
                ) : (
                  <>
                    <CurrencyValue
                      value={row.total_borrow}
                      currency={row.loan_token_symbol}
                      iconSize="small"
                    />
                  </>
                )}
              </>
            ) : (
              '-'
            )}
          </>
        ),
        getCellBottom: (row) => (
          <>
            {filters.filtersApplied.currency === 'USD' ? (
              <CurrencyValue
                value={row.total_borrow_usd - row.prev_total_borrow_usd}
                currency="USD"
                trend
              />
            ) : (
              <CurrencyValue
                value={row.total_borrow - row.prev_total_borrow}
                currency={row.loan_token_symbol}
                iconSize="small"
                trend
              />
            )}
          </>
        ),
        sortingField: 'total_borrow_usd',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      },
      {
        header: 'SUPPLY APY',
        getCell: (row) =>
          row.collateral_token_symbol ? formatPercentage(row.supply_rate_apy) : '-',
        getCellBottom: (row) => (
          <TrendValue
            value={formatPercentage(row.supply_rate_apy - row.prev_supply_rate_apy)}
          />
        ),
        sortingField: 'supply_rate_apy',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      },
      {
        header: 'NET APY',
        getCell: (row) =>
          row.collateral_token_symbol ? formatPercentage(row.total_rate_apy) : '-',
        getCellBottom: (row) => (
          <TrendValue
            value={formatPercentage(row.total_rate_apy - row.prev_total_rate_apy)}
          />
        ),
        sortingField: 'total_rate_apy',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      },
      {
        header: 'BORROW APY',
        getCell: (row) =>
          row.collateral_token_symbol ? formatPercentage(row.borrow_rate_apy) : '-',
        getCellBottom: (row) => (
          <TrendValue
            value={formatPercentage(row.borrow_rate_apy - row.prev_borrow_rate_apy)}
          />
        ),
        sortingField: 'borrow_rate_apy',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      }
    ];

    content = (
      <Table
        rows={data['results']}
        columns={columns}
        getKey={(row) => row.uid}
        getLinkWrapper={(row) => <Link to={`${row.uid}`} />}
        sorting={sorting}
        pagination={{
          ...pagination,
          totalRecords: data.count
        }}
      />
    );
  } else {
    content = <TableLoader />;
  }
  return (
    <Page title="Markets" actions={<TimeFilter defaultValue={TimeOptions['1D']} />}>
      <Flex
        gap="space-xs"
        direction={['row', 'row', 'row', 'column']}
        justifyContent={['flex-end', 'flex-end', 'flex-end', 'space-between']}>
        <SearchInput
          placeholder="Search by symbol or name"
          defaultValue={searchText}
          onValueChange={(value) => setSearchText(value)}
        />
        <Flex gap="space-xs" justifyContent="flex-end">
          {/* <AdditionalFilters /> */}
          <CurrencyFilter
            key="currency-filter"
            width="8rem"
            height="auto"
            currency2={{ value: 'token', displayValue: 'TOKEN' }}
            currency1={{ value: 'USD', displayValue: 'USD' }}
            {...filters}
          />
        </Flex>
      </Flex>
      {content}
    </Page>
  );
}
