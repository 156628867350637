import { CryptoIcon } from '@/components';
import {
  ArrowDownRightCircleIcon,
  ArrowUpRightCircleIcon,
  Flex,
  FormattedNumber
} from '@blockanalitica/ui';
import classnames from 'classnames';
import styles from './CurrencyValue.module.scss';

export default function CurrencyValue({
  value,
  currency,
  iconSize = 'large',
  trend = false,
  trendNoIcon = false,
  notation = 'compact',
  dashIfZero = false
}) {
  let num = Number(value);

  if (isNaN(num)) {
    return;
  }
  if (num === 0 && dashIfZero === true) {
    return '-';
  }
  if (num === 0 && (trend === true || trendNoIcon === true)) {
    return;
  }

  let maximumFractionDigits = Math.abs(num) < 0.01 ? 5 : 2;
  let opts;
  let valueProps;

  const absNum = Math.abs(num);
  if (absNum < 0.00001 && absNum !== 0) {
    notation = 'scientific';
    maximumFractionDigits = 3;
  }

  if (num >= 1e20) {
    num = Infinity;
    valueProps = {
      fontSize: 'var(--size-0)'
    };
  } else if (currency === 'USD') {
    opts = {
      formatNumberOptions: {
        maximumFractionDigits: maximumFractionDigits,
        currency: 'USD',
        style: 'currency',
        notation
      }
    };
  } else if (currency) {
    opts = {
      suffix: <CryptoIcon name={currency} size={iconSize} />,
      formatNumberOptions: {
        maximumFractionDigits: maximumFractionDigits,
        notation
      }
    };
  } else {
    opts = {
      formatNumberOptions: {
        maximumFractionDigits: maximumFractionDigits,
        notation
      }
    };
  }

  return (
    <>
      {trend === true || trendNoIcon === true ? (
        <Flex
          justifyContent="center"
          gap="space-3xs"
          alignItems="center"
          className={classnames({
            [styles.bullTrend]: num > 0,
            [styles.bearTrend]: num < 0
          })}>
          {trendNoIcon === false ? (
            <>
              {num > 0 ? (
                <ArrowUpRightCircleIcon size={14} />
              ) : (
                <ArrowDownRightCircleIcon size={14} />
              )}
            </>
          ) : null}

          <FormattedNumber
            value={trendNoIcon === true ? num : Math.abs(num)}
            options={opts}
          />
        </Flex>
      ) : (
        <FormattedNumber value={num} options={opts} valueProps={valueProps} />
      )}
    </>
  );
}
