import classnames from 'classnames';
import { Flex } from '@blockanalitica/ui';
import styles from './TimeFilter.module.scss';
import { useCallback } from 'react';
import { useSearchParams } from '@/hooks';

// eslint-disable-next-line react-refresh/only-export-components
export enum TimeOptions {
  '1D' = '1',
  '7D' = '7',
  '30D' = '30'
}

type TimeFilterProps = {
  defaultValue?: TimeOptions;
};

export default function TimeFilter({
  defaultValue = TimeOptions['1D']
}: TimeFilterProps) {
  const [dataTimeFilter, setSearchParams] = useSearchParams('days_ago', defaultValue);

  const onChangeDataFilter = useCallback(
    (key: keyof typeof TimeOptions) => {
      setSearchParams(TimeOptions[key]);
    },
    [setSearchParams]
  );

  return (
    <Flex gap="space-3xs">
      {Object.keys(TimeOptions).map((key) => (
        <div
          role="button"
          aria-label="Data time filter button"
          key={key}
          className={classnames(styles.wrapper, {
            [styles.active]:
              dataTimeFilter === TimeOptions[key as keyof typeof TimeOptions]
          })}
          onClick={() => onChangeDataFilter(key as keyof typeof TimeOptions)}>
          <p
            className={classnames('text-2', {
              regular: dataTimeFilter !== TimeOptions[key as keyof typeof TimeOptions]
            })}>
            {key}
          </p>
        </div>
      ))}
    </Flex>
  );
}
