import { useApi } from '@/api';
import { MultiLineChart } from '@/components/charts';
import { CardLoader, CurrencyValue } from '@blockanalitica/ui';

export default function CapitalAtRiskChart() {
  const [data, error] = useApi({
    url: `/risk/capital-at-risk/`,
    queryParams: {
      scenario: 'long'
    }
  });

  if (error) {
    throw error;
  }

  if (data) {
    return (
      <MultiLineChart
        data={data}
        valueFormatter={(value) => (
          <CurrencyValue value={value} options={{ currency: 'USD' }} />
        )}
        xAxisNegative={true}
        forceKpis={true}
        aspectRatio={3}
      />
    );
  }

  return <CardLoader headerHeight="3rem" bodyHeight="7rem" />;
}
