import { useApi } from '@/api';
import LinkButton from '@/components/forms/LinkButton';
import { SimpleVaultCard } from '@/components/vault';
import { Box, CardLoader, Flex } from '@blockanalitica/ui';
import { Fragment } from 'react';

export default function FeaturedVaults() {
  const [data, error] = useApi({
    url: '/vaults/featured/'
  });

  if (error) {
    throw error;
  }

  if (data) {
    if (!data.length) {
      return null;
    }
    const width = 100 / data.length + '%';
    return (
      <Fragment>
        <Flex justifyContent="space-between" alignItems="center">
          <h4>Featured Vaults</h4>
          <LinkButton style={{ height: '1.5rem' }} to="/vaults/vaults">
            view all
          </LinkButton>
        </Flex>
        <Flex direction={['row', 'column', 'column']} gap="space-xs">
          {data.map((row) => (
            <Box key={row.address} flexBasis={[width, '100%', '100%']}>
              <SimpleVaultCard data={row} smallBox={true} />
            </Box>
          ))}
        </Flex>
      </Fragment>
    );
  }
  return <CardLoader />;
}
