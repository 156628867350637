import { useApi } from '@/api';
import { CardList, Link } from '@/components';
import CurrencyValue from '@/components/CurrencyValue/CurrencyValue';
import PoolName from '@/components/PoolName/PoolName';
import WalletAddress from '@/components/WalletAddress/WalletAddress';
import { EmptyBox } from '@/components/empty';
import { LinkButton } from '@/components/forms';
import {
  CardLoader,
  Flex,
  List,
  formatPercentage,
  useIsMobile
} from '@blockanalitica/ui';

export default function PositionsAtRisk() {
  const [data, error] = useApi({
    url: '/risk/',
    queryParams: {
      p: 1,
      p_size: 4,
      order: 'price_change',
      scenario: 'long'
    }
  });
  const isMobile = useIsMobile();

  if (error) {
    throw error;
  }

  if (data) {
    let content = undefined;
    if (data.results.length > 0) {
      content = (
        <List>
          {data.results.map((row) => (
            <Link key={row.wallet_address} to={`/wallets/${row.wallet_address}`}>
              <Flex justifyContent="space-between" alignItems="center">
                <WalletAddress
                  options={{
                    size: 'xSmall'
                  }}
                  address={row.wallet_address}
                />
                {isMobile ? null : (
                  <PoolName
                    loanSymbol={row.loan_token_symbol}
                    collateralSymbol={row.collateral_token_symbol}
                    lltv={row.lltv}
                  />
                )}
                <Flex direction="column" gap="space-3xs" alignItems="flex-end">
                  <span className="text-2">
                    -{formatPercentage(Number(row.price_change))}
                  </span>
                  <span className="text-2 text-muted">oracle drop</span>
                </Flex>
                <Flex direction="column" gap="space-3xs" alignItems="flex-end">
                  <CurrencyValue
                    value={row.amount}
                    currency={row.symbol}
                    iconSize="small"
                  />
                  <span className="text-2 text-muted">
                    <CurrencyValue value={row.amount_usd} currency="USD" />
                  </span>
                </Flex>
              </Flex>
            </Link>
          ))}
        </List>
      );
    } else {
      content = <EmptyBox />;
    }

    return (
      <CardList
        style={{ width: '100%' }}
        title="Positions at risk"
        actions={<LinkButton to="positions">view all</LinkButton>}>
        {content}
      </CardList>
    );
  }

  return <CardLoader headerHeight="3rem" bodyHeight="7rem" />;
}
