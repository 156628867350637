import { useApi } from '@/api';
import { MultiLineTimeSeriesChart } from '@/components/charts';
import { CardLoader } from '@blockanalitica/ui';

export default function CapitalAtRiskHistoryChart({ daysAgo }) {
  const [data, error] = useApi({
    url: `/risk/capital-at-risk-history/`,
    queryParams: {
      days_ago: daysAgo,
      scenario: 'LST',
      bucket_1: '0.01',
      bucket_2: '0.05',
      bucket_3: '0.1'
    }
  });

  if (error) {
    throw error;
  }

  if (data) {
    return <MultiLineTimeSeriesChart data={data} aspectRatio={3} />;
  }

  return <CardLoader headerHeight="3rem" bodyHeight="7rem" />;
}
