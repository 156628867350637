import { EmptyBox } from '@/components/empty';
import { formatCurreny } from '@/utils';
import {
  Flex,
  LineChart as LineChartComponent,
  chartsRgbColorPallete,
  formatDatetime
} from '@blockanalitica/ui';
import { useMemo } from 'react';

export default function MultiLineTimeSeriesChart({
  data,
  valueFormatter: valueFormatter = (value) => formatCurreny(value),
  fields = { x: 'date', y: 'amount', label: 'bucket' },
  yScaleValueFormatter = undefined,
  aspectRatio = 4,
  daysAgo = 30,
  ...rest
}) {
  const colors = chartsRgbColorPallete(1);
  const datasets = useMemo(() => {
    // Group data by labels
    const groupedByLabel = data.reduce((acc, item) => {
      const label = item[fields.label];
      if (!acc[label]) {
        acc[label] = [];
      }
      acc[label].push({
        x: item[fields.x],
        y: item[fields.y],
        label: item[fields.label]
      });
      return acc;
    }, {});

    // Map each group to a dataset
    const result = Object.entries(groupedByLabel).map(([label, data], index) => ({
      label: label,
      data: data,
      borderColor: colors[index % colors.length],
      borderWidth: 4,
      backgroundColor: colors[index % colors.length]
    }));

    return result;
  }, [data, fields, colors]);

  const kpisConfig = useMemo(
    () => ({
      valueFormatter: valueFormatter,
      subValueFormatter: (value) => (
        <span className="text-muted">{formatDatetime(value)}</span>
      ),
      defaultData: datasets.map((set) => set.data[set.data.length - 1]) // Then map the remaining datasets
    }),
    [datasets, valueFormatter]
  );

  const config = useMemo(
    () => ({
      options: {
        aspectRatio: aspectRatio,
        onResize: (chart) => {
          const ratio = chart.config.options.aspectRatio;
          if (window.innerWidth < 576 && ratio !== 1.5) {
            chart.config.options.oldAspectRatio = ratio;
            chart.config.options.aspectRatio = 1.5;
          } else if (
            window.innerWidth >= 576 &&
            chart.config.options.oldAspectRatio > 0 &&
            ratio !== chart.config.options.oldAspectRatio
          ) {
            chart.config.options.aspectRatio = chart.config.options.oldAspectRatio;
          }
        },
        plugins: {
          legend: {
            display: false
          }
        },
        scales: {
          x: {
            type: 'time',
            time: {
              unit: daysAgo < 30 ? 'day' : 'week'
            },
            ticks: {
              callback: (value) => formatDatetime(value, { format: 'MMM d' })
            }
          },
          y: {
            ticks: {
              format: { notation: 'compact' },
              callback: yScaleValueFormatter
                ? yScaleValueFormatter
                : (value) => formatCurreny(value)
            }
          }
        }
      }
    }),
    [aspectRatio, yScaleValueFormatter, daysAgo]
  );

  if (data.length > 0) {
    return (
      <Flex direction="column" {...rest}>
        <LineChartComponent
          data={{ datasets }}
          kpisConfig={kpisConfig}
          config={config}
        />
      </Flex>
    );
  }

  // Default state until data is ready
  return <EmptyBox />;
}
