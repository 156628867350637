import { useApi } from '@/api';
import { MultiLineTimeSeriesChart } from '@/components/charts';
import { CardLoader, formatPercentage } from '@blockanalitica/ui';

export default function HistoricRatesMultilineChart({ poolUid, key, daysAgo }) {
  const [data, error] = useApi({
    url: `/markets/${poolUid}/historic/apy/`,
    queryParams: {
      days_ago: daysAgo
    }
  });

  if (error) {
    throw error;
  }
  if (data) {
    return (
      <MultiLineTimeSeriesChart
        data={data}
        valueFormatter={(value) => formatPercentage(value)}
        yScaleValueFormatter={(value) => formatPercentage(value)}
        key={key}
        aspectRatio={3.4}
      />
    );
  }

  return <CardLoader headerHeight="3rem" bodyHeight="7rem" />;
}
