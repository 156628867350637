import { useApi } from '@/api';
import { CardLoader, Flex, useIsMobile } from '@blockanalitica/ui';

import { Card } from '@/components';
import { Heatmap } from '@/components/charts';

export default function ActivityHeatmap({ vaultAddress, address }) {
  const isMobile = useIsMobile();
  const monthsAgo = isMobile ? 3 : 12;
  const [data, error] = useApi({
    url: `/vaults/${vaultAddress}/depositors/${address}/activity-heatmap/`
  });

  if (error) {
    throw error;
  }

  if (data) {
    return (
      <Card header={{ title: 'Activity' }}>
        <Flex
          direction={['row', 'column', 'column']}
          gap="space-m"
          justifyContent="center">
          <Heatmap data={data} monthsAgo={monthsAgo} />
        </Flex>
      </Card>
    );
  }

  return <CardLoader headerHeight="3rem" bodyHeight="7rem" />;
}
