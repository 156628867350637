import { Flex } from '@blockanalitica/ui';
import Card from './Card';
import styles from './CardList.module.scss';

export default function CardList({ children, style, title, actions }) {
  return (
    <Card {...style}>
      {title || actions ? (
        <Flex
          alignItems="center"
          justifyContent="space-between"
          className={styles.header}>
          <span>{title}</span>
          {actions}
        </Flex>
      ) : null}
      <div style={{ fontSize: 'var(--size--1)' }}>{children}</div>
    </Card>
  );
}
