import { CryptoIcon } from '@/components';
import { Flex, textShortener } from '@blockanalitica/ui';
import { useMemo } from 'react';

const sizeMap = {
  xSmall: 12,
  small: 16,
  medium: 20,
  large: 24
};

export default function TokenName({ symbol, name, iconSize = 30, ...rest }) {
  const imgSize = useMemo(
    () => (typeof iconSize === 'string' ? sizeMap[iconSize] : iconSize),
    [iconSize]
  );

  return (
    <Flex alignItems="center" gap="space-2xs" {...rest}>
      <CryptoIcon name={symbol} size={imgSize} />
      <Flex direction="column" gap="space-3xs" alignItems="flex-start">
        <div>
          {textShortener(symbol, {
            threshold: 8,
            firstSliceLength: 6,
            secondSliceLength: 0
          })}
        </div>
        <div className="text-muted text-1">{name}</div>
      </Flex>
    </Flex>
  );
}
