import { useApi } from '@/api';
import { KpiCard, Page } from '@/components';
import CurrencyValue from '@/components/CurrencyValue/CurrencyValue';
import WalletAddress from '@/components/WalletAddress/WalletAddress';
import { TimeFilter, TimeOptions } from '@/components/global';
import { SimpleVaultCard } from '@/components/vault';
import { useSearchParams } from '@/hooks';
import { Flex, PageLoader } from '@blockanalitica/ui';
import { Link, useParams } from 'react-router-dom';
import ActivityHeatmap from './ActivityHeatmap';
import EventsTable from './EventsTable';
import HistoricTabs from './HistoricTabs';
export default function VaultDepositor() {
  const { vaultAddress, address } = useParams();
  const [daysAgo] = useSearchParams('days_ago', 1);
  const [data, error] = useApi({
    url: `/vaults/${vaultAddress}/depositors/${address}/`
  });

  if (error) {
    throw error;
  }
  if (data) {
    return (
      <Page
        title={
          <div style={{ fontSize: 'initial' }}>
            <WalletAddress
              address={data.wallet_address}
              options={{
                size: 'large',
                showEthScanLink: true,
                showDebankLink: true,
                showZapperLink: true,
                showCopyBtn: true
              }}
            />
          </div>
        }
        actions={<TimeFilter defaultValue={TimeOptions['1D']} />}>
        <Flex direction={['row', 'column', 'column']} gap="space-m">
          <Flex flexBasis={['50%', '100%', '100%']}>
            <Link to={`vaults/${vaultAddress}`} style={{ width: '100%' }}>
              <SimpleVaultCard data={data} />
            </Link>
          </Flex>
          <Flex flexBasis={['50%', '100%', '100%']}>
            <KpiCard
              title="Balance"
              value={<CurrencyValue value={data.balance_asset_usd} currency="USD" />}
              footer={
                <Flex justifyContent="space-between">
                  <Flex gap="space-2xs">
                    <CurrencyValue
                      value={data.balance}
                      currency={data.symbol}
                      iconSize="small"
                    />
                    <CurrencyValue value={data[`change_${daysAgo}d`]} trend />
                  </Flex>
                  <CurrencyValue
                    value={data.balance_asset}
                    currency={data.asset_symbol}
                    iconSize="small"
                  />
                </Flex>
              }
              options={{
                valueSize: 'text-5',
                trendValueSize: 'text-3',
                footerSize: 'text-2'
              }}
            />
          </Flex>
        </Flex>
        <HistoricTabs
          vaultAddress={vaultAddress}
          address={address}
          asset_symbol={data.asset_symbol}
        />
        <ActivityHeatmap vaultAddress={vaultAddress} address={address} />
        <EventsTable vaultAddress={vaultAddress} address={address} />
      </Page>
    );
  }
  return <PageLoader />;
}
