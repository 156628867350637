import { useApi } from '@/api';
import CurrencyValue from '@/components/CurrencyValue/CurrencyValue';
import RelativeDateTime from '@/components/RelativeDateTime/RelativeDateTime';
import { EventName } from '@/components/events';
import TableLoader from '@/components/loaders/TableLoader';
import Table from '@/components/table/Table';
import { EtherScanPictogram, Flex, useTableProps } from '@blockanalitica/ui';

export default function EventsTable({ vaultAddress, address }) {
  const { sorting, pagination } = useTableProps({
    pageSize: 10,
    initialSorting: '-order_index'
  });

  const [data, error] = useApi({
    url: `/vaults/${vaultAddress}/depositors/${address}/events/`,
    queryParams: {
      p: pagination.currentPage,
      p_size: pagination.pageSize,
      order: sorting.currentSorting
    }
  });

  if (error) {
    throw error;
  }

  let content;
  if (data) {
    const columns = [
      {
        header: 'EVENT',
        getCell: (row) => <EventName event={row.event} />
      },
      {
        header: 'AMOUNT',
        getCell: (row) => (
          <CurrencyValue
            value={row.amount}
            currency={row.symbol}
            iconSize="small"
            trend
            trendNoIcon
          />
        ),
        getCellBottom: (row) => (
          <CurrencyValue
            value={row.amount_asset}
            currency={row.asset_symbol}
            iconSize="small"
          />
        ),
        sortingField: 'amount_asset',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      },
      {
        header: 'TIME',
        getCell: (row) => <RelativeDateTime value={row.datetime} />,
        getCellBottom: (row) => (
          <Flex gap="space-3xs" className="text-muted">
            {row.block_number}
            <a
              href={`https://etherscan.io/tx/${row.tx_hash}`}
              target="_blank"
              rel="noreferrer">
              <EtherScanPictogram size={14} />
            </a>
          </Flex>
        ),
        sortingField: 'order_index',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      }
    ];
    content = (
      <Table
        rows={data['results']}
        getKey={(row) => row.order_index}
        columns={columns}
        sorting={sorting}
        pagination={{
          ...pagination,
          totalRecords: data.count
        }}
      />
    );
  } else {
    content = <TableLoader />;
  }

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center">
        <h4>Events</h4>
      </Flex>
      {content}
    </>
  );
}
