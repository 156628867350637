import { Link } from '@/components';
import PoolName from '@/components/PoolName/PoolName';
import RelativeDateTime from '@/components/RelativeDateTime/RelativeDateTime';
import WalletAddress from '@/components/WalletAddress/WalletAddress';
import { EventName } from '@/components/events';
import TableLoader from '@/components/loaders/TableLoader';
import Table from '@/components/table/Table';
import { CurrencyValue, EtherScanPictogram, Flex } from '@blockanalitica/ui';
import { useMemo } from 'react';

export default function ActivityTable({ data, tableProps }) {
  const columns = useMemo(() => {
    return [
      {
        header: 'WALLET',
        getCell: (row) => (
          <Link to={`/wallets/${row.wallet_address}`}>
            <WalletAddress
              options={{
                size: 'xSmall'
              }}
              address={row.wallet_address}
            />
          </Link>
        )
      },
      {
        header: 'MARKET',
        getCell: (row) => (
          <PoolName
            loanSymbol={row.loan_token_symbol}
            collateralSymbol={row.collateral_token_symbol}
            lltv={row.lltv}
          />
        )
      },
      {
        header: 'EVENT',
        getCell: (row) => <EventName event={row.event} />
      },
      {
        header: 'AMOUNT',
        getCell: (row) => (
          <CurrencyValue
            value={row.assets}
            options={{
              currency: row.token_symbol,
              iconSize: 'small',
              trendNoIcon: true,
              leadingCurrencySymbol: false
            }}
          />
        ),
        getCellBottom: (row) => (
          <span className="text-muted">
            <CurrencyValue value={row.assets_usd} options={{ currency: 'USD' }} />
          </span>
        ),
        sortingField: 'assets',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      },
      {
        header: 'TIME',
        getCell: (row) => <RelativeDateTime value={row.datetime} />,
        getCellBottom: (row) => (
          <Flex gap="space-3xs" className="text-muted">
            {row.block_number}
            <a
              href={`https://etherscan.io/tx/${row.tx_hash}`}
              target="_blank"
              rel="noreferrer">
              <EtherScanPictogram size={14} />
            </a>
          </Flex>
        ),
        sortingField: 'order_index',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      }
    ];
  }, []);

  if (data) {
    return (
      <Table
        rows={data['results']}
        getKey={(row) => row.order_index}
        columns={columns}
        sorting={tableProps.sorting}
        pagination={{
          ...tableProps.pagination,
          totalRecords: data.count
        }}
      />
    );
  }

  return <TableLoader />;
}
