import { Page } from '@/components';
import { Flex } from '@blockanalitica/ui';
import CapitalAtRiskTabs from './VolatileTokenStablecoin/CapitalAtRiskTabs';
import LSTCapitalAtRiskTabs from './LST/CapitalAtRiskTabs';
import PositionsAtRisk from './VolatileTokenStablecoin/PositionsAtRisk';
import LSTPositionsAtRisk from './LST/PositionsAtRisk';

export default function Risk() {
  return (
    <Page title="Risk">
      <Flex direction="column" alignContent="flex-start" gap="space-m">
        <h4>Volatile token / stablecoin</h4>
        <span className="text-muted">
          This section aggregates data exclusively from markets where the collateral
          token is volatile and the loan token is stable.
        </span>
        <Flex direction={['row', 'column', 'column']} gap="space-m">
          <Flex flexBasis={['45%', '100%', '100%']}>
            <PositionsAtRisk />
          </Flex>
          <Flex flexBasis={['55%', '100%', '100%']}>
            <CapitalAtRiskTabs />
          </Flex>
        </Flex>
      </Flex>
      <Flex direction="column" alignContent="flex-start" gap="space-m">
        <h4>Liquid Staking Token / WETH</h4>
        <span className="text-muted">
          This section aggregates data exclusively from markets where the Liquid Staking
          Tokens are used as collateral to borrow Wrapped Ether.
        </span>
        <Flex direction={['row', 'column', 'column']} gap="space-m">
          <Flex flexBasis={['55%', '100%', '100%']}>
            <LSTCapitalAtRiskTabs />
          </Flex>
          <Flex flexBasis={['45%', '100%', '100%']}>
            <LSTPositionsAtRisk />
          </Flex>
        </Flex>
      </Flex>
    </Page>
  );
}
