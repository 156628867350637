import Svg from './Svg';

export default function SearchIcon({ size = 32, color = 'currentColor', ...props }) {
  return (
    <Svg
      x="0px"
      y="0px"
      viewBox="-0.5 -0.5 14 14"
      width={size + 'px'}
      height={size + 'px'}
      fill="none"
      {...props}>
      <g id="blank-notepad--content-notes-book-notepad-notebook">
        <path
          id="Vector"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M3.7142857142857144 3.25v-2.7857142857142856"
          strokeWidth="1"></path>
        <path
          id="Vector_2"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M6.5 3.25v-2.7857142857142856"
          strokeWidth="1"></path>
        <path
          id="Vector_3"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M9.285714285714286 3.25v-2.7857142857142856"
          strokeWidth="1"></path>
        <path
          id="Vector_4"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M11.607142857142858 1.8571428571428572h-10.214285714285715c-0.5128360714285715 0 -0.9285714285714286 0.41574 -0.9285714285714286 0.9285714285714286v8.821428571428571c0 0.51285 0.41573535714285714 0.9285714285714286 0.9285714285714286 0.9285714285714286h10.214285714285715c0.51285 0 0.9285714285714286 -0.4157214285714286 0.9285714285714286 -0.9285714285714286V2.7857142857142856c0 -0.5128314285714286 -0.4157214285714286 -0.9285714285714286 -0.9285714285714286 -0.9285714285714286Z"
          strokeWidth="1"></path>
      </g>
    </Svg>
  );
}
