import { Page } from '@/components';
import { SearchInput } from '@/components/forms';
import { TimeFilter, TimeOptions } from '@/components/global';
import { useSearchParams } from '@/hooks';
import { CurrencyFilter, Flex, useTableProps } from '@blockanalitica/ui';
import VaultsTable from './VaultsTable';

export default function Vaults() {
  const [daysAgo] = useSearchParams('days_ago');
  const [searchText, setSearchText] = useSearchParams('search', '');

  const { filters } = useTableProps({
    initialFilters: { currency: 'USD' },
    filtersDebounceTime: 50
  });
  return (
    <Page title="Vaults" actions={<TimeFilter defaultValue={TimeOptions['1D']} />}>
      <Flex gap="space-m" justifyContent="flex-end">
        <SearchInput
          placeholder="Search by symbol or name"
          defaultValue={searchText}
          onValueChange={(value) => setSearchText(value)}
        />
        <CurrencyFilter
          key="currency-filter"
          width="8rem"
          currency2={{ value: 'token', displayValue: 'TOKEN' }}
          currency1={{ value: 'USD', displayValue: 'USD' }}
          {...filters}
        />
      </Flex>
      <VaultsTable daysAgo={daysAgo} searchText={searchText} filters={filters} />
    </Page>
  );
}
