import { useApi } from '@/api';
import BALogo from '@/assets/logos/BA-logo-full-white.svg?react';
import BPLogo from '@/assets/logos/BProtocol.svg?react';
import { Card, KpiCard, Page } from '@/components';
import CurrencyValue from '@/components/CurrencyValue/CurrencyValue';
import WalletAddress from '@/components/WalletAddress/WalletAddress';
import ExternalLinkButton from '@/components/forms/ExternalLinkButton';
import { TimeFilter, TimeOptions } from '@/components/global';
import VaultName from '@/components/vault/VaultName';
import { useSearchParams } from '@/hooks';
import {
  Box,
  Flex,
  Kpi,
  KpiBody,
  KpiFooter,
  KpiTitle,
  PageLoader,
  TrendValue,
  formatPercentage,
  useIsMobile
} from '@blockanalitica/ui';
import { useParams } from 'react-router-dom';
import ConfigEventsTable from './ConfigEventsTable';
import HistoricTabs from './HistoricTabs';
import LatestAllocationEvents from './LatestAllocationEvents';
import LatestVaultEvents from './LatestVaultEvents';
import MarketTable from './MarketTable';
import PendingSubmitEventsTable from './PendingSubmitEventsTable';
import TopDepositors from './TopDepositors';
import VaultPoolsTable from './VaultPoolsTable';
import VaultSupplyChart from './VaultSupplyChart';
export default function Vault() {
  const { vaultAddress } = useParams();
  const [daysAgo] = useSearchParams('days_ago', 1);
  const isMobile = useIsMobile();
  const [data, error] = useApi({
    url: `/vaults/${vaultAddress}/`,
    queryParams: {
      days_ago: daysAgo
    }
  });

  if (error) {
    throw error;
  }

  if (data) {
    return (
      <Page
        title={
          <div className="text-3">
            <VaultName
              assetSymbol={data.asset_symbol}
              symbol={data.symbol}
              name={data.name}
            />
          </div>
        }
        actions={
          !isMobile && (
            <Flex gap="space-m" justifyContent="center" alignItems="center">
              {data.featured && (
                <Flex justifyContent={'flex-end'} gap="space-m">
                  <BPLogo height={30} />
                  <BALogo height={30} />
                </Flex>
              )}
              <TimeFilter defaultValue={TimeOptions['1D']} />
            </Flex>
          )
        }>
        {isMobile && (
          <>
            <Flex gap="space-m" justifyContent={'flex-end'}>
              {data.featured && (
                <Flex justifyContent={'space-between'} gap="space-m">
                  <BPLogo height={30} />
                  <BALogo height={30} />
                </Flex>
              )}
            </Flex>
            <Flex gap="space-m" justifyContent={'flex-end'}>
              <TimeFilter defaultValue={TimeOptions['1D']} />
            </Flex>
          </>
        )}
        <Flex direction={['row', 'column', 'column']} gap="space-xs">
          <Flex flexBasis={['33%', '100%', '100%']}>
            <KpiCard
              title="Total Deposits"
              value={
                <CurrencyValue
                  value={data.total_supply}
                  currency={data.asset_symbol}
                  iconSize={30}
                />
              }
              trendValue={
                <CurrencyValue
                  value={data.total_supply - data.prev_total_supply}
                  trend
                />
              }
              footer={
                <Flex justifyContent={'space-between'} gap="space-m">
                  <CurrencyValue
                    value={data.total_supply * data.price}
                    currency="USD"
                    iconSize="large"
                  />
                  <WalletAddress
                    options={{
                      size: 'xSmall',
                      inline: true,
                      showCopyBtn: true,
                      showEthScanLink: true
                    }}
                    address={vaultAddress}
                  />
                </Flex>
              }
              options={{
                valueSize: 'text-5',
                trendValueSize: 'text-3',
                trendValueIcon: true,
                footerSize: 'text-2'
              }}
            />
          </Flex>
          <Flex flexBasis={['33%', '100%', '100%']}>
            <Kpi border="none">
              <KpiTitle>APY</KpiTitle>
              <KpiBody
                gap="space-2xs"
                className="text-4"
                justifyContent="space-between">
                <Flex
                  gap="space-2xs"
                  justifyContent={'flex-start'}
                  alignItems={'center'}>
                  <Box>{formatPercentage(data.total_rate_apy)}</Box>
                  <TrendValue
                    className="text-3"
                    value={formatPercentage(
                      data.total_rate_apy - data.prev_total_rate_apy
                    )}
                  />
                </Flex>
                <Box className="text-muted">
                  {data.featured && (
                    <Flex justifyContent={'flex-end'} gap="space-m">
                      <ExternalLinkButton
                        style={{ height: '1.5rem' }}
                        href={`https://app.morpho.org/vault?vault=${data.checksum_address}`}>
                        Deposit
                      </ExternalLinkButton>
                    </Flex>
                  )}
                </Box>
              </KpiBody>
              <KpiFooter>
                <Flex gap="space-3xs">
                  <Flex>{formatPercentage(data.supply_rate_apy)}</Flex>
                  <Flex>+</Flex>
                  <Flex gap="space-3xs">{formatPercentage(data.reward_rate_apy)}</Flex>
                  <Flex>+</Flex>
                  <Flex>
                    <CurrencyValue
                      value={data.morpho_reward_share}
                      currency="MORPHO"
                      iconSize="small"
                    />
                    /$1000
                  </Flex>
                </Flex>
              </KpiFooter>
            </Kpi>
          </Flex>

          <Flex flexBasis={['33%', '100%', '100%']}>
            <Card style={{ width: '100%' }}>
              <Flex
                direction="column"
                gap="space-3xs"
                justifyContent="space-between"
                style={{ height: '100%' }}>
                <Flex justifyContent="space-between" alignItems="center">
                  <div className="text-2">Owner:</div>
                  <Flex direction="column" alignItems="flex-end">
                    <WalletAddress
                      options={{
                        size: 'xSmall',
                        inline: true,
                        showCopyBtn: true,
                        showEthScanLink: true
                      }}
                      address={data.owner}
                    />
                  </Flex>
                </Flex>
                <Flex justifyContent="space-between" alignItems="center">
                  <div className="text-2">Curator:</div>
                  <Flex direction="column" alignItems="flex-end">
                    {data.curator ? (
                      <WalletAddress
                        options={{
                          size: 'xSmall',
                          inline: true,
                          showCopyBtn: true,
                          showEthScanLink: true
                        }}
                        address={data.curator}
                      />
                    ) : (
                      <span className="text-2">-</span>
                    )}
                  </Flex>
                </Flex>
                <Flex justifyContent="space-between" alignItems="center">
                  <div className="text-2">Allocator:</div>
                  <Flex direction="column" alignItems="flex-end">
                    {data.allocator ? (
                      <WalletAddress
                        options={{
                          size: 'xSmall',
                          inline: true,
                          showCopyBtn: true,
                          showEthScanLink: true
                        }}
                        address={data.allocator}
                      />
                    ) : (
                      <span className="text-2">-</span>
                    )}
                  </Flex>
                </Flex>

                <Flex justifyContent="space-between" alignItems="center">
                  <div className="text-2">Guardian:</div>
                  <Flex direction="column" alignItems="flex-end">
                    {data.guardian ? (
                      <WalletAddress
                        options={{
                          size: 'xSmall',
                          inline: true,
                          showCopyBtn: true,
                          showEthScanLink: true
                        }}
                        address={data.guardian}
                      />
                    ) : (
                      <span className="text-2">-</span>
                    )}
                  </Flex>
                </Flex>
              </Flex>
            </Card>
          </Flex>
        </Flex>
        <Flex direction={['row', 'column', 'column']} gap="space-xs">
          <Flex flexBasis={['33%', '100%', '100%']}>
            <Card header={{ title: 'Allocation' }} style={{ width: '100%' }}>
              <div style={{ paddingTop: 'var(--size-0)' }}>
                <VaultSupplyChart vaultAddress={vaultAddress} />
              </div>
            </Card>
          </Flex>
          <Flex flexBasis={['67%', '100%', '100%']}>
            <HistoricTabs vaultAddress={vaultAddress} assetSymbol={data.asset_symbol} />
          </Flex>
        </Flex>
        <Flex direction={['row', 'column', 'column']} gap="space-xs">
          <Flex flexBasis={['50%', '100%', '100%']}>
            <Kpi border="none" style={{ boxShadow: '0 0 10px var(--color-shadow)' }}>
              <KpiTitle>Performance Fee</KpiTitle>
              <KpiBody
                gap="space-2xs"
                className="text-4"
                justifyContent="space-between">
                <Box>{formatPercentage(data.fee)}</Box>
                {data.fee_recipient && (
                  <Box className="text-muted">
                    <WalletAddress
                      options={{
                        size: 'xSmall',
                        inline: true,
                        showCopyBtn: true,
                        showEthScanLink: true
                      }}
                      address={data.fee_recipient}
                    />
                  </Box>
                )}
              </KpiBody>
            </Kpi>
          </Flex>
          <Flex flexBasis={['50%', '100%', '100%']}>
            <KpiCard title="Timelock" value={data.timelock + 'h'} />
          </Flex>
        </Flex>

        <VaultPoolsTable vaultAddress={vaultAddress} daysAgo={daysAgo} />
        <MarketTable vaultAddress={vaultAddress} daysAgo={daysAgo} />

        <Flex direction={['row', 'column', 'column']} gap="space-xs">
          <Flex flexBasis={['33%', '100%', '100%']}>
            <TopDepositors
              vaultAddress={vaultAddress}
              totalSupply={data.total_supply}
            />
          </Flex>
          <Flex flexBasis={['33%', '100%', '100%']}>
            <LatestVaultEvents vaultAddress={vaultAddress} />
          </Flex>
          <Flex flexBasis={['33%', '100%', '100%']}>
            <LatestAllocationEvents vaultAddress={vaultAddress} />
          </Flex>
        </Flex>
        <PendingSubmitEventsTable vaultAddress={vaultAddress} daysAgo={daysAgo} />

        <ConfigEventsTable vaultAddress={vaultAddress} daysAgo={daysAgo} />
      </Page>
    );
  }

  return <PageLoader />;
}
