import { Card as CardComponent } from '@blockanalitica/ui';

export default function Card({ children, style, ...rest }) {
  return (
    <CardComponent
      borderless={true}
      style={{ boxShadow: '0 0 10px var(--color-shadow)', ...style }}
      {...rest}>
      {children}
    </CardComponent>
  );
}
