import { useApi } from '@/api';
import { Card } from '@/components';
import CurrencyValue from '@/components/CurrencyValue/CurrencyValue';
import { LineChart, TimeSelector } from '@/components/charts';
import { formatCurreny } from '@/utils';
import { CardLoader, Flex } from '@blockanalitica/ui';
import { useState } from 'react';

export default function HistoricTabs({ address, vaultAddress, asset_symbol }) {
  const [daysAgo, setDaysAgo] = useState(30);

  const [data, error] = useApi({
    url: `/vaults/${vaultAddress}/depositors/${address}/historic/`,
    queryParams: {
      days_ago: daysAgo
    }
  });

  if (error) {
    throw error;
  }

  if (data) {
    const valueFormatter = (value) => (
      <CurrencyValue value={value} currency={asset_symbol} iconSize={30} />
    );
    const yScaleValueFormatter = (value) => formatCurreny(value, asset_symbol);

    return (
      <Card>
        <Flex direction="column" gap="space-xs">
          <Flex
            direction={['row', 'row', 'column']}
            alignItems={['flex-start', 'flex-start', 'center']}
            justifyContent="space-between"
            gap="space-xs">
            <h4>Balance</h4>
            <TimeSelector
              activeOption={daysAgo}
              onChange={(e) => setDaysAgo(e.target.value)}
            />
          </Flex>
          <LineChart
            data={data}
            field={'balance_asset'}
            valueFormatter={valueFormatter}
            yScaleValueFormatter={yScaleValueFormatter}
            daysAgo={daysAgo}
          />
        </Flex>
      </Card>
    );
  }

  return <CardLoader headerHeight="3rem" bodyHeight="7rem" />;
}
