import { useApi } from '@/api';
import { Link } from '@/components';
import CurrencyValue from '@/components/CurrencyValue/CurrencyValue';
import RelativeDateTime from '@/components/RelativeDateTime/RelativeDateTime';
import WalletAddress from '@/components/WalletAddress/WalletAddress';
import TableLoader from '@/components/loaders/TableLoader';
import Table from '@/components/table/Table';
import VaultName from '@/components/vault/VaultName';
import { useTableProps } from '@blockanalitica/ui';

export default function DepositorsTable({ daysAgo, searchText }) {
  const { sorting, pagination } = useTableProps({
    pageSize: 15,
    initialSorting: '-balance'
  });
  const [data, error] = useApi({
    url: '/vaults/depositors/',
    queryParams: {
      p: pagination.currentPage,
      p_size: pagination.pageSize,
      days_ago: daysAgo,
      order: sorting.currentSorting,
      search: searchText
    }
  });

  if (error) {
    throw error;
  }

  if (data) {
    const columns = [
      {
        header: 'WALLET',
        getCell: (row) => (
          <WalletAddress
            options={{
              size: 'xSmall'
            }}
            address={row.wallet_address}
          />
        ),
        sortingField: 'wallet_address',
        cellAlign: 'flex-start'
      },
      {
        header: 'VAULT',
        getCell: (row) => (
          <VaultName
            assetSymbol={row.asset_symbol}
            symbol={row.symbol}
            name={row.name}
          />
        ),
        cellAlign: 'flex-start'
      },
      {
        header: 'BALANCE USD',
        getCell: (row) => (
          <CurrencyValue value={row.balance_asset * row.price} currency={'USD'} />
        ),
        sortingField: 'balance',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      },
      {
        header: 'BALANCE',
        getCell: (row) => <CurrencyValue value={row.balance} />,
        getCellBottom: (row) => (
          <CurrencyValue
            value={row.balance_asset}
            currency={row.asset_symbol}
            iconSize="small"
          />
        ),
        sortingField: 'balance',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      },
      {
        header: `CHANGE ${daysAgo}d`,
        getCell: (row) => <CurrencyValue value={row[`change_${daysAgo}d`]} trend />,
        sortingField: 'balance',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      },
      {
        header: 'TIME',
        getCell: (row) => <RelativeDateTime value={row.datetime} />,
        sortingField: 'order_index',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      }
    ];
    return (
      <Table
        rows={data['results']}
        columns={columns}
        getKey={(row) => row.uid}
        getLinkWrapper={(row) => <Link to={`${row.address}`} />}
        sorting={sorting}
        pagination={{
          ...pagination,
          totalRecords: data.count
        }}
      />
    );
  }
  return <TableLoader />;
}
