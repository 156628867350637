import { useApi } from '@/api';
import { Page, Tabs } from '@/components';
import PoolName from '@/components/PoolName/PoolName';
import SearchInput from '@/components/forms/SearchInput';
import { TimeFilter, TimeOptions } from '@/components/global';
import { useSearchParams } from '@/hooks';
import { CurrencyFilter, Flex, PageLoader, useTableProps } from '@blockanalitica/ui';
import { useParams } from 'react-router-dom';
import BorrowersTable from './BorrowersTable';
import SuppliersTable from './SuppliersTable';

export default function Wallets() {
  const [daysAgo] = useSearchParams('days_ago', 1);
  const [searchText, setSearchText] = useSearchParams('search', '');
  const [activeTab, setActiveTab] = useSearchParams('tab', 'Borrowers');
  const { poolUid } = useParams();

  const { filters } = useTableProps({
    initialFilters: { currency: 'USD' },
    filtersDebounceTime: 50
  });

  const handleTabClick = (tab) => {
    setActiveTab(tab.label);
  };

  const [data, error] = useApi({
    url: `/markets/${poolUid}/`
  });

  if (error) {
    throw error;
  }

  const onValueChange = (value) => {
    setSearchText(value);
  };

  if (data) {
    const tabs = [
      {
        label: 'Borrowers',
        content: (
          <BorrowersTable
            poolUid={poolUid}
            daysAgo={daysAgo}
            filters={filters}
            searchText={searchText}
          />
        )
      },
      {
        label: 'Suppliers',
        content: (
          <SuppliersTable
            poolUid={poolUid}
            daysAgo={daysAgo}
            filters={filters}
            searchText={searchText}
          />
        )
      }
    ];

    return (
      <Page
        title={
          <Flex gap="space-xs">
            <PoolName
              loanSymbol={data.loan_token_symbol}
              collateralSymbol={data.collateral_token_symbol}
              iconSize={40}
            />
            Wallets
          </Flex>
        }
        actions={
          <Flex gap="space-xs">
            <TimeFilter defaultValue={TimeOptions['1D']} />
          </Flex>
        }>
        <Flex gap="space-xs" justifyContent={'flex-end'}>
          <SearchInput
            placeholder="Search by address"
            defaultValue={searchText}
            onValueChange={(value) => onValueChange(value)}
          />
          <CurrencyFilter
            key="currency-filter"
            width="8rem"
            height="auto"
            currency1={{ value: 'token', displayValue: 'TOKEN' }}
            currency2={{ value: 'USD', displayValue: 'USD' }}
            {...filters}
          />
        </Flex>
        <Tabs
          data={tabs}
          options={{
            externalStateHandler: {
              activeTab,
              onTabClick: handleTabClick
            }
          }}
        />
      </Page>
    );
  }
  return <PageLoader />;
}
