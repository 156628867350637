import { useApi } from '@/api';
import BALogo from '@/assets/logos/BA-logo-full-white.svg?react';
import BPLogo from '@/assets/logos/BProtocol.svg?react';
import { Link } from '@/components';
import CurrencyValue from '@/components/CurrencyValue/CurrencyValue';
import TableLoader from '@/components/loaders/TableLoader';
import Table from '@/components/table/Table';
import VaultName from '@/components/vault/VaultName';
import {
  Flex,
  TrendValue,
  formatPercentage,
  useIsMobile,
  useTableProps
} from '@blockanalitica/ui';
export default function VaultsTable({ daysAgo, searchText, filters }) {
  const { sorting, pagination } = useTableProps({
    pageSize: 15,
    initialSorting: '-tvl'
  });
  const [data, error] = useApi({
    url: '/vaults/',
    queryParams: {
      p: pagination.currentPage,
      p_size: pagination.pageSize,
      days_ago: daysAgo,
      order: sorting.currentSorting,
      search: searchText
    }
  });

  const isMobile = useIsMobile();

  if (error) {
    throw error;
  }

  if (data) {
    const columns = [
      {
        header: 'VAULT',
        getCell: (row) => (
          <VaultName
            assetSymbol={row.asset_symbol}
            symbol={row.symbol}
            name={row.name}
          />
        ),
        cellAlign: 'flex-start'
      },
      {
        header: 'DEPOSITS',
        getCell: (row) => (
          <>
            {filters.filtersApplied.currency === 'USD' ? (
              <CurrencyValue value={row.total_supply_usd} currency={'USD'} />
            ) : (
              <CurrencyValue
                value={row.total_supply}
                currency={row.asset_symbol}
                iconSize="small"
              />
            )}
          </>
        ),
        getCellBottom: (row) => (
          <>
            {filters.filtersApplied.currency === 'USD' ? (
              <CurrencyValue
                value={row.total_supply_usd - row.prev_total_supply_usd}
                currency={'USD'}
                trend
              />
            ) : (
              <CurrencyValue
                value={row.total_supply - row.prev_total_supply}
                currency={row.asset_symbol}
                iconSize="small"
                trend
              />
            )}
          </>
        ),
        sortingField: 'total_supply_usd',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      },
      {
        header: 'APY',
        getCell: (row) => formatPercentage(row.supply_rate_apy),
        getCellBottom: (row) => (
          <TrendValue
            value={formatPercentage(row.supply_rate_apy - row.prev_supply_rate_apy)}
          />
        ),
        sortingField: 'supply_rate_apy',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      },
      {
        header: 'NET APY',
        getCell: (row) => formatPercentage(row.total_rate_apy),
        getCellBottom: (row) => (
          <TrendValue
            value={formatPercentage(row.total_rate_apy - row.prev_total_rate_apy)}
          />
        ),
        sortingField: 'total_rate_apy',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      },
      {
        header: '# of MARKETS',
        getCell: (row) => <CurrencyValue value={row.pool_count} iconSize="small" />,
        getCellBottom: (row) => (
          <CurrencyValue
            value={row.pool_count - row.prev_pool_count}
            iconSize="small"
            trend
          />
        ),
        sortingField: 'pool_count',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      }
    ];

    if (isMobile) {
      columns.splice(4, 0, {
        header: '',
        getCell: (row) =>
          row.featured ? (
            <Flex justifyContent={'flex-start'} gap="space-m" style={{ opacity: 0.6 }}>
              <BPLogo height={30} />
              <BALogo height={30} />
            </Flex>
          ) : (
            ''
          )
      });
    } else {
      columns.splice(1, 0, {
        header: '',
        getCell: (row) =>
          row.featured ? (
            <Flex justifyContent={'flex-start'} gap="space-m" style={{ opacity: 0.6 }}>
              <BPLogo height={30} />
              <BALogo height={30} />
            </Flex>
          ) : (
            ''
          )
      });
    }
    return (
      <Table
        rows={data['results']}
        columns={columns}
        getKey={(row) => row.uid}
        getLinkWrapper={(row) => <Link to={`${row.address}`} />}
        sorting={sorting}
        pagination={{
          ...pagination,
          totalRecords: data.count
        }}
      />
    );
  }
  return <TableLoader />;
}
