import { useApi } from '@/api';
import { MultiLineTimeSeriesChart } from '@/components/charts';
import { CardLoader, formatPercentage } from '@blockanalitica/ui';

export default function LTVHistoryChart({ daysAgo }) {
  const [data, error] = useApi({
    url: `/risk/loan-to-value-history/`,
    queryParams: {
      days_ago: daysAgo,
      scenario: 'long'
    }
  });

  if (error) {
    throw error;
  }

  if (data) {
    return (
      <MultiLineTimeSeriesChart
        data={data}
        yScaleValueFormatter={formatPercentage}
        valueFormatter={formatPercentage}
        aspectRatio={3}
      />
    );
  }

  return <CardLoader headerHeight="3rem" bodyHeight="7rem" />;
}
