import { useApi } from '@/api';
import { Link } from '@/components';
import CurrencyValue from '@/components/CurrencyValue/CurrencyValue';
import PoolName from '@/components/PoolName/PoolName';
import TableLoader from '@/components/loaders/TableLoader';
import Table from '@/components/table/Table';
import {
  CurrencyFilter,
  Flex,
  TrendValue,
  formatPercentage,
  useTableProps
} from '@blockanalitica/ui';

export default function VaultPoolsTable({ vaultAddress, daysAgo }) {
  const { sorting, filters, pagination } = useTableProps({
    initialSorting: '-supply_usd',
    pageSize: 15,
    initialFilters: { currency: 'token' },
    filtersDebounceTime: 50
  });
  let [data, error] = useApi({
    url: `/vaults/${vaultAddress}/pools/`,
    queryParams: {
      p: pagination.currentPage,
      p_size: pagination.pageSize,
      days_ago: daysAgo,
      order: sorting.currentSorting
    }
  });

  if (error) {
    if (error.status === 404) {
      data = { results: [] };
    } else {
      throw new Error(error);
    }
  }

  if (data) {
    const columns = [
      {
        header: 'MARKET',
        getCell: (row) => (
          <PoolName
            loanSymbol={row.loan_token_symbol}
            collateralSymbol={row.collateral_token_symbol}
            lltv={row.lltv}
          />
        ),
        cellAlign: 'flex-start'
      },
      {
        header: 'SUPPLY',
        getCell: (row) => (
          <>
            {filters.filtersApplied.currency === 'USD' ? (
              <CurrencyValue value={row.supply_usd} currency="USD" />
            ) : (
              <CurrencyValue
                value={row.supply}
                currency={row.loan_token_symbol}
                iconSize="small"
              />
            )}
          </>
        ),
        getCellBottom: (row) => (
          <>
            {filters.filtersApplied.currency === 'USD' ? (
              <CurrencyValue
                value={row[`supply_change_${daysAgo}d_usd`]}
                currency="USD"
                trend
              />
            ) : (
              <CurrencyValue
                value={row[`supply_change_${daysAgo}d`]}
                currency={row.loan_token_symbol}
                iconSize="small"
                trend
              />
            )}
          </>
        ),
        sortingField: 'supply_usd',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      },
      {
        header: 'SUPPLY CAP',
        getCell: (row) => (
          <>
            {filters.filtersApplied.currency === 'USD' ? (
              <CurrencyValue value={row.cap * row.loan_token_price} currency="USD" />
            ) : (
              <CurrencyValue
                value={row.cap}
                currency={row.loan_token_symbol}
                iconSize="small"
              />
            )}
          </>
        ),
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      },
      {
        header: 'SUPPLY APY',
        getCell: (row) => formatPercentage(row.supply_rate_apy),
        getCellBottom: (row) => (
          <TrendValue
            value={formatPercentage(row.supply_rate_apy - row.prev_supply_rate_apy)}
          />
        ),
        sortingField: 'supply_rate_apy',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      },
      {
        header: 'NET APY',
        getCell: (row) =>
          row.collateral_token_symbol ? formatPercentage(row.total_rate_apy) : '-',
        getCellBottom: (row) => (
          <TrendValue
            value={formatPercentage(row.total_rate_apy - row.prev_total_rate_apy)}
          />
        ),
        sortingField: 'total_rate_apy',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      },
      {
        header: 'SHARE IN MARKET',
        getCell: (row) => formatPercentage(row.share),
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      }
    ];
    return (
      <>
        <Flex justifyContent="space-between" alignItems="center">
          <h4>Allocation</h4>
          <CurrencyFilter
            key="currency-filter"
            width="8rem"
            currency2={{ value: 'token', displayValue: 'TOKEN' }}
            currency1={{ value: 'USD', displayValue: 'USD' }}
            {...filters}
          />
        </Flex>
        <Table
          rows={data['results']}
          columns={columns}
          sorting={sorting}
          getKey={(row) => row.uid}
          getLinkWrapper={(row) => <Link to={`/markets/${row.market_uid}/`} />}
          pagination={{
            ...pagination,
            totalRecords: data.count
          }}
        />
      </>
    );
  }
  return <TableLoader />;
}
