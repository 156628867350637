import { Page, Tabs } from '@/components';
import { SearchInput } from '@/components/forms';
import { TimeFilter, TimeOptions } from '@/components/global';
import { useSearchParams } from '@/hooks';
import { CurrencyFilter, Flex, useTableProps } from '@blockanalitica/ui';
import BorrowersTable from './BorrowersTable';
import SuppliersTable from './SuppliersTable';

export default function Positions() {
  const [daysAgo] = useSearchParams('days_ago', 1);
  const [activeTab, setActiveTab] = useSearchParams('tab', 'Borrowers');
  const [searchText, setSearchText] = useSearchParams('search', '');

  const { filters } = useTableProps({
    initialFilters: { currency: 'USD' },
    filtersDebounceTime: 50
  });

  const handleTabClick = (tab) => {
    setActiveTab(tab.label);
  };

  const tabs = [
    {
      label: 'Borrowers',
      content: (
        <BorrowersTable daysAgo={daysAgo} searchText={searchText} filters={filters} />
      )
    },
    {
      label: 'Suppliers',
      content: (
        <SuppliersTable daysAgo={daysAgo} searchText={searchText} filters={filters} />
      )
    }
  ];

  return (
    <Page title="Positions" actions={<TimeFilter defaultValue={TimeOptions['1D']} />}>
      <Flex gap="space-xs" justifyContent={['flex-end', 'flex-end', 'space-between']}>
        <SearchInput
          placeholder="Search by wallet address"
          defaultValue={searchText}
          onValueChange={(value) => setSearchText(value)}
        />
        <CurrencyFilter
          key="currency-filter"
          width="8rem"
          height="auto"
          currency2={{ value: 'token', displayValue: 'TOKEN' }}
          currency1={{ value: 'USD', displayValue: 'USD' }}
          {...filters}
        />
      </Flex>
      <Tabs
        data={tabs}
        options={{
          externalStateHandler: {
            activeTab,
            onTabClick: handleTabClick
          }
        }}
      />
    </Page>
  );
}
