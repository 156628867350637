import Svg from './Svg';

export default function QutestionSolidIcon({
  size = 32,
  color = 'currentColor',
  ...props
}) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 14 14"
      width={size + 'px'}
      height={size + 'px'}
      {...props}>
      <g id="help-question-1--circle-faq-frame-help-info-mark-more-query-question">
        <path
          id="Ellipse 373"
          fill={color}
          d="M0 7a7 7 0 1 0 14 0A7 7 0 1 0 0 7"
          strokeWidth="1"></path>
        <g id="Union">
          <path
            fill="#021935"
            d="M5.75 5.25A1.25 1.25 0 1 1 7 6.5a0.75 0.75 0 0 0 -0.75 0.75v0.646a0.75 0.75 0 1 0 1.5 0 2.751 2.751 0 1 0 -3.5 -2.646 0.75 0.75 0 0 0 1.5 0Z"
            strokeWidth="1"></path>
        </g>
        <g id="Union_2">
          <path
            fill="#021935"
            d="M7 11.5a1 1 0 1 0 0 -2 1 1 0 0 0 0 2Z"
            strokeWidth="1"></path>
        </g>
      </g>
    </Svg>
  );
}
