import DisplaySwitch from '@/components/DisplaySwitch/DisplaySwitch';
import { Flex } from '@blockanalitica/ui';
import { Card } from '@/components';
import { useState } from 'react';
import PoolCapitalAtRiskChart from './PoolCapitalAtRiskChart';
import PoolCapitalAtRiskBar from './PoolCapitalAtRiskBar';
import PoolCapitalAtRiskHistoryChart from './PoolCapitalAtRiskHistoryChart';
export default function PoolCapitalAtRiskTabs({ poolUid, collateralSymbol }) {
  const [activeDisplayOption, setActiveDisplayOption] = useState('car');

  const displayOptions = [
    { key: 'car', value: 'Collateral at risk' },
    { key: 'car-bar', value: 'Collateral at risk - per drop' },
    { key: 'car-history', value: 'Collateral at risk - history' }
  ];

  let content;

  if (activeDisplayOption === 'car') {
    content = (
      <PoolCapitalAtRiskChart poolUid={poolUid} collateralSymbol={collateralSymbol} />
    );
  } else if (activeDisplayOption === 'car-bar') {
    content = (
      <PoolCapitalAtRiskBar poolUid={poolUid} collateralSymbol={collateralSymbol} />
    );
  } else if (activeDisplayOption === 'car-history') {
    content = (
      <PoolCapitalAtRiskHistoryChart
        poolUid={poolUid}
        collateralSymbol={collateralSymbol}
      />
    );
  }
  return (
    <Card style={{ width: '100%' }}>
      <Flex direction="column" gap="space-xs">
        <Flex
          direction={['row', 'row', 'column']}
          alignItems={['flex-start', 'flex-start', 'center']}
          justifyContent="space-between"
          gap="space-xs">
          <DisplaySwitch
            options={displayOptions}
            activeOption={activeDisplayOption}
            onChange={setActiveDisplayOption}
          />
        </Flex>
        {content}
      </Flex>
    </Card>
  );
}
