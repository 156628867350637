import { useApi } from '@/api';
import { CardList, Link } from '@/components';
import CurrencyValue from '@/components/CurrencyValue/CurrencyValue';
import RelativeDateTime from '@/components/RelativeDateTime/RelativeDateTime';
import WalletAddress from '@/components/WalletAddress/WalletAddress';
import { EmptyBox } from '@/components/empty';
import { EventName } from '@/components/events';
import { LinkButton } from '@/components/forms';
import { CardLoader, Flex, List } from '@blockanalitica/ui';

export default function LatestEventsListCard({ poolUid }) {
  const [data, error] = useApi({
    url: `/markets/${poolUid}/events/`,
    queryParams: {
      p_size: 4
    }
  });

  if (error) {
    throw error;
  }

  if (data) {
    let content = undefined;
    if (data['results'].length > 0) {
      content = (
        <List>
          {data['results'].map((row) => (
            <Link key={row.wallet_address} to={`wallets/${row.wallet_address}`}>
              <Flex justifyContent="space-between" alignItems="center">
                <WalletAddress
                  options={{
                    size: 'xSmall'
                  }}
                  address={row.wallet_address}
                />
                <span className="text-2">
                  <EventName event={row.event} />
                </span>
                <Flex direction="column" gap="space-3xs" alignItems="flex-end">
                  <CurrencyValue
                    value={row.assets}
                    currency={row.token_symbol}
                    iconSize="small"
                    trendNoIcon={true}
                  />
                  <span className="text-2 text-muted">
                    <RelativeDateTime value={row.datetime} />
                  </span>
                </Flex>
              </Flex>
            </Link>
          ))}
        </List>
      );
    } else {
      content = <EmptyBox />;
    }

    return (
      <CardList
        style={{ width: '100%' }}
        title="Latest Events"
        actions={<LinkButton to="events">view all</LinkButton>}>
        {content}
      </CardList>
    );
  }

  return <CardLoader headerHeight="3rem" bodyHeight="7rem" />;
}
