import { useApi } from '@/api';
import CurrencyValue from '@/components/CurrencyValue/CurrencyValue';
import { CardLoader } from '@blockanalitica/ui';
import { BarChartCategory } from '@/components/charts';
import { useMemo } from 'react';
import { formatCurreny } from '@/utils';

export default function PoolCapitalAtRiskBar({ poolUid, collateralSymbol }) {
  const [data, error] = useApi({
    url: `/markets/${poolUid}/capital-at-risk/`,
    queryParams: {
      chart: 'bar'
    }
  });

  const options = useMemo(() => {
    const valueFormatter = (value) => (
      <CurrencyValue value={value} currency={collateralSymbol} />
    );
    const yScaleValueFormatter = (value) => formatCurreny(value, collateralSymbol);
    return {
      valueFormatter,
      yScaleValueFormatter
    };
  }, [collateralSymbol]);

  const fields = useMemo(
    () => [
      {
        field: 'amount',
        label: 'price_change',
        backgroundColor: '#5792FF'
      }
    ],
    []
  );

  if (error) {
    throw new Error(error.message);
  }

  if (data) {
    return (
      <BarChartCategory
        data={data}
        fields={fields}
        labelField={'price_change'}
        valueFormatter={options.valueFormatter}
        yScaleValueFormatter={options.yScaleValueFormatter}
      />
    );
  }

  return <CardLoader headerHeight="3rem" bodyHeight="7rem" />;
}
