import { useApi } from '@/api';
import { HealthRate, Link } from '@/components';
import CurrencyValue from '@/components/CurrencyValue/CurrencyValue';
import RelativeDateTime from '@/components/RelativeDateTime/RelativeDateTime';
import WalletAddress from '@/components/WalletAddress/WalletAddress';
import TableLoader from '@/components/loaders/TableLoader';
import Table from '@/components/table/Table';
import { formatPercentage, useTableProps } from '@blockanalitica/ui';

export default function BorrowersTable({ poolUid, daysAgo, filters, searchText }) {
  const { sorting, pagination } = useTableProps({
    pageSize: 10,
    initialSorting: '-borrow',
    initialFilters: { currency: 'USD' },
    filtersDebounceTime: 50
  });

  const [data, error] = useApi({
    url: `/markets/${poolUid}/wallets/`,
    queryParams: {
      p: pagination.currentPage,
      p_size: pagination.pageSize,
      type: 'borrowers',
      order: sorting.currentSorting,
      search: searchText
    }
  });

  if (error) {
    throw error;
  }

  if (data) {
    const columns = [
      {
        header: 'WALLET',
        getCell: (row) => (
          <WalletAddress
            options={{
              size: 'xSmall'
            }}
            address={row.wallet_address}
          />
        ),
        sortingField: 'wallet_address'
      },
      {
        header: 'COLLATERAL',
        getCell: (row) => (
          <>
            {filters.filtersApplied.currency === 'USD' ? (
              <CurrencyValue value={row.collateral_usd} currency="USD" />
            ) : (
              <CurrencyValue
                value={row.collateral}
                currency={row.collateral_token_symbol}
                iconSize="small"
              />
            )}
          </>
        ),
        getCellBottom: (row) => (
          <>
            {filters.filtersApplied.currency === 'USD' ? (
              <CurrencyValue
                value={row[`collateral_change_${daysAgo}d_usd`]}
                currency="USD"
                trend
              />
            ) : (
              <CurrencyValue
                value={row[`collateral_change_${daysAgo}d`]}
                currency={row.collateral_token_symbol}
                iconSize="small"
                trend
              />
            )}
          </>
        ),
        sortingField: 'collateral',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      },
      {
        header: 'BORROW',
        getCell: (row) => (
          <>
            {filters.filtersApplied.currency === 'USD' ? (
              <CurrencyValue value={row.borrow_usd} currency="USD" />
            ) : (
              <CurrencyValue
                value={row.borrow}
                currency={row.loan_token_symbol}
                iconSize="small"
              />
            )}
          </>
        ),
        getCellBottom: (row) => (
          <>
            {filters.filtersApplied.currency === 'USD' ? (
              <CurrencyValue
                value={row[`borrow_change_${daysAgo}d_usd`]}
                currency="USD"
                trend
              />
            ) : (
              <CurrencyValue
                value={row[`borrow_change_${daysAgo}d`]}
                currency={row.loan_token_symbol}
                iconSize="small"
                trend
              />
            )}
          </>
        ),
        sortingField: 'borrow',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      },
      {
        header: 'BORROW SHARE',
        getCell: (row) => (
          <span className="text-2">
            {formatPercentage(row.borrow / data.total_borrow)}
          </span>
        ),
        sortingField: 'borrow',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      },
      {
        header: 'HEALTH RATE',
        getCell: (row) => <HealthRate value={row.health_rate} />,
        sortingField: 'health_rate',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      },
      {
        header: 'LATEST ACTIVITY',
        getCell: (row) => <RelativeDateTime value={row.datetime} />,
        sortingField: 'datetime',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      }
    ];
    return (
      <Table
        rows={data['results']}
        getKey={(row) => row.wallet_address}
        getLinkWrapper={(row) => <Link to={`${row.wallet_address}`}></Link>}
        columns={columns}
        sorting={sorting}
        pagination={{
          ...pagination,
          totalRecords: data.count
        }}
      />
    );
  }

  return <TableLoader />;
}
