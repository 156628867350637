import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import App from './App.jsx';
import { ErrorBoundary } from '@/components';

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: 'morpho-ui',
  integrations: [],
  tracesSampleRate: 0
});

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<ErrorBoundary />}>
      <App />
    </Sentry.ErrorBoundary>
  </React.StrictMode>
);
