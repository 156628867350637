import { Page, Tabs } from '@/components';
import SearchInput from '@/components/forms/SearchInput';
import { TimeFilter, TimeOptions } from '@/components/global';
import { useSearchParams } from '@/hooks';
import { Flex } from '@blockanalitica/ui';
import BorrowersTable from './BorrowersTable';
import SuppliersTable from './SuppliersTable';

export default function Wallets() {
  const [daysAgo] = useSearchParams('days_ago', 1);
  const [searchText, setSearchText] = useSearchParams('search', '');
  const [activeTab, setActiveTab] = useSearchParams('tab', 'Borrowers');

  const handleTabClick = (tab) => {
    setActiveTab(tab.label);
  };

  const tabs = [
    {
      label: 'Borrowers',
      content: <BorrowersTable daysAgo={daysAgo} searchText={searchText} />
    },
    {
      label: 'Suppliers',
      content: <SuppliersTable daysAgo={daysAgo} searchText={searchText} />
    }
  ];

  return (
    <Page title="Wallets" actions={<TimeFilter defaultValue={TimeOptions['1D']} />}>
      <Flex gap="space-xs" justifyContent={['flex-end', 'flex-end', 'center']}>
        <SearchInput
          placeholder="Search by address"
          defaultValue={searchText}
          onValueChange={(value) => setSearchText(value)}
        />
      </Flex>
      <Tabs
        data={tabs}
        options={{
          externalStateHandler: {
            activeTab,
            onTabClick: handleTabClick
          }
        }}
      />
    </Page>
  );
}
