import { useApi } from '@/api';
import { CardList, Link } from '@/components';
import CurrencyValue from '@/components/CurrencyValue/CurrencyValue';
import WalletAddress from '@/components/WalletAddress/WalletAddress';
import { EmptyBox } from '@/components/empty';
import { LinkButton } from '@/components/forms';
import { CardLoader, Flex, List, formatPercentage } from '@blockanalitica/ui';

export default function BorrowersList({ poolUid, totalBorrow }) {
  const [data, error] = useApi({
    url: `/markets/${poolUid}/wallets/`,
    queryParams: {
      p_size: 4,
      type: 'borrowers',
      order: '-borrow'
    }
  });

  if (error) {
    throw error;
  }

  if (data) {
    let content = undefined;
    if (data['results'].length > 0) {
      content = (
        <List>
          {data['results'].map((item) => (
            <Link key={item.wallet_address} to={`wallets/${item.wallet_address}`}>
              <Flex justifyContent="space-between">
                <Flex justifyContent="space-between" style={{ flexBasis: '66%' }}>
                  <WalletAddress
                    options={{
                      size: 'xSmall'
                    }}
                    address={item.wallet_address}
                  />
                  <Flex direction="column" gap="space-3xs" alignItems="flex-end">
                    <span className="text-2">
                      {formatPercentage(item.borrow / totalBorrow)}
                    </span>
                    <span className="text-2 text-muted">share</span>
                  </Flex>
                </Flex>
                <Flex direction="column" gap="space-3xs" alignItems="flex-end">
                  <CurrencyValue
                    value={item.borrow}
                    currency={item.loan_token_symbol}
                    iconSize="small"
                  />
                  <span className="text-2 text-muted">
                    <CurrencyValue value={item.borrow_usd} currency={'USD'} />
                  </span>
                </Flex>
              </Flex>
            </Link>
          ))}
        </List>
      );
    } else {
      content = <EmptyBox />;
    }

    return (
      <CardList
        style={{ width: '100%' }}
        title="Borrowers"
        actions={
          <LinkButton
            to={{
              pathname: 'wallets',
              search: `?tab=Borrowers`
            }}>
            view all
          </LinkButton>
        }>
        {content}
      </CardList>
    );
  }

  return <CardLoader headerHeight="3rem" bodyHeight="7rem" />;
}
