import { Link } from '@/components';
import CurrencyValue from '@/components/CurrencyValue/CurrencyValue';
import RelativeDateTime from '@/components/RelativeDateTime/RelativeDateTime';
import WalletAddress from '@/components/WalletAddress/WalletAddress';
import { EventName } from '@/components/events';
import Table from '@/components/table/Table';
import { EtherScanPictogram, Flex } from '@blockanalitica/ui';
import { useMemo } from 'react';

export default function VaultEventsTable({ tableProps }) {
  const { data, sorting, pagination, vaultAddress } = tableProps;

  const columns = useMemo(() => {
    return [
      {
        header: 'WALLET',
        getCell: (row) => (
          <Link to={`/vaults/vaults/${vaultAddress}/depositors/${row.wallet_address}`}>
            <WalletAddress
              options={{
                size: 'xSmall'
              }}
              address={row.wallet_address}
            />
          </Link>
        )
      },
      {
        header: 'EVENT',
        getCell: (row) => <EventName event={row.event} />
      },
      {
        header: 'AMOUNT',
        getCell: (row) => (
          <CurrencyValue
            value={row.assets}
            currency={row.loan_token_symbol}
            iconSize="small"
            trendNoIcon={true}
          />
        ),
        getCellBottom: (row) => (
          <span className="text-muted">
            <CurrencyValue value={row.assets_usd} currency={'USD'} />
          </span>
        ),
        sortingField: 'assets',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      },
      {
        header: 'TIME',
        getCell: (row) => <RelativeDateTime value={row.datetime} />,
        getCellBottom: (row) => (
          <Flex gap="space-3xs" className="text-muted">
            {row.block_number}
            <a
              href={`https://etherscan.io/tx/${row.tx_hash}`}
              target="_blank"
              rel="noreferrer">
              <EtherScanPictogram size={14} />
            </a>
          </Flex>
        ),
        sortingField: 'order_index',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      }
    ];
  }, [vaultAddress]);

  return (
    <Table
      rows={data['results']}
      columns={columns}
      getKey={(row) => row.order_index}
      sorting={sorting}
      pagination={{
        ...pagination,
        totalRecords: data.count
      }}
    />
  );
}
