import { useState } from 'react';
import styles from './SearchInput.module.scss';
import SearchIcon from '@/components/icons/SearchIcon';
import { Flex, useDebounceCallback } from '@blockanalitica/ui';

export default function SearchInput({
  onValueChange,
  defaultValue = '',
  iconSize = 16,
  debounceTime = 400,
  ...rest
}) {
  const [value, setValue] = useState(defaultValue);
  const onChangeCallback = useDebounceCallback(onValueChange, debounceTime);

  const onChange = (value) => {
    setValue(value);
    onChangeCallback(value);
  };

  return (
    <Flex className={styles.container} alignItems="center">
      <SearchIcon className={styles.icon} size={iconSize} />
      <input
        className={styles.input}
        onChange={(e) => onChange(e.target.value)}
        value={value}
        {...rest}
      />
    </Flex>
  );
}
