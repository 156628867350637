import { useApi } from '@/api';
import {
  formatPercentage,
  CardLoader,
  DoughnutChart,
  chartsRgbColorPallete
} from '@blockanalitica/ui';
import { EmptyBox } from '@/components/empty';

export default function VaultSupplyChart({ vaultAddress }) {
  const [data, error] = useApi({
    url: `/vaults/${vaultAddress}/pools-supply/`
  });

  if (error) {
    if (error.status === 404) {
      return <EmptyBox />;
    } else {
      throw new Error(error);
    }
  }

  if (data) {
    const colors = chartsRgbColorPallete(1);
    const customConfig = {
      valueFormatter: formatPercentage
    };

    const chartData = {
      labels: data.map((row) => {
        if (row.collateral_token_symbol) {
          return `${row.collateral_token_symbol} / ${row.loan_token_symbol}`;
        }
        return row.loan_token_symbol;
      }),
      datasets: [
        {
          data: data.map((row) => row.share),
          backgroundColor: colors.map((color) => color),
          borderColor: colors.map((color) => color)
        }
      ]
    };

    return <DoughnutChart data={chartData} config={customConfig} />;
  }
  return <CardLoader />;
}
