import { useApi } from '@/api';
import { KpiCard, Page } from '@/components';
import CurrencyValue from '@/components/CurrencyValue/CurrencyValue';
import { TimeFilter, TimeOptions } from '@/components/global';
import { useSearchParams } from '@/hooks';
import { Box, Flex, formatPercentage } from '@blockanalitica/ui';
import FeaturedVaults from '../FeaturedVaults';
import TopVaultsTable from '../TopVaultsTable';
import HistoricTabs from './HistoricTabs';

export default function MetaMorpho() {
  const [daysAgo] = useSearchParams('days_ago', 1);
  const [data, error] = useApi({
    url: `/vaults/overview/`,
    queryParams: {
      days_ago: daysAgo
    }
  });

  if (error) {
    throw error;
  }

  if (data) {
    return (
      <Page
        title="Morpho Vaults"
        actions={<TimeFilter defaultValue={TimeOptions['1D']} />}>
        <Flex direction={['row', 'column', 'column']} gap="space-xs">
          <KpiCard
            title="Total Deposits"
            value={
              <CurrencyValue
                value={data.total_supply}
                currency={'USD'}
                notation="standard"
              />
            }
            trendValue={
              <CurrencyValue
                value={data.total_supply - data.previous_total_supply}
                currency={'USD'}
                trend
              />
            }
            footer={
              <Flex justifyContent="flex-start" gap="space-3xs">
                <span>Overall deposits:</span>
                {formatPercentage(data.total_supply / data.overall_total_supply)}
              </Flex>
            }
            options={{
              valueSize: 'text-5',
              trendValueSize: 'text-3',
              footerSize: 'text-2'
            }}
          />
        </Flex>

        <Flex direction={['row', 'column', 'column']} gap="space-xs">
          <Box width={'100%'}>
            <HistoricTabs />
          </Box>
        </Flex>
        <FeaturedVaults />
        <TopVaultsTable daysAgo={daysAgo} />
      </Page>
    );
  }

  return <></>;
}
