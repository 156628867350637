import { useApi } from '@/api';
import { KpiCard, Page } from '@/components';
import CurrencyValue from '@/components/CurrencyValue/CurrencyValue';
import { TimeFilter, TimeOptions } from '@/components/global';
import { useSearchParams } from '@/hooks';
import { Box, Flex } from '@blockanalitica/ui';
import LiquidationsEventTable from './LiquidationsEventTable';
import LiquidationsHistoricTabs from './LiquidationsHistoricTabs';

export default function Liquidations() {
  const [dataTimeFilter] = useSearchParams('days_ago', 1);
  const [data, error] = useApi({
    url: `/liquidations/`,
    queryParams: {
      days_ago: dataTimeFilter
    }
  });

  if (error) {
    throw error;
  }

  if (data) {
    return (
      <Page
        title="Liquidations"
        actions={<TimeFilter defaultValue={TimeOptions['1D']} />}>
        <Flex direction={['row', 'column', 'column']} gap="space-m">
          <Box flexBasis={['67%', '100%', '100%']}>
            <LiquidationsHistoricTabs />
          </Box>
          <Box flexBasis={['32%', '100%', '100%']}>
            <Flex
              direction={['column', 'column', 'column']}
              gap="space-xs"
              style={{ height: '100%' }}>
              <KpiCard
                title="# of Liquidations"
                value={<CurrencyValue value={data.count} />}
                trendValue={
                  <CurrencyValue value={data.count - data.previous_count} trend />
                }
                options={{
                  valueSize: 'text-5',
                  trendValueSize: 'text-3',
                  trendValueIcon: true,
                  footerSize: 'text-2'
                }}
              />

              <KpiCard
                title="Collateral Liquidated"
                value={<CurrencyValue value={data.seized_assets} currency={'USD'} />}
                trendValue={
                  <CurrencyValue
                    value={data.seized_assets - data.previous_seized_assets}
                    trend
                  />
                }
                options={{
                  valueSize: 'text-5',
                  trendValueSize: 'text-3',
                  trendValueIcon: true,
                  footerSize: 'text-2',
                  trendValueFormat: 'currency'
                }}
              />

              <KpiCard
                title="Repaid Debt"
                value={<CurrencyValue value={data.repaid_assets} currency={'USD'} />}
                trendValue={
                  <CurrencyValue
                    value={data.repaid_assets - data.previous_repaid_assets}
                    trend
                  />
                }
                options={{
                  valueSize: 'text-5',
                  trendValueSize: 'text-3',
                  trendValueIcon: true,
                  footerSize: 'text-2',
                  trendValueFormat: 'currency'
                }}
              />
            </Flex>
          </Box>
        </Flex>
        <LiquidationsEventTable />
      </Page>
    );
  }

  return <></>;
}
