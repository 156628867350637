import { ErrorBoundary, FixedSidebarLayout as Layout } from '@/components';
import Overall from '@/pages';
import Activity from '@/pages/activity';
import Vaults from '@/pages/metamorpho/vaults';
import Vault from '@/pages/metamorpho/vaults/vaultAddress';
import Pools from '@/pages/pools';
import Pool from '@/pages/pools/poolUid';
import PoolEvents from '@/pages/pools/poolUid/events';
import PoolWallets from '@/pages/pools/poolUid/wallets';
import PoolWallet from '@/pages/pools/poolUid/wallets/walletAddress';
import Positions from '@/pages/positions';
import Tokens from '@/pages/tokens';
import Token from '@/pages/tokens/address';
import Wallets from '@/pages/wallets';
import Wallet from '@/pages/wallets/address';
import * as Sentry from '@sentry/react';
import {
  Navigate,
  createBrowserRouter,
  generatePath,
  useLocation,
  useParams
} from 'react-router-dom';
import HomepageLayout from './components/layouts/Homepage';
import Morpho from './pages/homeIndex';
import Liquidations from './pages/liquidations';
import MetaMorpho from './pages/metamorpho';
import Depositors from './pages/metamorpho/vaults/depositors';
import AllocationEvents from './pages/metamorpho/vaults/vaultAddress/allocation';
import VaultDepositors from './pages/metamorpho/vaults/vaultAddress/depositors';
import VaultDepositor from './pages/metamorpho/vaults/vaultAddress/depositors/address';
import VaultEvents from './pages/metamorpho/vaults/vaultAddress/events';
import Risk from './pages/risk';

function SimpleRedirect() {
  const params = useParams();
  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const network = pathSegments[1];

  let redirectWithParams = null;
  if (['ethereum', 'base'].includes(network)) {
    redirectWithParams = generatePath('404', params);
  } else {
    const to = `/ethereum${location.pathname}`;
    redirectWithParams = generatePath(to, params);
  }

  return <Navigate to={redirectWithParams} />;
}

function SimpleRedirectTo() {
  const location = useLocation();

  const redirectWithParams = location.pathname.replace('/metamorpho', '/vaults');

  return <Navigate to={redirectWithParams} />;
}

const ROUTES = {
  ethereum: [
    {
      path: '/',
      element: <Overall />
    },
    {
      path: '/markets',
      element: <Pools />
    },
    {
      path: '/markets/:poolUid',
      element: <Pool />
    },
    {
      path: '/markets/:poolUid/wallets',
      element: <PoolWallets />
    },
    {
      path: '/markets/:poolUid/events',
      element: <PoolEvents />
    },
    {
      path: '/markets/:poolUid/wallets/:walletAddress',
      element: <PoolWallet />
    },
    {
      path: '/metamorpho',
      element: <SimpleRedirectTo />
    },
    {
      path: '/metamorpho/vaults',
      element: <SimpleRedirectTo />
    },
    {
      path: '/metamorpho/depositors',
      element: <SimpleRedirectTo />
    },

    {
      path: '/metamorpho/vaults/:vaultAddress',
      element: <SimpleRedirectTo />
    },
    {
      path: '/metamorpho/vaults/:vaultAddress/depositors',
      element: <SimpleRedirectTo />
    },
    {
      path: '/metamorpho/vaults/:vaultAddress/depositors/:address',
      element: <SimpleRedirectTo />
    },
    {
      path: '/metamorpho/vaults/:vaultAddress/events',
      element: <SimpleRedirectTo />
    },
    {
      path: '/metamorpho/vaults/:vaultAddress/allocation',
      element: <SimpleRedirectTo />
    },
    {
      path: '/vaults',
      element: <MetaMorpho />
    },
    {
      path: '/vaults/vaults',
      element: <Vaults />
    },
    {
      path: '/vaults/depositors',
      element: <Depositors />
    },

    {
      path: '/vaults/vaults/:vaultAddress',
      element: <Vault />
    },
    {
      path: '/vaults/vaults/:vaultAddress/depositors',
      element: <VaultDepositors />
    },
    {
      path: '/vaults/vaults/:vaultAddress/depositors/:address',
      element: <VaultDepositor />
    },
    {
      path: '/vaults/vaults/:vaultAddress/events',
      element: <VaultEvents />
    },
    {
      path: '/vaults/vaults/:vaultAddress/allocation',
      element: <AllocationEvents />
    },
    {
      path: '/wallets',
      element: <Wallets />
    },
    {
      path: '/wallets/:address',
      element: <Wallet />
    },
    {
      path: '/tokens',
      element: <Tokens />
    },
    {
      path: '/tokens/:address',
      element: <Token />
    },
    {
      path: '/positions',
      element: <Positions />
    },
    {
      path: '/liquidations',
      element: <Liquidations />
    },
    {
      path: '/risk',
      element: <Risk />
    },
    {
      path: '/activity',
      element: <Activity />
    }
  ],
  base: [
    {
      path: '/',
      element: <Overall />
    },
    {
      path: '/markets',
      element: <Pools />
    },
    {
      path: '/markets/:poolUid',
      element: <Pool />
    },
    {
      path: '/markets/:poolUid/wallets',
      element: <PoolWallets />
    },
    {
      path: '/markets/:poolUid/events',
      element: <PoolEvents />
    },
    {
      path: '/markets/:poolUid/wallets/:walletAddress',
      element: <PoolWallet />
    },
    {
      path: '/metamorpho',
      element: <SimpleRedirectTo />
    },
    {
      path: '/metamorpho/vaults',
      element: <SimpleRedirectTo />
    },
    {
      path: '/metamorpho/depositors',
      element: <SimpleRedirectTo />
    },

    {
      path: '/metamorpho/vaults/:vaultAddress',
      element: <SimpleRedirectTo />
    },
    {
      path: '/metamorpho/vaults/:vaultAddress/depositors',
      element: <SimpleRedirectTo />
    },
    {
      path: '/metamorpho/vaults/:vaultAddress/depositors/:address',
      element: <SimpleRedirectTo />
    },
    {
      path: '/metamorpho/vaults/:vaultAddress/events',
      element: <SimpleRedirectTo />
    },
    {
      path: '/metamorpho/vaults/:vaultAddress/allocation',
      element: <SimpleRedirectTo />
    },
    {
      path: '/vaults',
      element: <MetaMorpho />
    },
    {
      path: '/vaults/vaults',
      element: <Vaults />
    },
    {
      path: '/vaults/depositors',
      element: <Depositors />
    },

    {
      path: '/vaults/vaults/:vaultAddress',
      element: <Vault />
    },
    {
      path: '/vaults/vaults/:vaultAddress/depositors',
      element: <VaultDepositors />
    },
    {
      path: '/vaults/vaults/:vaultAddress/depositors/:address',
      element: <VaultDepositor />
    },
    {
      path: '/vaults/vaults/:vaultAddress/events',
      element: <VaultEvents />
    },
    {
      path: '/vaults/vaults/:vaultAddress/allocation',
      element: <AllocationEvents />
    },
    {
      path: '/wallets',
      element: <Wallets />
    },
    {
      path: '/wallets/:address',
      element: <Wallet />
    },
    {
      path: '/tokens',
      element: <Tokens />
    },
    {
      path: '/tokens/:address',
      element: <Token />
    },
    {
      path: '/positions',
      element: <Positions />
    },
    {
      path: '/liquidations',
      element: <Liquidations />
    },
    {
      path: '/risk',
      element: <Risk />
    },
    {
      path: '/activity',
      element: <Activity />
    }
  ]
};

function getRoutes() {
  const updatedRoutes = [];

  Object.keys(ROUTES).forEach((key) => {
    ROUTES[key].forEach((route) => {
      updatedRoutes.push({
        ...route,
        path: `/${key}${route.path}`
      });
    });
  });
  return updatedRoutes;
}

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    element: <Layout />,
    errorElement: (
      <Layout>
        <ErrorBoundary />
      </Layout>
    ),
    children: getRoutes()
  },
  {
    element: <HomepageLayout />,
    errorElement: (
      <HomepageLayout>
        <ErrorBoundary />
      </HomepageLayout>
    ),
    children: [
      {
        path: '/',
        element: <Morpho />
      }
    ]
  },
  {
    element: <SimpleRedirect />,
    children: ROUTES['ethereum']
  }
]);

export default router;
