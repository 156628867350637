import { useApi } from '@/api';
import { CardList, Link } from '@/components';
import CurrencyValue from '@/components/CurrencyValue/CurrencyValue';
import WalletAddress from '@/components/WalletAddress/WalletAddress';
import { EmptyBox } from '@/components/empty';
import { LinkButton } from '@/components/forms';
import VaultName from '@/components/vault/VaultName';
import { CardLoader, Flex, List, formatPercentage } from '@blockanalitica/ui';

function displaySupplier(name, asset_symbol, symbol, walletAddress) {
  if (name) {
    return <VaultName assetSymbol={asset_symbol} symbol={symbol} name={name} />;
  } else {
    return <WalletAddress options={{ size: 'xSmall' }} address={walletAddress} />;
  }
}

export default function SuppliersList({ poolUid, totalSupply }) {
  const [data, error] = useApi({
    url: `/markets/${poolUid}/wallets/`,
    queryParams: {
      p_size: 4,
      type: 'suppliers',
      order: '-supply'
    }
  });

  if (error) {
    throw error;
  }

  if (data) {
    let content = undefined;
    if (data['results'].length > 0) {
      content = (
        <List>
          {data['results'].map((item) => (
            <Link
              key={item.wallet_address}
              to={
                item.name
                  ? `/vaults/vaults/${item.wallet_address}`
                  : `wallets/${item.wallet_address}`
              }>
              <Flex justifyContent="space-between">
                <Flex justifyContent="space-between" style={{ flexBasis: '66%' }}>
                  {displaySupplier(
                    item.name,
                    item.asset_symbol,
                    item.symbol,
                    item.wallet_address
                  )}
                  <Flex direction="column" gap="space-3xs" alignItems="flex-end">
                    <span className="text-2">
                      {formatPercentage(item.supply / totalSupply)}
                    </span>
                    <span className="text-2 text-muted">share</span>
                  </Flex>
                </Flex>
                <Flex direction="column" gap="space-3xs" alignItems="flex-end">
                  <CurrencyValue
                    value={item.supply}
                    currency={item.loan_token_symbol}
                    iconSize="small"
                  />
                  <span className="text-2 text-muted">
                    <CurrencyValue value={item.supply_usd} currency={'USD'} />
                  </span>
                </Flex>
              </Flex>
            </Link>
          ))}
        </List>
      );
    } else {
      content = <EmptyBox />;
    }

    return (
      <CardList
        style={{ width: '100%' }}
        title="Suppliers"
        actions={
          <LinkButton
            to={{
              pathname: 'wallets',
              search: `?tab=Suppliers`
            }}>
            view all
          </LinkButton>
        }>
        {content}
      </CardList>
    );
  }

  return <CardLoader headerHeight="3rem" bodyHeight="7rem" />;
}
