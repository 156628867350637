import SearchInput from '@/components/forms/SearchInput';
import { useSearchParams } from '@/hooks';
import { Flex } from '@blockanalitica/ui';
import { useState } from 'react';
import styles from './Search.module.scss';
import SearchResults from './SearchResults';

export default function Search() {
  const [searchText, setSearchText] = useSearchParams('search', '');
  const [isOpen, setIsOpen] = useState(false);

  const onValueChange = (value) => {
    setSearchText(value);
  };

  return (
    <>
      <Flex className={styles.container}>
        <SearchInput
          placeholder="Search by market, token or vault"
          defaultValue={searchText}
          onValueChange={(value) => onValueChange(value)}
          onFocus={() => setIsOpen(true)}
        />
        {isOpen ? <SearchResults searchTerm={searchText} /> : null}
      </Flex>
      {isOpen ? (
        <div onClick={() => setIsOpen(false)} className={styles.alphaBackgroundLayer} />
      ) : null}
    </>
  );
}
