import classnames from 'classnames';
import { useCallback } from 'react';
import { NavLink as RouterNavLink, useLocation } from 'react-router-dom';
import styles from './NavLink.module.scss';

type NavLinkProps = {
  isRoot?: boolean;
  isSingleLink?: boolean;
  text: string;
  href: string;
  className?: string;
  forwardSearch?: boolean;
  daysAgo?: boolean;
};

export default function NavLink({
  isRoot = false,
  isSingleLink = false,
  text,
  href,
  className,
  forwardSearch = false,
  daysAgo = true
}: NavLinkProps) {
  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const network = pathSegments[1];

  const constructToPath = useCallback(() => {
    let toPath = typeof href === 'object' ? href.pathname : href;
    toPath = `/${network}${toPath}`;
    if (daysAgo) {
      const toSearchParams = new URLSearchParams();
      const currentSearchParams = new URLSearchParams(location.search);

      const daysAgo = currentSearchParams.get('days_ago');
      if (daysAgo) {
        toSearchParams.set('days_ago', daysAgo);
      }

      const searchParamsString = toSearchParams.toString()
        ? `?${toSearchParams.toString()}`
        : '';
      return typeof href === 'object'
        ? { ...href, search: searchParamsString }
        : `${toPath}${searchParamsString}`;
    }
    if (forwardSearch) {
      const hasQueryParams = toPath?.includes('?');

      const searchParams = hasQueryParams
        ? location.search.replace('?', '&')
        : location.search;

      return typeof href === 'object'
        ? { ...href, search: searchParams }
        : `${toPath}${searchParams}`;
    }

    return href;
  }, [daysAgo, forwardSearch, location.search, href, network]);

  return (
    <RouterNavLink
      className={({ isActive }) => {
        return classnames(
          'text-2',
          styles.link,
          {
            'text-3': isRoot,
            [styles.active]: (isActive && isSingleLink) || (isActive && !isRoot),
            [styles.header]: isRoot
          },
          className
        );
      }}
      key={text}
      to={constructToPath()}>
      {text}
    </RouterNavLink>
  );
}
