import { useApi } from '@/api';
import PoolName from '@/components/PoolName/PoolName';
import RelativeDateTime from '@/components/RelativeDateTime/RelativeDateTime';
import SelectFilter from '@/components/filters/SelectFilter';
import TableLoader from '@/components/loaders/TableLoader';
import Table from '@/components/table/Table';
import { ConfigEventName, ConfigEventData } from '@/components/events';
import { CONFIG_EVENTS, CONFIG_EVENT_TYPE_MAPPING } from '@/constants';
import { EtherScanPictogram, Flex, useTableProps } from '@blockanalitica/ui';
import { useMemo } from 'react';

export default function ConfigEventsTable({ vaultAddress, daysAgo }) {
  const selectFilterOptions = useMemo(() => {
    let options = [{ label: 'All events', value: '' }];
    options.push(
      ...CONFIG_EVENTS.map((event) => ({
        label: CONFIG_EVENT_TYPE_MAPPING[event],
        value: event
      }))
    );
    return options;
  }, []);
  const { sorting, filters, pagination } = useTableProps({
    pageSize: 5,
    initialSorting: '-order_index',
    filtersDebounceTime: 50
  });

  const [data, error] = useApi({
    url: `/vaults/${vaultAddress}/config/`,
    queryParams: {
      p: pagination.currentPage,
      p_size: pagination.pageSize,
      days_ago: daysAgo,
      order: sorting.currentSorting,
      event: filters.filtersApplied.eventType
    }
  });

  if (error) {
    throw error;
  }

  let content;

  if (data) {
    const columns = [
      {
        header: 'MARKET',
        getCell: (row) => (
          <>
            {row.loan_token_symbol ? (
              <>
                <PoolName
                  loanSymbol={row.loan_token_symbol}
                  collateralSymbol={row.collateral_token_symbol}
                  lltv={row.lltv}
                />
              </>
            ) : (
              '-'
            )}
          </>
        )
      },
      {
        header: 'EVENT',
        getCell: (row) => <ConfigEventName event={row.event} />
      },

      {
        header: 'DETAILS',
        getCell: (row) => <ConfigEventData data={row} />,
        cellSize: '3fr'
      },
      {
        header: 'TIME',
        getCell: (row) => <RelativeDateTime value={row.datetime} />,
        getCellBottom: (row) => (
          <Flex gap="space-3xs" className="text-muted">
            {row.block_number}
            <a
              href={`https://etherscan.io/tx/${row.tx_hash}`}
              target="_blank"
              rel="noreferrer">
              <EtherScanPictogram size={14} />
            </a>
          </Flex>
        ),
        sortingField: 'order_index',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      }
    ];
    content = (
      <Table
        rows={data['results']}
        columns={columns}
        getKey={(row) => row.order_index}
        sorting={sorting}
        pagination={{
          ...pagination,
          totalRecords: data.count
        }}
      />
    );
  } else {
    content = <TableLoader />;
  }

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center">
        <h4>Vault Configuration Events</h4>
        <SelectFilter
          key="event-filter"
          placeholder="Showing all events"
          filterField="eventType"
          options={selectFilterOptions}
          onPageChange={pagination.onPageChange}
          {...filters}
        />
      </Flex>
      {content}
    </>
  );
}
