import { Flex, useThemeCustomProperties, HeatmapActivity } from '@blockanalitica/ui';
import { useMemo } from 'react';

export default function Heatmap({ data, monthsAgo }) {
  const colors = useThemeCustomProperties(['color-primary']);
  const total = useMemo(() => data.reduce((sum, row) => sum + row.value, 0), [data]);

  const dateText = useMemo(() => {
    if (monthsAgo === 12) {
      return 'year';
    }
    return `${monthsAgo} months`;
  }, [monthsAgo]);

  return (
    <Flex direction="column" alignItems="flex-end" gap="space-3xs">
      <HeatmapActivity
        data={data}
        options={{
          monthsAgo: monthsAgo,
          heatColor: colors.colorPrimary,
          cellStyle: { outline: '1px solid var(--color-onSurface200)' }
        }}
      />
      <span className="text-2 text-muted">
        {total} events in the last {dateText}
      </span>
    </Flex>
  );
}
