import { Page } from '@/components';
import { TimeFilter, TimeOptions } from '@/components/global';
import { useSearchParams } from '@/hooks';
import DepositorsTable from './DepositorsTable';
export default function Depositors() {
  const [daysAgo] = useSearchParams('days_ago', 1);
  return (
    <Page title="Depositors" actions={<TimeFilter defaultValue={TimeOptions['1D']} />}>
      <DepositorsTable daysAgo={daysAgo} />
    </Page>
  );
}
