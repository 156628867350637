import { formatCurreny } from '@/utils';
import { BarChart as BarChartComponent, formatDatetime } from '@blockanalitica/ui';
import { useMemo } from 'react';

export default function BarChart({
  data,
  fields,
  valueFormatter,
  aspectRatio = 4,
  xScaleValueFormatter = undefined,
  yScaleValueFormatter = undefined
}) {
  const chartData = useMemo(() => {
    return {
      datasets: fields.map((fieldInfo) => ({
        label: fieldInfo.label,
        data: data.map((item) => ({
          x: item.date,
          y: item[fieldInfo.field]
        })),
        backgroundColor: fieldInfo.backgroundColor
      }))
    };
  }, [data, fields]);

  const kpisConfig = useMemo(() => {
    const defaultData = chartData.datasets.map((ds) => ({
      y: ds.data[ds.data.length - 1].y,
      x: ds.data[ds.data.length - 1].x,
      label: ds.label
    }));

    return {
      valueFormatter: valueFormatter,
      subValueFormatter: (value) => (
        <span className="text-muted">{formatDatetime(value)}</span>
      ),
      defaultData: defaultData
    };
  }, [chartData.datasets, valueFormatter]);

  const config = useMemo(
    () => ({
      options: {
        aspectRatio: aspectRatio,
        onResize: (chart) => {
          const ratio = chart.config.options.aspectRatio;
          if (window.innerWidth < 576 && ratio !== 1.5) {
            chart.config.options.oldAspectRatio = ratio;
            chart.config.options.aspectRatio = 1.5;
          } else if (
            window.innerWidth >= 576 &&
            chart.config.options.oldAspectRatio > 0 &&
            ratio !== chart.config.options.oldAspectRatio
          ) {
            chart.config.options.aspectRatio = chart.config.options.oldAspectRatio;
          }
        },
        scales: {
          x: {
            stacked: true,
            type: 'time',
            time: {
              unit: 'day'
            },
            ticks: {
              callback: xScaleValueFormatter
                ? xScaleValueFormatter
                : (value) => formatDatetime(value, { format: 'MMM d' })
            }
          },
          y: {
            type: 'linear',
            beginAtZero: true,
            stacked: true,
            ticks: {
              format: { notation: 'compact' },
              callback: yScaleValueFormatter
                ? yScaleValueFormatter
                : (value) => formatCurreny(value)
            }
          }
        }
      }
    }),
    [aspectRatio, xScaleValueFormatter, yScaleValueFormatter]
  );

  return <BarChartComponent data={chartData} kpisConfig={kpisConfig} config={config} />;
}
