import {
  Flex,
  LineChart as LineChartComponent,
  chartsRgbColorPallete,
  formatPercentage
} from '@blockanalitica/ui';
import { EmptyBox } from '@/components/empty';
import { formatCurreny } from '@/utils';
import { useMemo } from 'react';

export default function MultiLineChart({
  data,
  valueFormatter,
  fields = { x: 'price_change', y: 'total_amount', label: 'symbol' },
  aspectRatio = 4,
  xScaleValueFormatter = undefined,
  yScaleValueFormatter = undefined,
  forceKpis = false,
  xAxisNegative = false,
  defaultLineColor = false,
  ...rest
}) {
  const colors = chartsRgbColorPallete(1);
  const datasets = useMemo(() => {
    // Group data by labels
    const groupedByLabel = data.reduce((acc, item) => {
      const label = item[fields.label];
      if (!acc[label]) {
        acc[label] = [];
      }
      acc[label].push({
        x: xAxisNegative ? -item[fields.x] : item[fields.x],
        y: item[fields.y],
        label: item[fields.label]
      });
      return acc;
    }, {});

    // Map each group to a dataset
    const result = Object.entries(groupedByLabel).map(([label, data], index) => ({
      label: label,
      data: data,
      borderColor: defaultLineColor ? '#5792FF' : colors[index % colors.length],
      borderWidth: 4,
      backgroundColor: function (context) {
        // Check if defaultLineColor is true, if so, return a single color
        if (defaultLineColor) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            // This case happens on initial chart load
            return null;
          }
          const gradient = ctx.createLinearGradient(
            0,
            chartArea.bottom,
            0,
            chartArea.top
          );
          gradient.addColorStop(0, 'rgba(0, 0, 0, 0)');
          gradient.addColorStop(1, 'rgba(87, 146, 255, .4)');

          return gradient;
        }
        return colors[index % colors.length];
      }
    }));

    if (forceKpis) {
      const emptyDataset = {
        label: 'none',
        data: [],
        borderColor: '#000',
        borderWidth: 1,
        backgroundColor: '#000'
      };

      return [...result, emptyDataset];
    }

    return result;
  }, [data, fields, colors, forceKpis, xAxisNegative, defaultLineColor]);

  const kpisConfig = useMemo(
    () => ({
      valueFormatter: valueFormatter,
      subValueFormatter: (value) => (
        <span className="text-muted">{formatPercentage(Number(value))}</span>
      ),
      defaultData: datasets
        .filter((set) => set.label !== 'none') // Filter out datasets with label 'none'
        .map((set) => set.data[set.data.length - 1]) // Then map the remaining datasets
    }),
    [datasets, valueFormatter]
  );

  const config = useMemo(
    () => ({
      options: {
        fill: true,
        aspectRatio: aspectRatio,
        onResize: (chart) => {
          const ratio = chart.config.options.aspectRatio;
          if (window.innerWidth < 576 && ratio !== 1.5) {
            chart.config.options.oldAspectRatio = ratio;
            chart.config.options.aspectRatio = 1.5;
          } else if (
            window.innerWidth >= 576 &&
            chart.config.options.oldAspectRatio > 0 &&
            ratio !== chart.config.options.oldAspectRatio
          ) {
            chart.config.options.aspectRatio = chart.config.options.oldAspectRatio;
          }
        },
        plugins: {
          legend: {
            display: false
          }
        },
        scales: {
          x: {
            stacked: true,
            reverse: xAxisNegative,
            type: 'linear',
            ticks: {
              callback: xScaleValueFormatter
                ? xScaleValueFormatter
                : (value) => formatPercentage(Number(value))
            }
          },
          y: {
            stacked: true,
            ticks: {
              format: { notation: 'compact' },
              callback: yScaleValueFormatter
                ? yScaleValueFormatter
                : (value) => formatCurreny(value)
            }
          }
        }
      }
    }),
    [aspectRatio, xScaleValueFormatter, yScaleValueFormatter, xAxisNegative]
  );

  if (data.length > 0) {
    return (
      <Flex direction="column" {...rest}>
        <LineChartComponent
          data={{ datasets }}
          kpisConfig={kpisConfig}
          config={config}
        />
      </Flex>
    );
  }

  // Default state until data is ready
  return <EmptyBox />;
}
