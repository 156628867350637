import { useApi } from '@/api';
import { Page } from '@/components';
// import CurrencyValue from '@/components/CurrencyValue/CurrencyValue';
import { Link } from '@/components';
import SearchInput from '@/components/forms/SearchInput';
import { TimeFilter, TimeOptions } from '@/components/global';
import TableLoader from '@/components/loaders/TableLoader';
import Table from '@/components/table/Table';
import { TokenName } from '@/components/token';
import { useSearchParams } from '@/hooks';
import {
  CurrencyFilter,
  CurrencyValue,
  Flex,
  PercentageValue,
  useTableProps
} from '@blockanalitica/ui';
import { useMemo } from 'react';

export default function Tokens() {
  const [daysAgo] = useSearchParams('days_ago', 1);
  const [searchText, setSearchText] = useSearchParams('search', '');

  const { sorting, filters, pagination } = useTableProps({
    pageSize: 10,
    initialSorting: '-tvl',
    initialFilters: { currency: 'USD' }
  });
  const [data, error] = useApi({
    url: '/tokens/',
    queryParams: {
      p: pagination.currentPage,
      p_size: pagination.pageSize,
      days_ago: daysAgo,
      order: sorting.currentSorting,
      search: searchText
    }
  });

  if (error) {
    throw error;
  }

  const columns = useMemo(() => {
    return [
      {
        header: 'TOKEN',
        getCell: (row) => <TokenName symbol={row.symbol} name={row.name} />
      },
      {
        header: 'PRICE',
        getCell: (row) => (
          <CurrencyValue
            value={row.price}
            options={{ currency: 'USD', notation: 'standard' }}
          />
        ),
        getCellBottom: (row) => (
          <PercentageValue
            value={Number(row.price_change_percentage)}
            options={{ trend: true, hideIfZero: true }}
          />
        ),
        sortingField: 'price',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      },
      {
        header: 'TVL',
        getCell: (row) => (
          <CurrencyValue value={row.tvl} options={{ currency: 'USD' }} />
        ),
        getCellBottom: (row) => (
          <PercentageValue
            value={Number(row.tvl_change_percentage)}
            options={{ trend: true, hideIfZero: true }}
          />
        ),
        sortingField: 'tvl',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      },
      {
        header: 'DEPOSITS',
        getCell: (row) => (
          <>
            {filters.filtersApplied.currency === 'USD' ? (
              <CurrencyValue
                value={row.deposits_amount_usd}
                options={{ currency: 'USD', notation: 'standard' }}
              />
            ) : (
              <CurrencyValue
                value={row.deposits_amount}
                options={{
                  currency: row.symbol,
                  iconSize: 'small',
                  leadingCurrencySymbol: false
                }}
              />
            )}
          </>
        ),
        getCellBottom: (row) => (
          <>
            {filters.filtersApplied.currency === 'USD' ? (
              <PercentageValue
                value={Number(row.deposits_amount_usd_change_percentage)}
                options={{ trend: true, hideIfZero: true }}
              />
            ) : (
              <PercentageValue
                value={Number(row.deposits_amount_change_percentage)}
                options={{ trend: true, hideIfZero: true }}
              />
            )}
          </>
        ),
        sortingField: 'deposits_amount_usd',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      },
      {
        header: 'BORROW',
        getCell: (row) => (
          <>
            {filters.filtersApplied.currency === 'USD' ? (
              <CurrencyValue
                value={row.borrow_amount_usd}
                options={{ currency: 'USD', notation: 'standard' }}
              />
            ) : (
              <CurrencyValue
                value={row.borrow_amount}
                options={{
                  currency: row.symbol,
                  iconSize: 'small',
                  leadingCurrencySymbol: false
                }}
              />
            )}
          </>
        ),
        getCellBottom: (row) => (
          <>
            {filters.filtersApplied.currency === 'USD' ? (
              <PercentageValue
                value={Number(row.borrow_amount_usd_change_percentage)}
                options={{ trend: true, hideIfZero: true }}
              />
            ) : (
              <PercentageValue
                value={Number(row.borrow_amount_change_percentage)}
                options={{ trend: true, hideIfZero: true }}
              />
            )}
          </>
        ),
        sortingField: 'borrow_amount_usd',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      },
      {
        header: '# of MARKETS',
        getCell: (row) => row.markets_count,
        getCellBottom: (row) => (
          <CurrencyValue
            value={row.markets_count - row.prev_markets_count}
            options={{ trend: true }}
          />
        ),
        sortingField: 'markets_count',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      }
    ];
  }, [filters]);

  const content = useMemo(() => {
    if (data) {
      return (
        <Table
          rows={data['results']}
          columns={columns}
          getKey={(row) => row.address}
          getLinkWrapper={(row) => <Link to={`${row.address}`} />}
          sorting={sorting}
          pagination={{
            ...pagination,
            totalRecords: data.count
          }}
        />
      );
    }
    return <TableLoader />;
  }, [data, columns, sorting, pagination]);

  return (
    <Page title="Tokens" actions={<TimeFilter defaultValue={TimeOptions['1D']} />}>
      <Flex gap="space-xs" justifyContent={['flex-end', 'flex-end', 'center']}>
        <SearchInput
          placeholder="Search by symbol or name"
          defaultValue={searchText}
          onValueChange={(value) => setSearchText(value)}
        />
        <CurrencyFilter
          key="currency-filter"
          width="8rem"
          currency2={{ value: 'token', displayValue: 'TOKEN' }}
          currency1={{ value: 'USD', displayValue: 'USD' }}
          {...filters}
        />
      </Flex>
      {content}
    </Page>
  );
}
