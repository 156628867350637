import { Link } from '@/components';
import { ArrowRightIcon, Flex, textShortener } from '@blockanalitica/ui';
import { isValidElement } from 'react';

type TCrumb = {
  href: string;
  text: string | React.ReactNode;
};

type BreadcrumbsProps = {
  crumbs: TCrumb[];
};

export default function Breadcrumbs({ crumbs }: BreadcrumbsProps) {
  return (
    <Flex gap="space-2xs" display="inline-flex" alignItems="center">
      {crumbs.map((crumb, index) => (
        <Crumb
          key={crumb.href}
          last={crumbs.length === 1 || index === crumbs.length - 1}
          {...crumb}
        />
      ))}
    </Flex>
  );
}

type TCrumbProps = TCrumb & {
  last: boolean;
};

function Crumb({ href, text, last }: TCrumbProps) {
  let crumbText = null;
  if (isValidElement(text)) {
    crumbText = text;
  } else {
    crumbText = textShortener(text, {
      threshold: 63,
      firstSliceLength: 4,
      secondSliceLength: 4
    });

    if (text.startsWith('0x')) {
      crumbText = textShortener(text, {
        threshold: 11,
        firstSliceLength: 6,
        secondSliceLength: 4
      });
    }
  }

  if (last) {
    return <p className="text-2">{crumbText}</p>;
  }

  return (
    <Flex display="inline-flex" gap="space-2xs" alignItems="center">
      <Link to={href} className="text-2">
        {crumbText}
      </Link>
      <ArrowRightIcon size={12} />
    </Flex>
  );
}
