import PoolName from '@/components/PoolName/PoolName';
import RelativeDateTime from '@/components/RelativeDateTime/RelativeDateTime';
import { EventName } from '@/components/events';
import Table from '@/components/table/Table';
import { EtherScanPictogram, Flex } from '@blockanalitica/ui';
import { useMemo } from 'react';
import CurrencyValue from '@/components/CurrencyValue/CurrencyValue';

export default function AllocationEventsTable({ tableProps }) {
  const { data, sorting, pagination } = tableProps;

  const columns = useMemo(() => {
    return [
      {
        header: 'EVENT',
        getCell: (row) => (
          <PoolName
            loanSymbol={row.loan_token_symbol}
            collateralSymbol={row.collateral_token_symbol}
            lltv={row.lltv}
            iconSize={25}
          />
        )
      },
      {
        header: 'EVENT',
        getCell: (row) => <EventName event={row.event} />
      },
      {
        header: 'AMOUNT',
        getCell: (row) => (
          <CurrencyValue
            value={row.assets}
            currency={row.token_symbol}
            iconSize="small"
            trendNoIcon={true}
          />
        ),
        getCellBottom: (row) => (
          <span className="text-muted">
            <CurrencyValue value={row.assets_usd} currency={'USD'} />
          </span>
        ),
        sortingField: 'assets',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      },
      {
        header: 'TIME',
        getCell: (row) => <RelativeDateTime value={row.datetime} />,
        getCellBottom: (row) => (
          <Flex gap="space-3xs" className="text-muted">
            {row.block_number}
            <a
              href={`https://etherscan.io/tx/${row.tx_hash}`}
              target="_blank"
              rel="noreferrer">
              <EtherScanPictogram size={14} />
            </a>
          </Flex>
        ),
        sortingField: 'order_index',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      }
    ];
  }, []);

  return (
    <Table
      rows={data['results']}
      columns={columns}
      getKey={(row) => row.order_index}
      sorting={sorting}
      pagination={{
        ...pagination,
        totalRecords: data.count
      }}
    />
  );
}
