import BlankNotepadIcon from '@/components/icons/BlankNotepadIcon';
import { Flex } from '@blockanalitica/ui';
import styles from './EmptyBox.module.scss';

export default function EmptyBox({ text = 'NO DATA', icon = null }) {
  if (!icon) {
    icon = <BlankNotepadIcon />;
  }
  return (
    <Flex
      direction="column"
      className={styles.container}
      alignItems="center"
      justifyContent="center">
      {icon ? icon : null}
      <p className={styles.emptyText}>{text}</p>
    </Flex>
  );
}
