import { ChevronDownIcon, Stack } from '@blockanalitica/ui';

import { useLocation, useNavigate } from 'react-router-dom';
import styles from './NetworkSwitch.module.scss';
export type SelectOption = React.OptionHTMLAttributes<HTMLOptionElement> & {
  value: string;
  label: string;
};

export interface SelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  placeholder?: string;
  options: SelectOption[];
}

export default function NetworkSwitch() {
  const navigate = useNavigate();
  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const network = pathSegments[1];
  const onValueChange = (event) => {
    const selectedValue = event.target.value;
    navigate(`/${selectedValue}`);
  };
  const options = [
    { label: 'Ethereum', value: 'ethereum', icon: 'ETH' },
    { label: 'Base', value: 'base', icon: 'BASE' }
  ];
  return (
    <Stack direction="row" alignItems="flex-end" style={{ position: 'relative' }}>
      <select
        className={styles.selectBox}
        onChange={onValueChange}
        defaultValue={network}>
        {options.map((option, index) => (
          <option {...option} key={index}>
            {option.label}
          </option>
        ))}
      </select>

      <ChevronDownIcon className={styles.arrow} size={12} />
    </Stack>
  );
}
