import { useMemo } from 'react';
import { useApi } from '@/api';
import PoolName from '@/components/PoolName/PoolName';
import RelativeDateTime from '@/components/RelativeDateTime/RelativeDateTime';
import Table from '@/components/table/Table';
import { ConfigEventName, ConfigEventData } from '@/components/events';
import {
  EtherScanPictogram,
  Flex,
  useTableProps,
  formatDatetime,
  Badge
} from '@blockanalitica/ui';
import { DateTime } from 'luxon';

export default function PendingSubmitEventsTable({ vaultAddress, daysAgo }) {
  const { sorting, pagination } = useTableProps({
    pageSize: 5,
    initialSorting: '-order_index'
  });

  const [data, error] = useApi({
    url: `/vaults/${vaultAddress}/submit/`,
    queryParams: {
      p: pagination.currentPage,
      p_size: pagination.pageSize,
      days_ago: daysAgo,
      order: sorting.currentSorting
    }
  });

  if (error) {
    throw error;
  }

  const tableData = useMemo(() => {
    if (!data || data.count === 0) {
      return null;
    }
    const columns = [
      {
        header: 'MARKET',
        getCell: (row) => (
          <>
            {row.loan_token_symbol ? (
              <PoolName
                loanSymbol={row.loan_token_symbol}
                collateralSymbol={row.collateral_token_symbol}
                lltv={row.lltv}
              />
            ) : (
              '-'
            )}
          </>
        )
      },
      {
        header: 'EVENT',
        getCell: (row) => <ConfigEventName event={row.event} />
      },
      {
        header: 'DETAILS',
        getCell: (row) => <ConfigEventData data={row} />,
        cellSize: '3fr'
      },
      {
        header: 'TIME SUBMITTED',
        getCell: (row) => <RelativeDateTime value={row.datetime} />,
        getCellBottom: (row) => (
          <Flex gap="space-3xs" className="text-muted">
            {row.block_number}
            <a
              href={`https://etherscan.io/tx/${row.tx_hash}`}
              target="_blank"
              rel="noreferrer">
              <EtherScanPictogram size={14} />
            </a>
          </Flex>
        ),
        sortingField: 'order_index',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      },
      {
        header: 'EXECUTION STATUS',
        getCell: (row) => formatDatetime(row.execution_datetime),
        getCellBottom: (row) => {
          const executionDateTime = DateTime.fromISO(row.execution_datetime, {
            zone: 'utc'
          });
          const dateTime = DateTime.fromISO(row.current_datetime, { zone: 'utc' });
          const hoursUntilExecution = executionDateTime
            .diff(dateTime, 'hours')
            .hours.toFixed(0);

          if (parseInt(hoursUntilExecution, 10) <= 0) {
            return <Badge variant="success" text="Executable" padding="space-3xs" />;
          } else {
            return (
              <Badge
                variant="warning"
                text={`Pending ${hoursUntilExecution} h `}
                padding="space-3xs"
              />
            );
          }
        },
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      }
    ];

    return { columns, rows: data.results, totalRecords: data.count };
  }, [data]);

  if (!tableData) {
    return null;
  }

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center">
        <h4>Pending Submit Events</h4>
      </Flex>
      <Table
        rows={tableData.rows}
        columns={tableData.columns}
        getKey={(row) => row.order_index}
        sorting={sorting}
        pagination={{
          ...pagination,
          totalRecords: tableData.totalRecords
        }}
      />
    </>
  );
}
