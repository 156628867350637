import { useApi } from '@/api';
import { Card } from '@/components';
import CurrencyValue from '@/components/CurrencyValue/CurrencyValue';
import DisplaySwitch from '@/components/DisplaySwitch/DisplaySwitch';
import { LineChart, TimeSelector } from '@/components/charts';
import { formatCurreny } from '@/utils';
import { CardLoader, Flex, formatNumber } from '@blockanalitica/ui';
import { useMemo, useState } from 'react';

export default function HistoricTabs({
  poolUid,
  walletAddress,
  loanSymbol,
  collateralSymbol
}) {
  const [daysAgo, setDaysAgo] = useState(30);
  const [activeDisplayOption, setActiveDisplayOption] = useState('collateral');
  const [data, error] = useApi({
    url: `/markets/${poolUid}/wallets/${walletAddress}/historic/`,
    queryParams: {
      days_ago: daysAgo
    }
  });

  if (error) {
    throw error;
  }

  const displayOptions = useMemo(
    () => [
      { key: 'collateral', value: 'Collateral' },
      { key: 'supply', value: 'Supply' },
      { key: 'borrow', value: 'Borrow' }
    ],
    []
  );

  if (data) {
    let valueFormatter = undefined;
    let yScaleValueFormatter = undefined;
    switch (activeDisplayOption) {
      case 'collateral':
        valueFormatter = (value) => (
          <CurrencyValue value={value} currency={collateralSymbol} iconSize={30} />
        );
        yScaleValueFormatter = (value) => formatCurreny(value, collateralSymbol);
        break;
      case 'supply':
        valueFormatter = (value) => (
          <CurrencyValue value={value} currency={loanSymbol} iconSize={30} />
        );
        yScaleValueFormatter = (value) => formatCurreny(value, loanSymbol);
        break;
      case 'borrow':
        valueFormatter = (value) => (
          <CurrencyValue value={value} currency={loanSymbol} iconSize={30} />
        );
        yScaleValueFormatter = (value) => formatCurreny(value, loanSymbol);
        break;
      default:
        valueFormatter = (value) => formatNumber(value, null);
        yScaleValueFormatter = (value) => formatNumber(value);
    }

    return (
      <Card>
        <Flex direction="column" gap="space-xs">
          <Flex
            direction={['row', 'row', 'column']}
            alignItems={['flex-start', 'flex-start', 'center']}
            justifyContent="space-between"
            gap="space-xs">
            <DisplaySwitch
              options={displayOptions}
              activeOption={activeDisplayOption}
              onChange={setActiveDisplayOption}
            />

            <TimeSelector
              activeOption={daysAgo}
              onChange={(e) => setDaysAgo(e.target.value)}
            />
          </Flex>
          <LineChart
            key={activeDisplayOption}
            data={data}
            field={activeDisplayOption}
            valueFormatter={valueFormatter}
            yScaleValueFormatter={yScaleValueFormatter}
            daysAgo={daysAgo}
          />
        </Flex>
      </Card>
    );
  }

  return <CardLoader headerHeight="3rem" bodyHeight="7rem" />;
}
