import { Link } from '@/components';
import CurrencyValue from '@/components/CurrencyValue/CurrencyValue';
import WalletAddress from '@/components/WalletAddress/WalletAddress';
import { Flex, formatPercentage, textShortener } from '@blockanalitica/ui';

const EventValue = ({ title, children }) => {
  return (
    <Flex direction="column" style={{ textAlign: 'left' }} gap="space-3xs">
      <div className="text-muted">{title}</div>
      {children}
    </Flex>
  );
};

export default function ConfigEventData({ data }) {
  const { event, args, asset_symbol: assetSymbol } = data;
  let content = JSON.stringify(args, null, ' ');
  switch (event) {
    case 'OwnershipTransferred':
      content = (
        <Flex gap="space-s">
          <EventValue title="Previous Owner">
            <WalletAddress
              options={{
                size: 'xSmall',
                inline: true,
                showCopyBtn: true
              }}
              address={args.previousOwner}
            />
          </EventValue>
          <EventValue title="New Owner">
            <WalletAddress
              options={{
                size: 'xSmall',
                inline: true,
                showCopyBtn: true
              }}
              address={args.newOwner}
            />
          </EventValue>
        </Flex>
      );
      break;
    case 'ReallocateSupply':
      content = (
        <Flex gap="space-s">
          <EventValue title="Caller">
            <WalletAddress
              options={{
                size: 'xSmall',
                inline: true,
                showCopyBtn: true
              }}
              address={args.caller}
            />
          </EventValue>
          <EventValue title="Supplied Assets">
            <CurrencyValue
              value={args.suppliedAssets}
              currency={assetSymbol}
              iconSize="small"
            />
          </EventValue>
        </Flex>
      );
      break;
    case 'ReallocateWithdraw':
      content = (
        <Flex gap="space-s">
          <EventValue title="Caller">
            <WalletAddress
              options={{
                size: 'xSmall',
                inline: true,
                showCopyBtn: true
              }}
              address={args.caller}
            />
          </EventValue>
          <EventValue title="Withdrawn Assets">
            <CurrencyValue
              value={args.withdrawnAssets}
              currency={assetSymbol}
              iconSize="small"
            />
          </EventValue>
        </Flex>
      );
      break;
    case 'RevokePendingCap':
      // TODO
      break;
    case 'RevokePendingGuardian':
      // TODO
      break;
    case 'RevokePendingMarketRemoval':
      // TODO
      break;
    case 'RevokePendingTimelock':
      content = (
        <EventValue title="Caller">
          <WalletAddress
            options={{
              size: 'xSmall',
              inline: true,
              showCopyBtn: true
            }}
            address={args.caller}
          />
        </EventValue>
      );
      break;
    case 'SetCap':
      content = (
        <Flex gap="space-s">
          <EventValue title="Caller">
            <WalletAddress
              options={{
                size: 'xSmall',
                inline: true,
                showCopyBtn: true
              }}
              address={args.caller}
            />
          </EventValue>
          <EventValue title="Cap">
            <CurrencyValue value={args.cap} currency={assetSymbol} iconSize="small" />
          </EventValue>
        </Flex>
      );
      break;
    case 'SetCurator':
      content = (
        <EventValue title="New Curator">
          <WalletAddress
            options={{
              size: 'xSmall',
              inline: true,
              showCopyBtn: true
            }}
            address={args.newCurator}
          />
        </EventValue>
      );
      break;
    case 'SetFee':
      content = (
        <Flex gap="space-s">
          <EventValue title="Caller">
            <WalletAddress
              options={{
                size: 'xSmall',
                inline: true,
                showCopyBtn: true
              }}
              address={args.caller}
            />
          </EventValue>
          <EventValue title="New Fee">{formatPercentage(args.newFee)}</EventValue>
        </Flex>
      );
      break;
    case 'SetFeeRecipient':
      content = (
        <EventValue title="New Fee Recipient">
          <WalletAddress
            options={{
              size: 'xSmall',
              inline: true,
              showCopyBtn: true
            }}
            address={args.newFeeRecipient}
          />
        </EventValue>
      );
      break;
    case 'SetGuardian':
      content = (
        <Flex gap="space-s" width="100%">
          <EventValue title="Caller">
            <WalletAddress
              options={{
                size: 'xSmall',
                inline: true,
                showCopyBtn: true
              }}
              address={args.caller}
            />
          </EventValue>
          <EventValue title="Guardian">
            <WalletAddress
              options={{
                size: 'xSmall',
                inline: true,
                showCopyBtn: true
              }}
              address={args.guardian}
            />
          </EventValue>
        </Flex>
      );
      break;
    case 'SetIsAllocator':
      content = (
        <Flex gap="space-s" width="100%">
          <EventValue title="Allocator">
            <WalletAddress
              options={{
                size: 'xSmall',
                inline: true,
                showCopyBtn: true
              }}
              address={args.allocator}
            />
          </EventValue>
          <EventValue title="Is Allocator">
            {args.isAllocator ? 'true' : 'false'}
          </EventValue>
        </Flex>
      );
      break;
    case 'SetSkimRecipient':
      // TODO
      break;
    case 'SetSupplyQueue':
      content = (
        <Flex gap="space-s" width="100%">
          <EventValue title="Caller">
            <WalletAddress
              options={{
                size: 'xSmall',
                inline: true,
                showCopyBtn: true
              }}
              address={args.caller}
            />
          </EventValue>
          <EventValue title="New Supply Queue">
            <Flex gap="space-3xs" wrap="wrap">
              {args.newSupplyQueue.map((market, index) => (
                <span key={`newsupplyqueue-${index}-${market}`}>
                  <Link to={`/markets/${market}`}>{`${textShortener(market, {
                    threshold: 63,
                    firstSliceLength: 4,
                    secondSliceLength: 4
                  })}`}</Link>
                  {index !== args.newSupplyQueue.length - 1 ? ', ' : ''}
                </span>
              ))}
            </Flex>
          </EventValue>
        </Flex>
      );
      break;
    case 'SetTimelock':
      content = (
        <Flex gap="space-s">
          <EventValue title="Caller">
            <WalletAddress
              options={{
                size: 'xSmall',
                inline: true,
                showCopyBtn: true
              }}
              address={args.caller}
            />
          </EventValue>
          <EventValue title="New Timelock">
            {args.newTimelock / 60 / 60 + 'h'}
          </EventValue>
        </Flex>
      );
      break;
    case 'SetWithdrawQueue':
      content = (
        <Flex gap="space-s" width="100%">
          <EventValue title="Caller">
            <WalletAddress
              options={{
                size: 'xSmall',
                inline: true,
                showCopyBtn: true
              }}
              address={args.caller}
            />
          </EventValue>
          <EventValue title="New Withdraw Queue">
            <Flex gap="space-3xs" wrap="wrap">
              {args.newWithdrawQueue.map((market, index) => (
                <span key={`newwithdrawqueue-${index}-${market}`}>
                  <Link to={`/markets/${market}`}>{`${textShortener(market, {
                    threshold: 63,
                    firstSliceLength: 4,
                    secondSliceLength: 4
                  })}`}</Link>
                  {index !== args.newWithdrawQueue.length - 1 ? ', ' : ''}
                </span>
              ))}
            </Flex>
          </EventValue>
        </Flex>
      );
      break;
    case 'SubmitCap':
      content = (
        <Flex gap="space-s">
          <EventValue title="Caller">
            <WalletAddress
              options={{
                size: 'xSmall',
                inline: true,
                showCopyBtn: true
              }}
              address={args.caller}
            />
          </EventValue>
          <EventValue title="Cap">
            <CurrencyValue value={args.cap} currency={assetSymbol} iconSize="small" />
          </EventValue>
        </Flex>
      );
      break;
    case 'SubmitGuardian':
      // TODO
      break;
    case 'SubmitMarketRemoval':
      content = (
        <EventValue title="Caller">
          <WalletAddress
            options={{
              size: 'xSmall',
              inline: true,
              showCopyBtn: true
            }}
            address={args.caller}
          />
        </EventValue>
      );
      break;
    case 'SubmitTimelock':
      content = (
        <Flex gap="space-s">
          <EventValue title="New Timelock">
            {args.newTimelock / 60 / 60 + 'h'}
          </EventValue>
        </Flex>
      );
      break;
  }

  return <>{content}</>;
}
