// @ts-ignore
import BALogo from '@/assets/logos/BA-logo-full-white.svg?react';
import { NavLink, SidebarNavigationItem } from '@/components';
import BillsIcon from '@/components/icons/BillsIcon';
import CoinsStackIcon from '@/components/icons/CoinsStackIcon';
import HomeIcon from '@/components/icons/HomeIcon';
import LiquidationIcon from '@/components/icons/LiquidationIcon';
import MarketIcon from '@/components/icons/MarketIcon';
import MorphoLogoWhite from '@/components/icons/MorphoLogoWhite';
import VaultIcon from '@/components/icons/VaultIcon';
import WalletIcon from '@/components/icons/WalletIcon';
import {
  ActivityIcon,
  FixedSidebar,
  Flex,
  MediumIcon,
  XIcon,
  useTheme,
  useToggle
} from '@blockanalitica/ui';
import { NavigationData } from '@blockanalitica/ui/package/esm/src/components/client-components/sidebars/FixedSidebar/components/Sidebar/Sidebar';
import classnames from 'classnames';
import { ReactNode, useEffect } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import styles from './FixedSidebarLayout.module.scss';

type FixedSidebarLayoutProps = {
  children?: ReactNode;
};

const navigationData: NavigationData[] = [
  {
    title: 'Home',
    url: '/',
    isRoot: true,
    icon: <HomeIcon size={20} className={styles.icon} />,
    renderLink: ({ url, title }) => (
      <NavLink isRoot={true} isSingleLink={true} href={url} text={title} />
    )
  },
  {
    title: 'Morpho Vaults',
    url: '/vaults',
    isRoot: true,
    icon: <VaultIcon size={20} className={styles.icon} />,
    renderLink: ({ url, title }) => (
      <NavLink isRoot={true} isSingleLink={true} href={url} text={title} />
    )
  },
  {
    title: 'Markets',
    url: '/markets',
    isRoot: true,
    icon: <MarketIcon size={20} className={styles.icon} />,
    renderLink: ({ url, title }) => (
      <NavLink isRoot={true} isSingleLink={true} href={url} text={title} />
    )
  },
  {
    title: 'Wallets',
    url: '/wallets',
    isRoot: true,
    icon: <WalletIcon size={20} className={styles.icon} />,
    renderLink: ({ url, title }) => (
      <NavLink isRoot={true} isSingleLink={true} href={url} text={title} />
    )
  },
  {
    title: 'Positions',
    url: '/positions',
    isRoot: true,
    icon: <BillsIcon size={20} className={styles.icon} />,
    renderLink: ({ url, title }) => (
      <NavLink isRoot={true} isSingleLink={true} href={url} text={title} />
    )
  },
  {
    title: 'Activity',
    url: '/activity',
    isRoot: true,
    icon: <ActivityIcon size={20} className={styles.icon} />,
    renderLink: ({ url, title }) => (
      <NavLink isRoot={true} isSingleLink={true} href={url} text={title} />
    )
  },
  {
    title: 'Liquidations',
    url: '/liquidations',
    isRoot: true,
    icon: <LiquidationIcon size={20} className={styles.icon} />,
    renderLink: ({ url, title }) => (
      <NavLink isRoot={true} isSingleLink={true} href={url} text={title} />
    )
  },
  {
    title: 'Tokens',
    url: '/tokens',
    isRoot: true,
    icon: <CoinsStackIcon size={20} className={styles.icon} />,
    renderLink: ({ url, title }) => (
      <NavLink isRoot={true} isSingleLink={true} href={url} text={title} />
    )
  }
];

export default function FixedSidebarLayout({ children }: FixedSidebarLayoutProps) {
  const [themeMode] = useTheme();
  const location = useLocation();
  const [sidebarOpen, toggleSidebarOpen] = useToggle();

  useEffect(() => {
    toggleSidebarOpen(false);
  }, [location.pathname, toggleSidebarOpen]);

  return (
    <Flex aria-label={`Morpho Sidebar`} className={styles.container} gap="space-0">
      <FixedSidebar
        logo={
          <Link to="/">
            <MorphoLogoWhite />
          </Link>
        }
        options={{
          sidebarProps: {
            containerProps: {
              backgroundColor: [
                'var(--color-transparent)',
                'var(--color-solid)',
                'var(--color-solid)'
              ],
              style: { border: 'none' }
            }
          },
          mobileTopbarProps: {
            containerProps: {
              style: { backgroundColor: 'var(--color-solid)', border: 'none' }
            }
          }
        }}
        sidebarOpen={sidebarOpen}
        toggleSidebar={toggleSidebarOpen}
        footer={
          <Flex direction="column" gap="space-2xs">
            <Flex alignItems="center" gap="space-2xs">
              <a
                href="https://twitter.com/BlockAnalitica"
                className="text-muted"
                target="_blank"
                rel="noreferrer">
                <XIcon size={16} />
              </a>
              <a
                href="https://medium.com/block-analitica"
                className="text-muted"
                target="_blank"
                rel="noreferrer">
                <MediumIcon size={16} />
              </a>
            </Flex>
            <a
              href="https://blockanalitica.com"
              className="text-muted"
              target="_blank"
              rel="noreferrer">
              <BALogo
                className={classnames(styles.logo, {
                  [styles.dark]: themeMode === 'dark'
                })}
                height={60}
              />
            </a>
            <span className="text-muted text-1">© 2024 All rights reserved</span>
            <a
              href="https://blockanalitica.com/terms-of-service/"
              className="text-muted text-1"
              target="_blank"
              rel="noreferrer">
              Terms of Service
            </a>
          </Flex>
        }>
        {navigationData.map((navigationItem) => (
          <SidebarNavigationItem key={navigationItem.title} item={navigationItem} />
        ))}
      </FixedSidebar>

      <Flex
        className={styles.contentContainer}
        gap="space-0"
        direction="column"
        justifyContent="space-between">
        <main>{children ? children : <Outlet />}</main>
      </Flex>
    </Flex>
  );
}
