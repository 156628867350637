/*eslint no-useless-escape: "off"*/
import { useMemo } from 'react';
import QutestionSolidIcon from '@/components/icons/QutestionSolidIcon';
import { CryptoIcon as CryptoIconComponent } from '@blockanalitica/ui';

const sizeMap = {
  small: 16,
  medium: 20,
  large: 24
};

// prettier-ignore
const ENCODINGS = { 
  '\+': "%2B",
  '\!': "%21",
  '\"': "%22",
  '\#': "%23",
  '\$': "%24",
  '\&': "%26",
  '\'': "%27",
  '\(': "%28",
  '\)': "%29",
  '\*': "%2A",
  '\,': "%2C",
  '\:': "%3A",
  '\;': "%3B",
  '\=': "%3D",
  '\?': "%3F",
  '\@': "%40",
};

export function s3URLEncode(filename) {
  // prettier-ignore
  return filename.replace(/([+!"#$&'()*+,:;=?@])/img, match => ENCODINGS[match])
}

export default function CryptoIcon({ name, size = 'medium', ...rest }) {
  const imgSize = useMemo(
    () => (typeof size === 'string' ? sizeMap[size] : size),
    [size]
  );

  return (
    <CryptoIconComponent
      name={s3URLEncode(name)}
      size={size}
      options={{ fallback: <QutestionSolidIcon color="#5792ff" size={imgSize} /> }}
      {...rest}
    />
  );
}
