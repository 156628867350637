import { useApi } from '@/api';
import { CardList, Link } from '@/components';
import CurrencyValue from '@/components/CurrencyValue/CurrencyValue';
import WalletAddress from '@/components/WalletAddress/WalletAddress';
import { EmptyBox } from '@/components/empty';
import { CardLoader, Flex, List, formatPercentage } from '@blockanalitica/ui';

export default function PoolPositionsAtRisk({ poolUid, collateralSymbol }) {
  const [data, error] = useApi({
    url: `/markets/${poolUid}/positions-at-risk/`
  });

  if (error) {
    throw error;
  }

  if (data) {
    let content = undefined;

    if (data.length > 0) {
      content = (
        <List>
          {data.map((row) => (
            <Link key={row.wallet_address} to={`wallets/${row.wallet_address}`}>
              <Flex justifyContent="space-between" alignItems="center">
                <Flex justifyContent="space-between" style={{ flexBasis: '66%' }}>
                  <WalletAddress
                    options={{
                      size: 'xSmall'
                    }}
                    address={row.wallet_address}
                  />
                  <Flex direction="column" gap="space-3xs" alignItems="flex-end">
                    <span className="text-2">
                      -{formatPercentage(Number(row.price_change))}
                    </span>

                    <span className="text-2 text-muted">oracle drop</span>
                  </Flex>
                </Flex>
                <Flex direction="column" gap="space-3xs" alignItems="flex-end">
                  <CurrencyValue
                    value={row.amount}
                    currency={collateralSymbol}
                    iconSize="small"
                  />
                  <span className="text-2 text-muted">
                    <CurrencyValue value={row.amount_usd} currency={'USD'} />
                  </span>
                </Flex>
              </Flex>
            </Link>
          ))}
        </List>
      );
    } else {
      content = <EmptyBox />;
    }

    return (
      <CardList style={{ width: '100%' }} title="Positions at risk">
        {content}
      </CardList>
    );
  }

  return <CardLoader headerHeight="3rem" bodyHeight="7rem" />;
}
