import { useApi } from '@/api';
import CurrencyValue from '@/components/CurrencyValue/CurrencyValue';
import PoolName from '@/components/PoolName/PoolName';
import RelativeDateTime from '@/components/RelativeDateTime/RelativeDateTime';
import { EventName } from '@/components/events';
import SelectFilter from '@/components/filters/SelectFilter';
import TableLoader from '@/components/loaders/TableLoader';
import Table from '@/components/table/Table';
import { EVENT_TYPE_MAPPING, WALLET_EVENTS } from '@/constants';
import { EtherScanPictogram, Flex, useTableProps } from '@blockanalitica/ui';
import { useMemo } from 'react';

export default function EventsTable({ walletAddress }) {
  const selectFilterOptions = useMemo(() => {
    let options = [{ label: 'All events', value: '' }];
    options.push(
      ...WALLET_EVENTS.map((event) => ({
        label: EVENT_TYPE_MAPPING[event],
        value: event
      }))
    );
    return options;
  }, []);

  const { sorting, filters, pagination } = useTableProps({
    pageSize: 10,
    initialSorting: '-order_index',
    filtersDebounceTime: 50
  });

  const [data, error] = useApi({
    url: `/wallets/${walletAddress}/events/`,
    queryParams: {
      p: pagination.currentPage,
      p_size: pagination.pageSize,
      order: sorting.currentSorting,
      event: filters.filtersApplied.eventType
    }
  });

  if (error) {
    throw error;
  }

  let content;
  if (data) {
    const columns = [
      {
        header: 'MARKET',
        getCell: (row) => (
          <PoolName
            loanSymbol={row.loan_token_symbol}
            collateralSymbol={row.collateral_token_symbol}
            lltv={row.lltv}
          />
        )
      },
      {
        header: 'EVENT',
        getCell: (row) => <EventName event={row.event} />
      },
      {
        header: 'AMOUNT',
        getCell: (row) => (
          <CurrencyValue
            value={row.assets}
            currency={row.token_symbol}
            iconSize="small"
            trendNoIcon={true}
          />
        ),
        getCellBottom: (row) => (
          <span className="text-muted">
            <CurrencyValue value={row.assets_usd} currency={'USD'} />
          </span>
        ),
        sortingField: 'assets',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      },
      {
        header: 'TIME',
        getCell: (row) => <RelativeDateTime value={row.datetime} />,
        getCellBottom: (row) => (
          <Flex gap="space-3xs" className="text-muted">
            {row.block_number}
            <a
              href={`https://etherscan.io/tx/${row.tx_hash}`}
              target="_blank"
              rel="noreferrer">
              <EtherScanPictogram size={14} />
            </a>
          </Flex>
        ),
        sortingField: 'order_index',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      }
    ];
    content = (
      <Table
        rows={data['results']}
        getKey={(row) => row.order_index}
        columns={columns}
        sorting={sorting}
        pagination={{
          ...pagination,
          totalRecords: data.count
        }}
      />
    );
  } else {
    content = <TableLoader />;
  }

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center">
        <h4>Events</h4>
        <SelectFilter
          key="event-filter"
          placeholder="Showing all events"
          filterField="eventType"
          options={selectFilterOptions}
          onPageChange={pagination.onPageChange}
          {...filters}
        />
      </Flex>
      {content}
    </>
  );
}
