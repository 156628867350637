import { useApi } from '@/api';
import CurrencyValue from '@/components/CurrencyValue/CurrencyValue';
import { MultiLineTimeSeriesChart } from '@/components/charts';
import { formatCurreny } from '@/utils';
import { CardLoader } from '@blockanalitica/ui';
import { useMemo } from 'react';

export default function PoolCapitalAtRiskHistoryChart({ poolUid, collateralSymbol }) {
  const [data, error] = useApi({
    url: `/markets/${poolUid}/capital-at-risk-history/`
  });

  const options = useMemo(() => {
    const valueFormatter = (value) => (
      <CurrencyValue value={value} currency={collateralSymbol} />
    );
    const yScaleValueFormatter = (value) => formatCurreny(value, collateralSymbol);
    return {
      valueFormatter,
      yScaleValueFormatter
    };
  }, [collateralSymbol]);

  if (error) {
    throw error;
  }

  if (data) {
    return (
      <MultiLineTimeSeriesChart
        data={data}
        valueFormatter={options.valueFormatter}
        yScaleValueFormatter={options.yScaleValueFormatter}
      />
    );
  }

  return <CardLoader headerHeight="3rem" bodyHeight="7rem" />;
}
