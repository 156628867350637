import { useApi } from '@/api';
import { Card } from '@/components';
import { CardLoader, Flex, formatPercentage } from '@blockanalitica/ui';
import { LineChart } from '@/components/charts';
import { TimeSelector } from '@/components/charts';
import { useState } from 'react';

export default function LTVMarketHistoricChart({ poolUid }) {
  const [daysAgo, setDaysAgo] = useState(30);
  const [data, error] = useApi({
    url: `/markets/${poolUid}/market-ltv-history/`,
    queryParams: {
      days_ago: daysAgo
    }
  });

  if (error) {
    throw error;
  }

  if (data) {
    return (
      <Card>
        <Flex direction="column" gap="space-xs">
          <Flex
            direction={['row', 'row', 'column']}
            alignItems={['flex-start', 'flex-start', 'center']}
            justifyContent="space-between"
            gap="space-xs">
            <h4>Loan To Value</h4>
            <TimeSelector
              activeOption={daysAgo}
              onChange={(e) => setDaysAgo(e.target.value)}
            />
          </Flex>
          <Flex direction="column">
            <LineChart
              key="ltv"
              data={data}
              field="ltv"
              valueFormatter={formatPercentage}
              yScaleValueFormatter={formatPercentage}
              aspectRatio={6}
            />
          </Flex>
        </Flex>
      </Card>
    );
  }

  return <CardLoader headerHeight="3rem" bodyHeight="7rem" />;
}
