import { useApi } from '@/api';
import { CryptoIcon, KpiCard, Page } from '@/components';
import CurrencyValue from '@/components/CurrencyValue/CurrencyValue';
import PoolName from '@/components/PoolName/PoolName';
import WalletAddress from '@/components/WalletAddress/WalletAddress';
import { TimeFilter, TimeOptions } from '@/components/global';
import { KpiSwitchCard } from '@/components/kpi';
import { SimpleVaultCard } from '@/components/vault';
import { useSearchParams } from '@/hooks';
import {
  Box,
  CurrencyFilter,
  Flex,
  Kpi,
  KpiBody,
  KpiFooter,
  KpiTitle,
  PageLoader,
  Text,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  TrendValue,
  formatNumber,
  formatPercentage,
  useTableProps
} from '@blockanalitica/ui';
import { useParams } from 'react-router-dom';
import ActivityCard from './ActivityCard';
import BorrowersList from './BorrowersList';
import HistoricRatesTabs from './HistoricRatesTabs';
import HistoricTabs from './HistoricTabs';
import HistoricVolumeTabs from './HistoricVolumeTabs';
import LTVMarketHistoricChart from './LTVHistoricChart';
import LatestEventsListCard from './LatestEventsListCard';
import PoolCapitalAtRiskTabs from './PoolCapitalAtRiskTabs';
import PoolPositionsAtRisk from './PoolPositionsAtRisk';
import SuppliersList from './SuppliersList';

export default function Pool() {
  const { poolUid } = useParams();
  const [daysAgo] = useSearchParams('days_ago', 1);
  const [data, error] = useApi({
    url: `/markets/${poolUid}/`,
    queryParams: {
      days_ago: daysAgo
    }
  });

  const { filters } = useTableProps({
    initialFilters: { currency: 'USD' },
    filtersDebounceTime: 50
  });

  if (error) {
    throw error;
  }

  if (data) {
    return (
      <Page
        title={
          <Flex alignItems="baseline" justifyContent="center" gap="space-xs">
            <PoolName
              loanSymbol={data.loan_token_symbol}
              collateralSymbol={data.collateral_token_symbol}
              iconSize={30}
              short={false}
            />
            <div className="text-2 text-muted">LLTV: {formatPercentage(data.lltv)}</div>
          </Flex>
        }
        actions={
          <Flex
            gap="space-xs"
            justifyContent={['flex-end', 'flex-end', 'space-between']}>
            <TimeFilter defaultValue={TimeOptions['1D']} />
          </Flex>
        }>
        <Flex alignItems="center" justifyContent={'flex-end'}>
          <CurrencyFilter
            key="currency-filter"
            width="8rem"
            height="1.65rem"
            currency2={{ value: 'token', displayValue: 'TOKEN' }}
            currency1={{ value: 'USD', displayValue: 'USD' }}
            {...filters}
          />
        </Flex>
        <Flex direction={['row', 'column', 'column']} gap="space-m">
          <Flex flexBasis={['33%', '100%', '100%']}>
            <KpiSwitchCard
              title="Available Liquidity"
              values={{
                USD: <CurrencyValue value={data.liquidity_usd} currency="USD" />,
                token: (
                  <CurrencyValue
                    value={data.liquidity}
                    currency={data.loan_token_symbol}
                    iconSize={30}
                  />
                )
              }}
              trendValues={{
                USD: (
                  <CurrencyValue
                    value={data.liquidity_usd - data.previous_liquidity_usd}
                    trend
                  />
                ),
                token: (
                  <CurrencyValue
                    value={data.liquidity - data.previous_liquidity}
                    trend
                  />
                )
              }}
              footers={{
                USD: (
                  <CurrencyValue
                    value={data.liquidity}
                    currency={data.loan_token_symbol}
                    iconSize="small"
                  />
                ),
                token: <CurrencyValue value={data.liquidity_usd} currency="USD" />
              }}
              switchValue={filters.filtersApplied.currency}
              options={{
                valueSize: 'text-5',
                trendValueSize: 'text-3',
                footerSize: 'text-2'
              }}
            />
          </Flex>

          <Flex flexBasis={['33%', '100%', '100%']}>
            <KpiSwitchCard
              title="Total Supply"
              values={{
                USD: <CurrencyValue value={data.total_supply_usd} currency="USD" />,
                token: (
                  <CurrencyValue
                    value={data.total_supply}
                    currency={data.loan_token_symbol}
                    iconSize={30}
                  />
                )
              }}
              trendValues={{
                USD: (
                  <CurrencyValue
                    value={data.total_supply_usd - data.previous_total_supply_usd}
                    trend
                  />
                ),
                token: (
                  <CurrencyValue
                    value={data.total_supply - data.previous_total_supply}
                    trend
                  />
                )
              }}
              footers={{
                USD: (
                  <Flex justifyContent={'space-between'}>
                    <CurrencyValue
                      value={data.total_supply}
                      currency={data.loan_token_symbol}
                      iconSize="small"
                    />
                    <WalletAddress
                      options={{
                        size: 'xSmall',
                        inline: true,
                        showCopyBtn: true,
                        showEthScanLink: true
                      }}
                      address={data.loan_token_address}
                    />
                  </Flex>
                ),
                token: (
                  <Flex justifyContent={'space-between'}>
                    <CurrencyValue value={data.total_supply_usd} currency="USD" />
                    <WalletAddress
                      options={{
                        size: 'xSmall',
                        inline: true,
                        showCopyBtn: true,
                        showEthScanLink: true
                      }}
                      address={data.loan_token_address}
                    />
                  </Flex>
                )
              }}
              switchValue={filters.filtersApplied.currency}
              options={{
                valueSize: 'text-5',
                trendValueSize: 'text-3',
                footerSize: 'text-2'
              }}
            />
          </Flex>
          <Flex flexBasis={['33%', '100%', '100%']}>
            <KpiSwitchCard
              title="Total Borrow"
              values={{
                USD: <CurrencyValue value={data.total_borrow_usd} currency="USD" />,
                token: (
                  <CurrencyValue
                    value={data.total_borrow}
                    currency={data.loan_token_symbol}
                    iconSize={30}
                  />
                )
              }}
              trendValues={{
                USD: (
                  <CurrencyValue
                    value={data.total_borrow_usd - data.previous_total_borrow_usd}
                    trend
                  />
                ),
                token: (
                  <CurrencyValue
                    value={data.total_borrow - data.previous_total_borrow}
                    trend
                  />
                )
              }}
              footers={{
                USD: (
                  <Flex justifyContent={'space-between'}>
                    <CurrencyValue
                      value={data.total_borrow}
                      currency={data.loan_token_symbol}
                      iconSize="small"
                    />
                    <WalletAddress
                      options={{
                        size: 'xSmall',
                        inline: true,
                        showCopyBtn: true,
                        showEthScanLink: true
                      }}
                      address={data.loan_token_address}
                    />
                  </Flex>
                ),
                token: (
                  <Flex justifyContent={'space-between'}>
                    <CurrencyValue value={data.total_borrow_usd} currency="USD" />
                    <WalletAddress
                      options={{
                        size: 'xSmall',
                        inline: true,
                        showCopyBtn: true,
                        showEthScanLink: true
                      }}
                      address={data.loan_token_address}
                    />
                  </Flex>
                )
              }}
              switchValue={filters.filtersApplied.currency}
              options={{
                valueSize: 'text-5',
                trendValueSize: 'text-3',
                footerSize: 'text-2'
              }}
            />
          </Flex>
        </Flex>
        <Flex direction={['row', 'column', 'column']} gap="space-m">
          <Box flexBasis={['66%', '100%', '100%']}>
            <HistoricTabs
              poolUid={poolUid}
              loanSymbol={data.loan_token_symbol}
              collateralSymbol={data.collateral_token_symbol}
              switchValue={filters.filtersApplied.currency}
            />
          </Box>
          <Box flexBasis={['32%', '100%', '100%']}>
            <Flex
              direction={['column', 'column', 'column']}
              gap="space-xs"
              style={{ height: '100%' }}>
              <KpiSwitchCard
                title="Total Collateral"
                values={{
                  USD: (
                    <CurrencyValue value={data.total_collateral_usd} currency="USD" />
                  ),
                  token: (
                    <CurrencyValue
                      value={data.total_collateral}
                      currency={data.collateral_token_symbol}
                      iconSize={30}
                    />
                  )
                }}
                trendValues={{
                  USD: (
                    <CurrencyValue
                      value={
                        data.total_collateral_usd - data.previous_total_collateral_usd
                      }
                      trend
                    />
                  ),
                  token: (
                    <CurrencyValue
                      value={data.total_collateral - data.previous_total_collateral}
                      trend
                    />
                  )
                }}
                footers={{
                  USD: (
                    <Flex justifyContent={'space-between'}>
                      <CurrencyValue
                        value={data.total_collateral}
                        currency={data.collateral_token_symbol}
                        iconSize="small"
                      />
                      <WalletAddress
                        options={{
                          size: 'xSmall',
                          inline: true,
                          showCopyBtn: true,
                          showEthScanLink: true
                        }}
                        address={data.collateral_token_address}
                      />
                    </Flex>
                  ),
                  token: (
                    <Flex justifyContent={'space-between'}>
                      <CurrencyValue value={data.total_collateral_usd} currency="USD" />
                      <WalletAddress
                        options={{
                          size: 'xSmall',
                          inline: true,
                          showCopyBtn: true,
                          showEthScanLink: true
                        }}
                        address={data.collateral_token_address}
                      />
                    </Flex>
                  )
                }}
                switchValue={filters.filtersApplied.currency}
                options={{
                  valueSize: 'text-5',
                  trendValueSize: 'text-3',
                  footerSize: 'text-2'
                }}
              />

              <KpiCard
                title="Total Value Locked"
                value={<CurrencyValue value={data.tvl} currency="USD" />}
                trendValue={
                  <CurrencyValue value={data.tvl - data.previous_tvl} trend />
                }
                footer={
                  <Flex justifyContent="space-between">
                    {data.collateral_token_symbol ? (
                      <CurrencyValue
                        value={data.total_collateral}
                        currency={data.collateral_token_symbol}
                        iconSize="small"
                      />
                    ) : null}

                    <CurrencyValue
                      value={data.total_supply - data.total_borrow}
                      currency={data.loan_token_symbol}
                      iconSize="small"
                    />
                  </Flex>
                }
                options={{
                  valueSize: 'text-5',
                  trendValueSize: 'text-3',
                  footerSize: 'text-2'
                }}
              />
              <Kpi border="none">
                <KpiTitle>Oracle price</KpiTitle>
                <KpiBody
                  gap="space-2xs"
                  className="text-5"
                  justifyContent="space-between">
                  <Flex
                    gap="space-2xs"
                    justifyContent={'flex-start'}
                    alignItems={'center'}>
                    <Box>
                      {filters.filtersApplied.currency === 'USD' ? (
                        <CurrencyValue
                          value={data.oracle_price_usd}
                          currency="USD"
                          notation="standard"
                        />
                      ) : (
                        <CurrencyValue
                          value={data.oracle_price}
                          currency={data.loan_token_symbol}
                          notation="standard"
                        />
                      )}
                    </Box>
                    <TrendValue
                      className="text-3"
                      value={
                        filters.filtersApplied.currency === 'USD'
                          ? formatNumber(
                              data.oracle_price_usd - data.previous_oracle_price_usd
                            ).toString()
                          : formatNumber(
                              data.oracle_price - data.previous_oracle_price
                            ).toString()
                      }
                    />
                  </Flex>
                  {data.oracle_source ? (
                    <Box>
                      <Flex justifyContent={'flex-end'} gap="space-m">
                        <Tooltip>
                          <TooltipTrigger>
                            <CryptoIcon name={data.oracle_source} size={'large'} />
                          </TooltipTrigger>
                          <TooltipContent>
                            <Text>
                              {data.oracle_source.charAt(0).toUpperCase() +
                                data.oracle_source.slice(1).toLowerCase()}
                            </Text>
                          </TooltipContent>
                        </Tooltip>
                      </Flex>
                    </Box>
                  ) : null}
                </KpiBody>
                <KpiFooter justifyContent="space-between">
                  {filters.filtersApplied.currency === 'token' ? (
                    <CurrencyValue
                      value={data.oracle_price_usd}
                      currency="USD"
                      notation="standard"
                    />
                  ) : (
                    <CurrencyValue
                      value={data.oracle_price}
                      currency={data.loan_token_symbol}
                      notation="standard"
                      iconSize="small"
                    />
                  )}
                  <WalletAddress
                    options={{
                      size: 'xSmall',
                      inline: true,
                      showCopyBtn: true,
                      showEthScanLink: true
                    }}
                    address={data.oracle}
                  />
                </KpiFooter>
              </Kpi>
            </Flex>
          </Box>
        </Flex>
        {data.vaults.length > 0 ? (
          <Flex direction={['row', 'column', 'column']} gap="space-xs">
            <Flex flexBasis={['100%', '100%', '100%']}>
              <SimpleVaultCard data={data.vaults[0]} style={{ width: '100%' }} />
            </Flex>
            {/* <Flex flexBasis={['50%', '100%', '100%']}>
            <KpiCard
              title="Liquidation Bonus"
              value={formatPercentage(data.liquidation_incentive)}
            />
          </Flex> */}
          </Flex>
        ) : null}

        <Flex direction={['row', 'column', 'column']} gap="space-m">
          <Box flexBasis={['32%', '100%', '100%']}>
            <Flex
              direction={['column', 'column', 'column']}
              gap="space-xs"
              style={{ height: '100%' }}>
              <KpiCard
                title="Borrow APY"
                value={formatPercentage(data.borrow_rate_apy)}
                trendValue={formatPercentage(
                  data.borrow_rate_apy - data.previous_borrow_rate_apy
                )}
                footer={
                  <Flex>
                    <span style={{ marginRight: '10px' }}>Borrow Rate at Target:</span>
                    {formatPercentage(data.rate_at_target_apy)}
                  </Flex>
                }
                options={{
                  valueSize: 'text-5',
                  trendValueSize: 'text-3',
                  footerSize: 'text-2'
                }}
              />

              <KpiCard
                title="Supply APY"
                value={formatPercentage(data.total_rate_apy)}
                trendValue={formatPercentage(
                  data.total_rate_apy - data.previous_total_rate_apy
                )}
                footer={
                  <Flex gap="space-3xs">
                    <Flex>{formatPercentage(data.supply_rate_apy)}</Flex>
                    <Flex>+</Flex>
                    <Flex gap="space-3xs">
                      {formatPercentage(data.reward_rate_apy)}
                    </Flex>
                    <Flex>+</Flex>
                    <Flex>
                      <CurrencyValue
                        value={data.morpho_reward_share}
                        currency="MORPHO"
                        iconSize="small"
                      />
                      /$1000
                    </Flex>
                  </Flex>
                }
                options={{
                  valueSize: 'text-5',
                  trendValueSize: 'text-3',
                  footerSize: 'text-2'
                }}
              />
              <KpiCard
                title="Utilization"
                value={formatPercentage(data.utilization)}
                trendValue={formatPercentage(
                  data.utilization - data.previous_utilization
                )}
                footer={
                  <Flex alignItems="baseline" justifyContent="space-between">
                    <Flex>
                      <span style={{ marginRight: '10px' }}>Target utilization:</span>
                      {formatPercentage(data.target_utilization)}
                    </Flex>
                    <WalletAddress
                      options={{
                        size: 'xSmall',
                        inline: true,
                        showCopyBtn: true,
                        showEthScanLink: true
                      }}
                      address={data.irm}
                    />
                  </Flex>
                }
                options={{
                  valueSize: 'text-5',
                  trendValueSize: 'text-3',
                  footerSize: 'text-2'
                }}
              />
            </Flex>
          </Box>
          <Box flexBasis={['66%', '100%', '100%']}>
            <HistoricRatesTabs poolUid={poolUid} />
          </Box>
        </Flex>

        <Flex direction={['row', 'column', 'column']} gap="space-m">
          <Flex flexBasis={['50%', '100%', '100%']}>
            <SuppliersList poolUid={poolUid} totalSupply={data.total_supply} />
          </Flex>
          <Flex flexBasis={['50%', '100%', '100%']}>
            <BorrowersList poolUid={poolUid} totalBorrow={data.total_borrow} />
          </Flex>
          {/* <Flex flexBasis={['33%', '100%', '100%']}>
            <LatestEventsListCard poolUid={poolUid} />
          </Flex> */}
        </Flex>

        <ActivityCard poolUid={poolUid} />
        <Flex direction={['row', 'column', 'column']} gap="space-m">
          <Flex flexBasis={['32%', '100%', '100%']}>
            <LatestEventsListCard poolUid={poolUid} />
          </Flex>
          <Flex flexBasis={['67%', '100%', '100%']}>
            <HistoricVolumeTabs
              poolUid={poolUid}
              loanSymbol={data.loan_token_symbol}
              collateralSymbol={data.collateral_token_symbol}
            />
          </Flex>
        </Flex>
        <Flex direction={['row', 'column', 'column']} gap="space-m">
          <Flex flexBasis={['67%', '100%', '100%']}>
            <PoolCapitalAtRiskTabs
              poolUid={poolUid}
              collateralSymbol={data.collateral_token_symbol}
            />
          </Flex>
          <Flex flexBasis={['32%', '100%', '100%']}>
            <PoolPositionsAtRisk
              poolUid={poolUid}
              collateralSymbol={data.collateral_token_symbol}
            />
          </Flex>
        </Flex>
        {/* <LTVBucketsChart poolUid={poolUid} loanSymbol={data.loan_token_symbol} /> */}
        <LTVMarketHistoricChart poolUid={poolUid} />
      </Page>
    );
  }

  return <PageLoader />;
}
