import { Card } from '@/components';
import DisplaySwitch from '@/components/DisplaySwitch/DisplaySwitch';
import { TimeSelector } from '@/components/charts';
import { Flex } from '@blockanalitica/ui';
import { useState } from 'react';
import CapitalAtRiskChart from './CapitalAtRiskChart';
import CapitalAtRiskHistoryChart from './CapitalAtRiskHistoryChart';
import LTVHistoryChart from './LTVHistoryChart';

export default function CapitalAtRiskTabs() {
  const [daysAgo, setDaysAgo] = useState(30);
  const [activeDisplayOption, setActiveDisplayOption] = useState('car');

  const displayOptions = [
    { key: 'car', value: 'Collateral at risk' },
    { key: 'car-history', value: 'Collateral at risk - history' },
    { key: 'ltv-history', value: 'Loan to value - history' }
  ];

  let content;
  let daysDropdown = null;

  if (activeDisplayOption === 'car') {
    content = <CapitalAtRiskChart />;
  } else if (activeDisplayOption === 'car-history') {
    content = <CapitalAtRiskHistoryChart daysAgo={daysAgo} />;
    daysDropdown = (
      <TimeSelector
        activeOption={daysAgo}
        onChange={(e) => setDaysAgo(e.target.value)}
      />
    );
  } else if (activeDisplayOption === 'ltv-history') {
    content = <LTVHistoryChart daysAgo={daysAgo} />;
    daysDropdown = (
      <TimeSelector
        activeOption={daysAgo}
        onChange={(e) => setDaysAgo(e.target.value)}
      />
    );
  }

  return (
    <Card style={{ width: '100%' }}>
      <Flex direction="column" gap="space-xs">
        <Flex
          direction={['row', 'row', 'column']}
          alignItems="center"
          justifyContent="space-between"
          gap="space-xs">
          <DisplaySwitch
            options={displayOptions}
            activeOption={activeDisplayOption}
            onChange={setActiveDisplayOption}
          />
          {daysDropdown}
        </Flex>
        {content}
      </Flex>
    </Card>
  );
}
