import Svg from './Svg';

export default function HomeIcon({ size = 32, color = 'currentColor', ...props }) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="-0.5 -0.5 14 14"
      width={size + 'px'}
      height={size + 'px'}
      {...props}>
      <g id="home-4--home-house-roof-shelter">
        <path
          id="Vector"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M0.5 8 7 1.5 13.5 8"
          strokeWidth={1}
        />
        <path
          id="Vector_2"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="m2.5 6 0 6.5h9V6"
          strokeWidth={1}
        />
      </g>
    </Svg>
  );
}
