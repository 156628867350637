import { useApi } from '@/api';
import { HealthRate, KpiCard, Link, Page } from '@/components';
import CurrencyValue from '@/components/CurrencyValue/CurrencyValue';
import PoolName from '@/components/PoolName/PoolName';
import WalletAddress from '@/components/WalletAddress/WalletAddress';
import { TimeFilter, TimeOptions } from '@/components/global';
import { useSearchParams } from '@/hooks';
import {
  Box,
  Flex,
  FormattedNumber,
  PageLoader,
  formatPercentage
} from '@blockanalitica/ui';
import { useParams } from 'react-router-dom';
import HistoricTabs from './HistoricTabs';
import PoolWalletActivityCard from './PoolWalletActivityCard';
import PoolWalletEventsTable from './PoolWalletEventsTable';

export default function PoolWallet() {
  const { poolUid, walletAddress } = useParams();
  const [daysAgo] = useSearchParams('days_ago', 1);
  const [data, error] = useApi({
    url: `/markets/${poolUid}/wallets/${walletAddress}/`,
    queryParams: {
      days_ago: daysAgo
    }
  });

  if (error) {
    throw error;
  }

  if (data) {
    return (
      <Page
        title={
          <div style={{ fontSize: 'initial' }}>
            <WalletAddress
              address={data.wallet_address}
              options={{
                size: 'large',
                showEthScanLink: true,
                showDebankLink: true,
                showZapperLink: true,
                showCopyBtn: true
              }}
            />
          </div>
        }
        actions={<TimeFilter defaultValue={TimeOptions['1D']} />}>
        <Flex justifyContent="flex-start">
          <Link to={`/markets/${poolUid}`}>
            <PoolName
              loanSymbol={data.loan_token_symbol}
              collateralSymbol={data.collateral_token_symbol}
              lltv={data.lltv}
            />
          </Link>
        </Flex>

        <Flex direction={['row', 'column', 'column']} gap="space-m">
          <Flex flexBasis={['33%', '100%', '100%']}>
            <KpiCard
              title="Health Rate"
              value={<HealthRate value={data.health_rate} />}
              footer={'LTV: ' + formatPercentage(data.ltv)}
            />
          </Flex>
          <Flex flexBasis={['33%', '100%', '100%']}>
            <KpiCard
              title="Liquidation Price"
              value={
                <CurrencyValue
                  value={data.liquidation_price}
                  currency={data.loan_token_symbol}
                  iconSize={30}
                  notation="standard"
                />
              }
              footer={
                <Flex justifyContent={'space-between'} gap="space-xs">
                  <CurrencyValue
                    value={data.liquidation_price * data.loan_token_price}
                    currency={'USD'}
                    notation="standard"
                  />
                  <FormattedNumber
                    gap="space-0"
                    value={
                      ((data.oracle_price - data.liquidation_price) /
                        data.oracle_price) *
                      100
                    }
                    options={{
                      suffix: '%'
                    }}
                  />
                </Flex>
              }
            />
          </Flex>
          <Flex flexBasis={['33%', '100%', '100%']}>
            <KpiCard
              title="Oracle Price"
              value={
                <CurrencyValue
                  value={data.oracle_price}
                  currency={data.loan_token_symbol}
                  iconSize={30}
                  notation="standard"
                />
              }
              footer={
                <Flex justifyContent={'flex-start'} gap="space-xs">
                  <CurrencyValue
                    value={data.oracle_price * data.loan_token_price}
                    currency={'USD'}
                    notation="standard"
                  />
                </Flex>
              }
            />
          </Flex>
        </Flex>
        <Flex direction={['row', 'column', 'column']} gap="space-m">
          <Box flexBasis={['66%', '100%', '100%']}>
            <HistoricTabs
              poolUid={poolUid}
              walletAddress={walletAddress}
              loanSymbol={data.loan_token_symbol}
              collateralSymbol={data.collateral_token_symbol}
            />
          </Box>
          <Box flexBasis={['32%', '100%', '100%']}>
            <Flex
              direction={['column', 'column', 'column']}
              gap="space-xs"
              style={{ height: '100%' }}>
              <KpiCard
                title="Collateral"
                value={
                  <CurrencyValue
                    value={data.collateral}
                    currency={data.collateral_token_symbol}
                    iconSize={30}
                  />
                }
                trendValue={
                  <CurrencyValue value={data[`collateral_change_${daysAgo}d`]} trend />
                }
                footer={
                  <Flex justifyContent="space-between">
                    <CurrencyValue
                      value={data.collateral * data.collateral_token_price}
                      currency="USD"
                    />
                    <Flex gap="space-3xs">
                      <span>LLTV:</span>
                      {formatPercentage(data.lltv)}
                    </Flex>
                  </Flex>
                }
              />

              <KpiCard
                title="Borrow"
                value={
                  <CurrencyValue
                    value={data.borrow}
                    currency={data.loan_token_symbol}
                    iconSize={30}
                  />
                }
                trendValue={
                  <CurrencyValue value={data[`borrow_change_${daysAgo}d`]} trend />
                }
                footer={
                  <CurrencyValue
                    value={data.borrow * data.loan_token_price}
                    currency="USD"
                  />
                }
              />

              <KpiCard
                title="Supply"
                value={
                  <CurrencyValue
                    value={data.supply}
                    currency={data.loan_token_symbol}
                    iconSize={30}
                  />
                }
                trendValue={
                  <CurrencyValue value={data[`supply_change_${daysAgo}d`]} trend />
                }
                footer={
                  <CurrencyValue
                    value={data.supply * data.loan_token_price}
                    currency="USD"
                  />
                }
              />
            </Flex>
          </Box>
        </Flex>

        <PoolWalletActivityCard poolUid={poolUid} walletAddress={walletAddress} />
        <PoolWalletEventsTable poolUid={poolUid} walletAddress={walletAddress} />
      </Page>
    );
  }

  return <PageLoader />;
}
