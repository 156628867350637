import { useApi } from '@/api';
import CurrencyValue from '@/components/CurrencyValue/CurrencyValue';
import TableLoader from '@/components/loaders/TableLoader';
import Table from '@/components/table/Table';
import { CryptoIcon, Flex } from '@blockanalitica/ui';
import _ from 'lodash';
import { Link } from 'react-router-dom';

export default function ChainTable({ daysAgo }) {
  const [data, error] = useApi({
    url: '/chains/',
    queryParams: {
      days_ago: daysAgo,
      order: '-total_deposits'
    }
  });

  if (error) {
    throw error;
  }

  if (data) {
    const mapping = {
      ethereum: 'ETH',
      base: 'BASE'
    };
    const columns = [
      {
        header: 'Network',
        getCell: (row) => (
          <Flex gap="space-xs" alignItems="center">
            <CryptoIcon name={mapping[row.network]} size={25} />
            {_.capitalize(row.network)}
          </Flex>
        )
      },
      {
        header: 'TOTAL DEPOSITS',
        getCell: (row) => <CurrencyValue value={row.total_deposits} currency="USD" />,
        getCellBottom: (row) => (
          <CurrencyValue
            value={row.total_deposits - row.previous_total_deposits}
            currency="USD"
            trend
          />
        ),
        sortingField: 'total_deposits',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      },
      {
        header: 'TVL',
        getCell: (row) => <CurrencyValue value={row.tvl} currency="USD" />,
        getCellBottom: (row) => (
          <CurrencyValue value={row.tvl - row.previous_tvl} currency="USD" trend />
        ),
        sortingField: 'tvl',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      },
      {
        header: 'TOTAL BORROW',
        getCell: (row) => <CurrencyValue value={row.total_borrow} currency="USD" />,
        getCellBottom: (row) => (
          <CurrencyValue
            value={row.total_borrow - row.previous_total_borrow}
            currency="USD"
            trend
          />
        ),
        sortingField: 'total_borrow',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      },
      {
        header: 'TOTAL SUPPLY',
        getCell: (row) => <CurrencyValue value={row.total_supply} currency="USD" />,
        getCellBottom: (row) => (
          <CurrencyValue
            value={row.total_supply - row.previous_total_supply}
            currency="USD"
            trend
          />
        ),
        sortingField: 'total_supply',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      },
      {
        header: 'TOTAL COLLATERAL',
        getCell: (row) => <CurrencyValue value={row.total_collateral} currency="USD" />,
        getCellBottom: (row) => (
          <CurrencyValue
            value={row.total_collateral - row.previous_total_collateral}
            currency="USD"
            trend
          />
        ),
        sortingField: 'total_collateral',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      }
    ];

    return (
      <>
        <Flex justifyContent="space-between" alignItems="center">
          <h4>Networks</h4>
        </Flex>
        <Table
          rows={data}
          columns={columns}
          getKey={(row) => row.uid}
          getLinkWrapper={(row) => <Link to={`${row.network}`} />}
        />
      </>
    );
  }
  return <TableLoader />;
}
