import KpiCard from './KpiCard';

export default function KpiSwitchCard({
  values,
  trendValues,
  footers,
  switchValue,
  ...rest
}) {
  return (
    <KpiCard
      value={values[switchValue]}
      trendValue={trendValues ? trendValues[switchValue] : null}
      footer={footers ? footers[switchValue] : null}
      {...rest}
    />
  );
}
