import { useApi } from '@/api';
import { Card } from '@/components';
import { Heatmap } from '@/components/charts';
import { Flex, useIsMobile } from '@blockanalitica/ui';

export default function ActivityCard({ poolUid }) {
  const isMobile = useIsMobile();
  const monthsAgo = isMobile ? 3 : 12;
  const [data, error] = useApi({
    url: `/markets/${poolUid}/activity/`
  });

  if (error) {
    throw error;
  }

  if (!data) {
    return <></>;
  }

  return (
    <Card header={{ title: 'Activity' }}>
      <Flex
        direction={['row', 'column', 'column']}
        gap="space-m"
        justifyContent="center">
        <Heatmap data={data} monthsAgo={monthsAgo} />
      </Flex>
    </Card>
  );
}
