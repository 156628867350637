import { Card } from '@/components';
import DisplaySwitch from '@/components/DisplaySwitch/DisplaySwitch';
import { TimeSelector } from '@/components/charts';
import { Flex } from '@blockanalitica/ui';
import { useMemo, useState } from 'react';
import HistoricRatesLineChartsTab from './HistoricRatesLineChartsTab';
import HistoricRatesMultilineChart from './HistoricRatesMultilineChartTab';
export default function HistoricRatesTabs({ poolUid }) {
  const [daysAgo, setDaysAgo] = useState(30);
  const [activeDisplayOption, setActiveDisplayOption] = useState('APY');

  const displayOptions = useMemo(
    () => [
      { key: 'APY', value: 'APY' },
      { key: 'total_rate_apy', value: 'NET APY' },
      { key: 'utilization', value: 'Utilization' }
    ],
    []
  );

  let chart;

  if (activeDisplayOption === 'APY') {
    chart = (
      <HistoricRatesMultilineChart
        key={activeDisplayOption}
        poolUid={poolUid}
        daysAgo={daysAgo}
      />
    );
  } else {
    chart = (
      <HistoricRatesLineChartsTab
        key={activeDisplayOption}
        daysAgo={daysAgo}
        poolUid={poolUid}
        activeDisplayOption={activeDisplayOption}
      />
    );
  }

  return (
    <Card>
      <Flex direction="column" gap="space-xs">
        <Flex
          direction={['row', 'row', 'column']}
          alignItems={['flex-start', 'flex-start', 'center']}
          justifyContent="space-between"
          gap="space-xs">
          <DisplaySwitch
            options={displayOptions}
            activeOption={activeDisplayOption}
            onChange={setActiveDisplayOption}
          />

          <TimeSelector
            activeOption={daysAgo}
            onChange={(e) => setDaysAgo(e.target.value)}
          />
        </Flex>
        {chart}
      </Flex>
    </Card>
  );
}
