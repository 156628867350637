import { useApi } from '@/api';
import { Link } from '@/components';
import CurrencyValue from '@/components/CurrencyValue/CurrencyValue';
import RelativeDateTime from '@/components/RelativeDateTime/RelativeDateTime';
import WalletAddress from '@/components/WalletAddress/WalletAddress';
import TableLoader from '@/components/loaders/TableLoader';
import Table from '@/components/table/Table';
import VaultName from '@/components/vault/VaultName';
import { formatPercentage, useTableProps } from '@blockanalitica/ui';

function displaySupplier(name, asset_symbol, symbol, walletAddress) {
  if (name) {
    return <VaultName assetSymbol={asset_symbol} symbol={symbol} name={name} />;
  } else {
    return <WalletAddress options={{ size: 'xSmall' }} address={walletAddress} />;
  }
}

export default function SuppliersTable({ poolUid, daysAgo, filters, searchText }) {
  const { sorting, pagination } = useTableProps({
    pageSize: 10,
    initialSorting: '-supply',
    initialFilters: { currency: 'USD' },
    filtersDebounceTime: 50
  });
  const [data, error] = useApi({
    url: `/markets/${poolUid}/wallets/`,
    queryParams: {
      p: pagination.currentPage,
      p_size: pagination.pageSize,
      type: 'suppliers',
      order: sorting.currentSorting,
      search: searchText
    }
  });

  if (error) {
    throw error;
  }

  if (data) {
    const columns = [
      {
        header: 'WALLET',
        getCell: (row) => (
          <>
            {displaySupplier(
              row.name,
              row.asset_symbol,
              row.symbol,
              row.wallet_address
            )}
          </>
        ),
        sortingField: 'wallet_address'
      },
      {
        header: 'SHARE',
        getCell: (row) => (
          <span className="text-2">
            {formatPercentage(row.supply / data.total_supply)}
          </span>
        ),
        sortingField: 'supply',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      },
      {
        header: 'SUPPLY',
        getCell: (row) => (
          <>
            {filters.filtersApplied.currency === 'USD' ? (
              <CurrencyValue value={row.supply_usd} currency="USD" />
            ) : (
              <CurrencyValue
                value={row.supply}
                currency={row.loan_token_symbol}
                iconSize="small"
              />
            )}
          </>
        ),
        getCellBottom: (row) => (
          <>
            {filters.filtersApplied.currency === 'USD' ? (
              <CurrencyValue
                value={row[`supply_change_${daysAgo}d_usd`]}
                currency="USD"
                trend
              />
            ) : (
              <CurrencyValue
                value={row[`supply_change_${daysAgo}d`]}
                currency={row.loan_token_symbol}
                iconSize="small"
                trend
              />
            )}
          </>
        ),
        sortingField: 'supply',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      },
      {
        header: 'LATEST ACTIVITY',
        getCell: (row) => <RelativeDateTime value={row.datetime} />,
        sortingField: 'datetime',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      }
    ];
    return (
      <Table
        rows={data['results']}
        getKey={(row) => row.wallet_address}
        getLinkWrapper={(row) => (
          <Link
            to={
              row.name
                ? `/vaults/vaults/${row.wallet_address}`
                : `${row.wallet_address}`
            }></Link>
        )}
        columns={columns}
        sorting={sorting}
        pagination={{
          ...pagination,
          totalRecords: data.count
        }}
      />
    );
  }

  return <TableLoader />;
}
