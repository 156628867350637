import { useApi } from '@/api';
import { KpiCard, Page } from '@/components';
import CurrencyValue from '@/components/CurrencyValue/CurrencyValue';
import { TimeFilter, TimeOptions } from '@/components/global';
import { useSearchParams } from '@/hooks';
import { Box, Flex, PageLoader, WalletAddress, formatNumber } from '@blockanalitica/ui';
import { useParams } from 'react-router-dom';
import ActivityHeatmap from './ActivityHeatmap';
import EventsTable from './EventsTable';
import PoolsTable from './PoolsTable';

export default function Wallet() {
  const { address } = useParams();
  const [daysAgo] = useSearchParams('days_ago', 1);
  const [data, error] = useApi({
    url: `/wallets/${address}/`
  });

  if (error) {
    throw error;
  }

  if (data) {
    return (
      <Page
        title={
          <div style={{ fontSize: 'initial' }}>
            <WalletAddress
              address={data.wallet_address}
              options={{
                size: 'large',
                showEthScanLink: true,
                showDebankLink: true,
                showZapperLink: true,
                showCopyBtn: true
              }}
            />
          </div>
        }
        actions={<TimeFilter defaultValue={TimeOptions['1D']} />}>
        <Flex direction={['row', 'column', 'column']} gap="space-m">
          <Box flexBasis={['33%', '100%', '100%']}>
            <KpiCard
              title="Collateral"
              value={<CurrencyValue value={data.collateral_usd} currency="USD" />}
              trendValue={formatNumber(data[`collateral_change_${daysAgo}d_usd`])}
            />
          </Box>
          <Box flexBasis={['33%', '100%', '100%']}>
            <KpiCard
              title="Borrow"
              value={<CurrencyValue value={data.borrow_usd} currency="USD" />}
              trendValue={formatNumber(data[`borrow_change_${daysAgo}d_usd`])}
            />
          </Box>
          <Box flexBasis={['33%', '100%', '100%']}>
            <KpiCard
              title="Supply"
              value={<CurrencyValue value={data.supply_usd} currency="USD" />}
              trendValue={formatNumber(data[`supply_change_${daysAgo}d_usd`])}
            />
          </Box>
        </Flex>
        <PoolsTable walletAddress={address} />
        <ActivityHeatmap walletAddress={address} />
        <EventsTable walletAddress={address} />
      </Page>
    );
  }
  return <PageLoader />;
}
