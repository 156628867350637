import styles from './ExternalLinkButton.module.scss';

export default function ExternalLinkButton({ children, href, ...rest }) {
  const handleAClick = (event) => {
    event.stopPropagation();
  };
  return (
    <a
      href={href}
      onClick={handleAClick}
      target="_blank"
      rel="noopener noreferrer"
      className={styles.btn}
      {...rest}>
      {children}
    </a>
  );
}
