import { Link } from '@/components';
import CurrencyValue from '@/components/CurrencyValue/CurrencyValue';
import RelativeDateTime from '@/components/RelativeDateTime/RelativeDateTime';
import WalletAddress from '@/components/WalletAddress/WalletAddress';
import TableLoader from '@/components/loaders/TableLoader';
import Table from '@/components/table/Table';

export default function DepositorsTable({ tableProps }) {
  const { data, sorting, pagination, daysAgo } = tableProps;
  if (data) {
    const columns = [
      {
        header: 'WALLET',
        getCell: (row) => (
          <WalletAddress
            options={{
              size: 'xSmall'
            }}
            address={row.wallet_address}
          />
        ),
        sortingField: 'wallet_address',
        cellAlign: 'flex-start'
      },
      {
        header: 'BALANCE USD',
        getCell: (row) => (
          <CurrencyValue value={row.balance_asset_usd} currency={'USD'} />
        ),
        sortingField: 'balance_asset_usd',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      },
      {
        header: 'BALANCE',
        getCell: (row) => (
          <CurrencyValue value={row.balance} currency={row.symbol} iconSize="small" />
        ),
        getCellBottom: (row) => (
          <CurrencyValue
            value={row.balance_asset}
            currency={row.asset_symbol}
            iconSize="small"
          />
        ),
        sortingField: 'balance',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      },
      {
        header: `CHANGE ${daysAgo}d`,
        getCell: (row) => <CurrencyValue value={row[`change_${daysAgo}d`]} trend />,
        sortingField: `change_${daysAgo}d`,
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      },
      {
        header: 'TIME',
        getCell: (row) => <RelativeDateTime value={row.datetime} />,
        sortingField: 'order_index',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      }
    ];
    return (
      <Table
        rows={data['results']}
        columns={columns}
        getKey={(row) => row.wallet_address}
        getLinkWrapper={(row) => <Link to={`${row.wallet_address}`} />}
        sorting={sorting}
        pagination={{
          ...pagination,
          totalRecords: data.count
        }}
      />
    );
  }
  return <TableLoader />;
}
