import { Link } from '@/components';
import styles from './LinkButton.module.scss';

export default function LinkButton({ children, ...rest }) {
  return (
    <Link className={styles.btn} {...rest}>
      {children}
    </Link>
  );
}
