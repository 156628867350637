import { useApi } from '@/api';
import CurrencyValue from '@/components/CurrencyValue/CurrencyValue';
import PoolName from '@/components/PoolName/PoolName';
import RelativeDateTime from '@/components/RelativeDateTime/RelativeDateTime';
import WalletAddress from '@/components/WalletAddress/WalletAddress';
import TableLoader from '@/components/loaders/TableLoader';
import Table from '@/components/table/Table';
import { EtherScanPictogram, Flex, useTableProps } from '@blockanalitica/ui';

export default function LiquidationsEventTable({ daysAgo }) {
  const { sorting, pagination } = useTableProps({
    pageSize: 15,
    initialSorting: '-order_index',
    filtersDebounceTime: 50
  });
  const [data, error] = useApi({
    url: '/liquidations/events/',
    queryParams: {
      p: pagination.currentPage,
      p_size: pagination.pageSize,
      order: sorting.currentSorting,
      days_ago: daysAgo
    }
  });

  if (error) {
    throw error;
  }

  if (data) {
    const columns = [
      {
        header: 'MARKET',
        getCell: (row) => (
          <PoolName
            loanSymbol={row.loan_token_symbol}
            collateralSymbol={row.collateral_token_symbol}
            lltv={row.lltv}
          />
        )
      },
      {
        header: 'BORROWER',
        getCell: (row) => (
          <WalletAddress
            options={{
              size: 'xSmall'
            }}
            address={row.wallet_address}
          />
        )
      },
      {
        header: 'COLLATERAL',
        getCell: (row) => (
          <CurrencyValue
            value={row.seized_assets}
            currency={row.collateral_token_symbol}
            iconSize="small"
          />
        ),
        getCellBottom: (row) => (
          <CurrencyValue
            value={row.seized_assets_usd}
            currency="USD"
            className="text-muted"
          />
        ),
        sortingField: 'seized_assets_usd',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      },
      {
        header: 'DEBT',
        getCell: (row) => (
          <CurrencyValue
            value={row.repaid_assets}
            currency={row.loan_token_symbol}
            iconSize="small"
          />
        ),
        getCellBottom: (row) => (
          <CurrencyValue value={row.repaid_assets_usd} currency={'USD'} />
        ),
        sortingField: 'repaid_assets_usd',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      },
      {
        header: 'LIQUIDATOR',
        getCell: (row) => (
          <WalletAddress
            options={{
              size: 'xSmall'
            }}
            address={row.caller}
          />
        )
      },
      {
        header: 'TIME',
        getCell: (row) => <RelativeDateTime value={row.datetime} />,
        getCellBottom: (row) => (
          <Flex gap="space-3xs" className="text-muted">
            {row.block_number}
            <a
              href={`https://etherscan.io/tx/${row.tx_hash}`}
              target="_blank"
              rel="noreferrer">
              <EtherScanPictogram size={14} />
            </a>
          </Flex>
        ),
        sortingField: 'order_index',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      }
    ];
    return (
      <Table
        rows={data['results']}
        columns={columns}
        sorting={sorting}
        pagination={{
          ...pagination,
          totalRecords: data.count
        }}
        getKey={(row) => row.order_index}
        // getLinkWrapper={(row) => <Link to={`/metamorpho/vaults/${row.address}`} />}
      />
    );
  }
  return <TableLoader />;
}
