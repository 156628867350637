import { formatNumber } from '@blockanalitica/ui';
import classnames from 'classnames';
import styles from './HealthRate.module.scss';

export default function HealthRate({ value }) {
  const hr = Number(value);

  if (hr > 0) {
    return (
      <span
        className={classnames({
          [styles.belowOne]: hr < 1,
          [styles.closeToOne]: hr >= 1 && hr < 1.05
        })}>
        {formatNumber(hr)}
      </span>
    );
  }
  return '-';
}
