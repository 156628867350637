import { Select } from '@blockanalitica/ui';
import { useMemo } from 'react';

const DATES = {
  7: '7D',
  30: '30D',
  90: '90D'
};

export default function TimeSelector({ activeOption, onChange }) {
  const selectFilterOptions = useMemo(
    () =>
      Object.entries(DATES).map(([value, label]) => ({
        label,
        value,
        selected: parseInt(value) === parseInt(activeOption)
      })),
    [activeOption]
  );

  return (
    <Select
      options={selectFilterOptions}
      onChange={onChange}
      style={{
        padding: 'var(--space-2xs) var(--space-l) var(--space-2xs) var(--space-xs)',
        background: 'var(--color-onSurface200)',
        color: 'var(--color-text)',
        border: 'none',
        fontSize: 'var(--size--1)'
      }}
    />
  );
}
