import { useApi } from '@/api';
import { CardList } from '@/components';
import CurrencyValue from '@/components/CurrencyValue/CurrencyValue';
import RelativeDateTime from '@/components/RelativeDateTime/RelativeDateTime';
import WalletAddress from '@/components/WalletAddress/WalletAddress';
import { EmptyBox } from '@/components/empty';
import { EventName } from '@/components/events';
import { LinkButton } from '@/components/forms';
import { CardLoader, Flex, List } from '@blockanalitica/ui';

export default function LatestVaultEvents({ vaultAddress }) {
  let [data, error] = useApi({
    url: `/vaults/${vaultAddress}/events/`,
    queryParams: {
      p_size: 5,
      order: '-datetime'
    }
  });

  if (error) {
    if (error.status === 404) {
      data = { results: [] };
    } else {
      throw new Error(error);
    }
  }

  if (data) {
    let content = undefined;
    if (data['results'].length > 0) {
      content = (
        <List>
          {data['results'].map((row) => (
            <Flex
              justifyContent="space-between"
              alignItems="center"
              key={row.order_index}>
              <WalletAddress
                options={{
                  size: 'xSmall'
                }}
                address={row.wallet_address}
              />
              <span className="text-2">
                <EventName event={row.event} />
              </span>
              <Flex direction="column" gap="space-3xs" alignItems="flex-end">
                <CurrencyValue
                  value={row.assets}
                  currency={row.loan_token_symbol}
                  iconSize="small"
                />
                <span className="text-2 text-muted">
                  <RelativeDateTime value={row.datetime} />
                </span>
              </Flex>
            </Flex>
          ))}
        </List>
      );
    } else {
      content = <EmptyBox />;
    }
    return (
      <CardList
        style={{ width: '100%' }}
        title="Latest Vault Events"
        actions={<LinkButton to="events">view all</LinkButton>}>
        {content}
      </CardList>
    );
  }

  return <CardLoader headerHeight="3rem" bodyHeight="7rem" />;
}
