import { Flex, ColumnsLoader } from '@blockanalitica/ui';

export default function TableLoader() {
  return (
    <Flex gap="space-xs" direction="column">
      <ColumnsLoader elements={3} columnHeight="2rem" />
      <ColumnsLoader elements={3} columnHeight="2rem" />
      <ColumnsLoader elements={3} columnHeight="2rem" />
    </Flex>
  );
}
