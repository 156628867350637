import {
  formatDatetime,
  Tooltip,
  TooltipTrigger,
  TooltipContent
} from '@blockanalitica/ui';

export default function RelativeDateTime({ value }) {
  return (
    <Tooltip>
      <TooltipTrigger>{formatDatetime(value, { toRelative: true })}</TooltipTrigger>
      <TooltipContent>{formatDatetime(value, { showTime: true })}</TooltipContent>
    </Tooltip>
  );
}
