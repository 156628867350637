import { ThemeProvider } from '@blockanalitica/ui';
import { RouterProvider } from 'react-router-dom';
import router from './router';
import './theme/index.scss';
import { initialize } from '@blockanalitica/ui';

initialize({ intl: { timezone: 'UTC' } });

export default function App() {
  return (
    <ThemeProvider>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}
