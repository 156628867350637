import { KpiCard as KpiCardUI } from '@blockanalitica/ui';

export default function KpiCard({ style, ...rest }) {
  return (
    <KpiCardUI
      style={{ boxShadow: '0 0 10px var(--color-shadow)', border: 'none', ...style }}
      {...rest}
    />
  );
}
