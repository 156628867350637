export default function MorphoLogoWhite() {
  return (
    <svg
      width="162"
      height="60"
      viewBox="0 0 352 71"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.64062 47.123V66.9881C8.64062 68.211 9.67558 68.7186 9.99756 68.8339C10.3195 68.9723 11.4005 69.2723 12.3664 68.3725L27.3944 53.9307C28.6741 52.701 29.9093 51.4083 30.8374 49.8958C31.2743 49.1847 31.4554 48.7842 31.4554 48.7842C32.3755 46.9154 32.3755 45.1158 31.4782 43.3161C30.1448 40.6398 26.7407 37.9172 21.6117 35.3331L12.8494 40.2245C10.2506 41.7011 8.64062 44.3083 8.64062 47.123Z"
        fill="#CCCCCC"
      />
      <path
        d="M0 2.94003V23.7742C0 26.3813 1.74793 28.6885 4.23183 29.4268C12.6953 31.8725 27.4375 37.1329 31.0024 44.8621C31.462 45.8772 31.7381 46.8694 31.8307 47.9076C34.1994 43.5931 35.2802 38.6326 34.7743 33.6029C34.0841 26.4736 30.3121 19.9903 24.4248 15.8604L3.42685 1.16347C3.05883 0.886606 2.62186 0.748169 2.1849 0.748169C1.81688 0.748169 1.4949 0.817388 1.14994 1.00197C0.459945 1.39419 0 2.10944 0 2.94003Z"
        fill="white"
      />
      <path
        d="M64.5349 47.123V66.9881C64.5349 68.211 63.5004 68.7186 63.178 68.8339C62.8564 68.9723 61.7747 69.2723 60.8092 68.3725L45.4314 53.5948C44.3847 52.5887 43.3851 51.5247 42.5966 50.3054C41.9721 49.3403 41.7204 48.7842 41.7204 48.7842C40.8003 46.9154 40.8003 45.1158 41.6968 43.3161C43.031 40.6398 46.4351 37.9172 51.5632 35.3331L60.3261 40.2245C62.9482 41.7011 64.5349 44.3083 64.5349 47.123Z"
        fill="#CCCCCC"
      />
      <path
        d="M73.1899 2.93832V23.7724C73.1899 26.3796 71.4416 28.6868 68.9575 29.4251C60.4943 31.8707 45.7524 37.1312 42.1875 44.8604C41.727 45.8756 41.4509 46.8677 41.3592 47.9059C38.9904 43.5915 37.9096 38.6309 38.4155 33.6012C39.1049 26.4718 42.8769 19.9886 48.7651 15.8587L69.7631 1.16176C70.1309 0.884889 70.5678 0.74646 71.0047 0.74646C71.3725 0.74646 71.6949 0.815678 72.0392 1.00026C72.7295 1.39248 73.1899 2.10772 73.1899 2.93832Z"
        fill="white"
      />
      <path
        d="M108.813 7.44067H116.852L135.482 50.5312H136.034L154.664 7.44067H162.564V57.1207H156.699V17.5837H156.147L139.07 57.1207H132.308L115.23 17.5837H114.678V57.1207H108.813V7.44067Z"
        fill="white"
      />
      <path
        d="M169.042 39.5602C169.042 27.6922 176.253 21.1372 186.948 21.1372C197.608 21.1372 204.853 27.6922 204.853 39.5602C204.853 51.3937 197.608 57.9487 186.948 57.9487C176.253 57.9487 169.042 51.3937 169.042 39.5602ZM175.183 39.5602C175.183 48.2542 179.91 52.9807 186.948 52.9807C193.951 52.9807 198.678 48.2542 198.678 39.5602C198.678 30.8317 193.951 26.1397 186.948 26.1397C179.91 26.1397 175.183 30.8317 175.183 39.5602Z"
        fill="white"
      />
      <path
        d="M210.652 21.9652H216.517V26.1742H217.069C218.277 23.6902 220.485 21.8272 225.763 21.8272H230.662V27.0712H225.901C219.312 27.0712 216.724 30.6937 216.724 37.8352V57.1207H210.652V21.9652Z"
        fill="white"
      />
      <path
        d="M235.333 70.9207V21.9652H241.267V26.8297H241.819C244.441 22.7587 248.719 21.1717 253.687 21.1717C262.829 21.1717 269.833 27.3817 269.833 39.5602C269.833 51.7042 262.829 57.9142 253.687 57.9142C248.822 57.9142 244.579 56.3617 241.957 52.4632H241.405V70.9207H235.333ZM241.198 39.5602C241.198 48.0817 245.752 52.8772 252.445 52.8772C259.172 52.8772 263.727 48.0817 263.727 39.5602C263.727 31.0042 259.172 26.2087 252.445 26.2087C245.752 26.2087 241.198 31.0042 241.198 39.5602Z"
        fill="white"
      />
      <path
        d="M275.647 7.44067H281.719V26.3122H282.271C284.789 22.5862 288.964 21.1717 293.897 21.1717C302.074 21.1717 307.249 25.3117 307.249 33.9022V57.1207H301.177V34.4887C301.177 28.7617 298.037 26.2432 292.552 26.2432C286.549 26.2432 281.719 29.7967 281.719 37.4902V57.1207H275.647V7.44067Z"
        fill="white"
      />
      <path
        d="M312.711 39.5602C312.711 27.6922 319.921 21.1372 330.616 21.1372C341.277 21.1372 348.522 27.6922 348.522 39.5602C348.522 51.3937 341.277 57.9487 330.616 57.9487C319.921 57.9487 312.711 51.3937 312.711 39.5602ZM318.852 39.5602C318.852 48.2542 323.578 52.9807 330.616 52.9807C337.62 52.9807 342.346 48.2542 342.346 39.5602C342.346 30.8317 337.62 26.1397 330.616 26.1397C323.578 26.1397 318.852 30.8317 318.852 39.5602Z"
        fill="white"
      />
    </svg>
  );
}
