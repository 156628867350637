import { useApi } from '@/api';
import { Page } from '@/components';
import CurrencyValue from '@/components/CurrencyValue/CurrencyValue';

import { Search, TimeFilter, TimeOptions } from '@/components/global';
import KpiCard from '@/components/kpi/KpiCard';
import { useSearchParams } from '@/hooks';
import { Box, CryptoIcon, Flex, useIsMobile } from '@blockanalitica/ui';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import FeaturedVaults from './FeaturedVaults';
import HistoricTabs from './HistoricTabs';
import PoolsTable from './PoolsTable';

export default function Overall() {
  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const network = pathSegments[1];
  const [daysAgo] = useSearchParams('days_ago', 1);
  const isMobile = useIsMobile();
  const [data, error] = useApi({
    url: `/overall/`,
    queryParams: {
      days_ago: daysAgo
    }
  });

  if (error) {
    throw error;
  }

  if (data) {
    const mapping = {
      ethereum: 'ETH',
      base: 'BASE'
    };
    return (
      <Page
        title={
          <Flex gap="space-xs" alignItems="center">
            <CryptoIcon name={mapping[network]} size={30} />
            <h1>{_.capitalize(network)}</h1>
          </Flex>
        }
        actions={
          !isMobile && (
            <Flex
              gap="space-m"
              justifyContent={['flex-end', 'flex-end', 'space-between']}>
              <Search />
              <TimeFilter defaultValue={TimeOptions['1D']} />
            </Flex>
          )
        }>
        {isMobile && (
          <Flex gap="space-m" justifyContent={['space-between']}>
            <Search />
            <TimeFilter defaultValue={TimeOptions['1D']} />
          </Flex>
        )}

        <Flex direction={['row', 'column', 'column']} gap="space-xs">
          <Box flexBasis={['67%', '100%', '100%']}>
            <KpiCard
              title="Total Deposits"
              value={
                <CurrencyValue
                  value={data.total_deposits}
                  currency={'USD'}
                  notation="standard"
                />
              }
              trendValue={
                <CurrencyValue
                  value={data.total_deposits - data.previous_total_deposits}
                  currency={'USD'}
                  trend
                />
              }
              options={{
                valueSize: 'text-6',
                trendValueSize: 'text-3',
                trendValueIcon: true,
                footerSize: 'text-2',
                trendValueFormat: 'currency'
              }}
            />
          </Box>

          <Box flexBasis={['33%', '100%', '100%']}>
            <KpiCard
              title="Total Value Locked"
              value={<CurrencyValue value={data.tvl} currency={'USD'} />}
              trendValue={
                <CurrencyValue
                  value={data.tvl - data.previous_tvl}
                  currency={'USD'}
                  trend
                />
              }
              options={{
                valueSize: 'text-6',
                trendValueSize: 'text-3',
                trendValueIcon: true,
                footerSize: 'text-2',
                trendValueFormat: 'currency'
              }}
            />
          </Box>
        </Flex>
        <Flex direction={['row', 'column', 'column']} gap="space-xs">
          <Box flexBasis={['67%', '100%', '100%']}>
            <HistoricTabs />
          </Box>

          <Flex flexBasis={['33%', '100%', '100%']} direction="column" gap="space-xs">
            <KpiCard
              title="Total Borrow"
              value={<CurrencyValue value={data.total_borrow} currency={'USD'} />}
              trendValue={
                <CurrencyValue
                  value={data.total_borrow - data.previous_total_borrow}
                  currency={'USD'}
                  trend
                />
              }
              options={{
                valueSize: 'text-5',
                trendValueSize: 'text-3',
                trendValueIcon: true,
                footerSize: 'text-2',
                trendValueFormat: 'currency'
              }}
            />
            <KpiCard
              title="Total Supply"
              value={<CurrencyValue value={data.total_supply} currency={'USD'} />}
              trendValue={
                <CurrencyValue
                  value={data.total_supply - data.previous_total_supply}
                  currency={'USD'}
                  trend
                />
              }
              options={{
                valueSize: 'text-5',
                trendValueSize: 'text-3',
                trendValueIcon: true,
                footerSize: 'text-2',
                trendValueFormat: 'currency'
              }}
            />

            <KpiCard
              style={{ flex: '1' }}
              title="Total Collateral"
              value={<CurrencyValue value={data.total_collateral} currency="USD" />}
              trendValue={
                <CurrencyValue
                  value={data.total_collateral - data.previous_total_collateral}
                  currency={'USD'}
                  trend
                />
              }
              options={{
                valueSize: 'text-5',
                trendValueSize: 'text-3',
                trendValueIcon: true,
                footerSize: 'text-2',
                trendValueFormat: 'currency'
              }}
            />

            {/* <KpiCard
              title="Daily Volume"
              value={<CurrencyValue value={data.volume} currency={'USD'} />}
              options={{
                valueSize: 'text-5',
                trendValueSize: 'text-3',
                trendValueIcon: true,
                footerSize: 'text-2',
                trendValueFormat: 'currency'
              }}
            /> */}
          </Flex>
        </Flex>
        <FeaturedVaults />
        <PoolsTable daysAgo={daysAgo} />
        {/* <TopVaultsTable daysAgo={daysAgo} /> */}
        {/* <TokensTable /> */}
      </Page>
    );
  }

  return <></>;
}
