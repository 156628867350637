import Svg from './Svg';

export default function VaultIcon({ size = 32, color = 'currentColor', ...props }) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 14 14"
      width={size + 'px'}
      height={size + 'px'}
      {...props}>
      <g id="safe-vault--saving-combo-payment-safe-combination-finance">
        <path
          id="Vector"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M11.607142857142858 0.4642857142857143h-10.214285714285715c-0.5128360714285715 0 -0.9285714285714286 0.41573535714285714 -0.9285714285714286 0.9285714285714286V10.214285714285715c0 0.51285 0.41573535714285714 0.9285714285714286 0.9285714285714286 0.9285714285714286h10.214285714285715c0.51285 0 0.9285714285714286 -0.4157214285714286 0.9285714285714286 -0.9285714285714286V1.3928571428571428c0 -0.5128360714285715 -0.4157214285714286 -0.9285714285714286 -0.9285714285714286 -0.9285714285714286Z"
          strokeWidth={1}
        />
        <path
          id="Vector_2"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M7.892857142857143 7.428571428571429c0.8974642857142857 0 1.625 -0.7275357142857143 1.625 -1.625S8.79032142857143 4.178571428571429 7.892857142857143 4.178571428571429s-1.625 0.7275357142857143 -1.625 1.625S6.995392857142858 7.428571428571429 7.892857142857143 7.428571428571429Z"
          strokeWidth={1}
        />
        <path
          id="Vector_3"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M7.892857142857143 3.017857142857143V4.178571428571429"
          strokeWidth={1}
        />
        <path
          id="Vector_4"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M7.892857142857143 7.428571428571429v1.1607142857142858"
          strokeWidth={1}
        />
        <path
          id="Vector_5"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M10.678571428571429 5.803571428571429h-1.1607142857142858"
          strokeWidth={1}
        />
        <path
          id="Vector_6"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M6.267857142857143 5.803571428571429H5.107142857142858"
          strokeWidth={1}
        />
        <path
          id="Vector_7"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="m9.86142857142857 3.8348885714285714 -0.8171521428571429 0.8171428571428572"
          strokeWidth={1}
        />
        <path
          id="Vector_8"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="m6.741428571428571 6.954990714285715 -0.8171428571428572 0.8171428571428572"
          strokeWidth={1}
        />
        <path
          id="Vector_9"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="m9.86142857142857 7.772133571428571 -0.8171521428571429 -0.8171428571428572"
          strokeWidth={1}
        />
        <path
          id="Vector_10"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="m6.741428571428571 4.652031428571429 -0.8171428571428572 -0.8171428571428572"
          strokeWidth={1}
        />
        <path
          id="Vector_11"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M2.7857142857142856 4.178571428571429V7.428571428571429"
          strokeWidth={1}
        />
        <path
          id="Vector_12"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M1.8571428571428572 11.142857142857142v1.3928571428571428"
          strokeWidth={1}
        />
        <path
          id="Vector_13"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M10.678571428571429 11.142857142857142v1.3928571428571428"
          strokeWidth={1}
        />
      </g>
    </Svg>
  );
}
