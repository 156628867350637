import { useApi } from '@/api';
import { Link } from '@/components';
import CurrencyValue from '@/components/CurrencyValue/CurrencyValue';
import PoolName from '@/components/PoolName/PoolName';
import TableLoader from '@/components/loaders/TableLoader';
import Table from '@/components/table/Table';
import {
  CurrencyFilter,
  Flex,
  TrendValue,
  formatPercentage,
  useTableProps
} from '@blockanalitica/ui';

export default function MarketTable({ vaultAddress, daysAgo }) {
  const { filters, sorting, pagination } = useTableProps({
    pageSize: 15,
    initialSorting: '-total_supply_usd',
    initialFilters: { currency: 'token' },
    filtersDebounceTime: 50
  });
  const [data, error] = useApi({
    url: `/vaults/${vaultAddress}/markets/`,
    queryParams: {
      p: pagination.currentPage,
      p_size: pagination.pageSize,
      order: sorting.currentSorting,
      days_ago: daysAgo
    }
  });

  if (error) {
    throw error;
  }

  if (data) {
    const columns = [
      {
        header: 'MARKET',
        getCell: (row) => (
          <PoolName
            loanSymbol={row.loan_token_symbol}
            collateralSymbol={row.collateral_token_symbol}
            lltv={row.lltv}
          />
        )
      },
      {
        header: 'LIQUIDITY',
        getCell: (row) => (
          <>
            {filters.filtersApplied.currency === 'USD' ? (
              <CurrencyValue value={row.liquidity_usd} currency="USD" />
            ) : (
              <CurrencyValue
                value={row.liquidity}
                currency={row.loan_token_symbol}
                iconSize="small"
              />
            )}
          </>
        ),
        getCellBottom: (row) => (
          <>
            {filters.filtersApplied.currency === 'USD' ? (
              <CurrencyValue
                value={row.liquidity_usd - row.prev_liquidity_usd}
                currency="USD"
                trend
              />
            ) : (
              <CurrencyValue
                value={row.liquidity - row.prev_liquidity}
                currency={row.loan_token_symbol}
                iconSize="small"
                trend
              />
            )}
          </>
        ),
        sortingField: 'liquidity_usd',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      },
      {
        header: 'SUPPLY',
        getCell: (row) => (
          <>
            {filters.filtersApplied.currency === 'USD' ? (
              <CurrencyValue value={row.total_supply_usd} currency="USD" />
            ) : (
              <CurrencyValue
                value={row.total_supply}
                currency={row.loan_token_symbol}
                iconSize="small"
              />
            )}
          </>
        ),
        getCellBottom: (row) => (
          <>
            {filters.filtersApplied.currency === 'USD' ? (
              <CurrencyValue
                value={row.total_supply_usd - row.prev_total_supply_usd}
                currency="USD"
                trend
              />
            ) : (
              <CurrencyValue
                value={row.total_supply - row.prev_total_supply}
                currency={row.loan_token_symbol}
                iconSize="small"
                trend
              />
            )}
          </>
        ),
        sortingField: 'total_supply_usd',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      },
      {
        header: 'BORROW',
        getCell: (row) => (
          <>
            {row.collateral_token_symbol ? (
              <>
                {filters.filtersApplied.currency === 'USD' ? (
                  <CurrencyValue value={row.total_borrow_usd} currency="USD" />
                ) : (
                  <>
                    <CurrencyValue
                      value={row.total_borrow}
                      currency={row.loan_token_symbol}
                      iconSize="small"
                    />
                  </>
                )}
              </>
            ) : (
              '-'
            )}
          </>
        ),
        getCellBottom: (row) => (
          <>
            {filters.filtersApplied.currency === 'USD' ? (
              <CurrencyValue
                value={row.total_borrow_usd - row.prev_total_borrow_usd}
                currency="USD"
                trend
              />
            ) : (
              <CurrencyValue
                value={row.total_borrow - row.prev_total_borrow}
                currency={row.loan_token_symbol}
                iconSize="small"
                trend
              />
            )}
          </>
        ),
        sortingField: 'total_borrow_usd',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      },
      {
        header: 'SUPPLY APY',
        getCell: (row) =>
          row.collateral_token_symbol ? formatPercentage(row.supply_rate_apy) : '-',
        getCellBottom: (row) => (
          <TrendValue
            value={formatPercentage(row.supply_rate_apy - row.prev_supply_rate_apy)}
          />
        ),
        sortingField: 'supply_rate_apy',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      },
      {
        header: 'NET APY',
        getCell: (row) =>
          row.collateral_token_symbol ? formatPercentage(row.total_rate_apy) : '-',
        getCellBottom: (row) => (
          <TrendValue
            value={formatPercentage(row.total_rate_apy - row.prev_total_rate_apy)}
          />
        ),
        sortingField: 'total_rate_apy',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      },
      {
        header: 'BORROW APY',
        getCell: (row) =>
          row.collateral_token_symbol ? formatPercentage(row.borrow_rate_apy) : '-',
        getCellBottom: (row) => (
          <TrendValue
            value={formatPercentage(row.borrow_rate_apy - row.prev_borrow_rate_apy)}
          />
        ),
        sortingField: 'borrow_rate_apy',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      },
      {
        header: 'UTILIZATION',
        getCell: (row) =>
          row.collateral_token_symbol ? formatPercentage(row.utilization) : '-',
        getCellBottom: (row) => (
          <TrendValue
            value={formatPercentage(row.utilization - row.prev_utilization)}
          />
        ),
        sortingField: 'borrow_rate_apy',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      }
    ];

    return (
      <>
        <Flex justifyContent="space-between" alignItems="center">
          <h4>Markets</h4>
          <CurrencyFilter
            key="currency-filter"
            width="8rem"
            currency2={{ value: 'token', displayValue: 'TOKEN' }}
            currency1={{ value: 'USD', displayValue: 'USD' }}
            {...filters}
          />
        </Flex>
        <Table
          rows={data['results']}
          columns={columns}
          getKey={(row) => row.uid}
          getLinkWrapper={(row) => <Link to={`/markets/${row.uid}`} />}
          sorting={sorting}
          pagination={{
            ...pagination,
            totalRecords: data.count
          }}
        />
      </>
    );
  }
  return <TableLoader />;
}
