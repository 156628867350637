import Svg from './Svg';

export default function WalletIcon({ size = 32, color = 'currentColor', ...props }) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="-0.5 -0.5 14 14"
      width={size + 'px'}
      height={size + 'px'}
      {...props}>
      <g id="wallet--money-payment-finance-wallet">
        <path
          id="Vector"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M11.142857142857142 9.75v1.8571428571428572c0 0.24625714285714287 -0.09787142857142857 0.48248571428571424 -0.27197857142857146 0.6565928571428571S10.460542857142858 12.535714285714286 10.214285714285715 12.535714285714286H1.3928571428571428c-0.2462757142857143 0 -0.48245785714285716 -0.09787142857142857 -0.6565993571428572 -0.27197857142857146C0.5621172142857144 12.089628571428573 0.4642857142857143 11.8534 0.4642857142857143 11.607142857142858V4.642857142857143c0 -1.5385035714285715 1.2472107142857143 -2.7857142857142856 2.7857142857142856 -2.7857142857142856H9.285714285714286v2.3214285714285716"
          strokeWidth={1}
        />
        <path
          id="Vector_2"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12.071428571428571 6.964285714285714H8.821428571428571c-0.2564157142857143 0 -0.4642857142857143 0.20787 -0.4642857142857143 0.4642857142857143v1.8571428571428572c0 0.25637857142857146 0.20787 0.4642857142857143 0.4642857142857143 0.4642857142857143H12.071428571428571c0.25637857142857146 0 0.4642857142857143 -0.20790714285714285 0.4642857142857143 -0.4642857142857143V7.428571428571429c0 -0.2564157142857143 -0.20790714285714285 -0.4642857142857143 -0.4642857142857143 -0.4642857142857143Z"
          strokeWidth={1}
        />
        <path
          id="Vector_3"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M11.142857142857142 6.964285714285714v-1.8571428571428572c0 -0.2462757142857143 -0.09787142857142857 -0.48245785714285716 -0.27197857142857146 -0.6566021428571429C10.696771428571429 4.276405714285715 10.460542857142858 4.178571428571429 10.214285714285715 4.178571428571429H3.25"
          strokeWidth={1}
        />
      </g>
    </Svg>
  );
}
