import Svg from './Svg';

export default function BillsIcon({ size = 32, color = 'currentColor', ...props }) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="-0.5 -0.5 14 14"
      width={size + 'px'}
      height={size + 'px'}
      {...props}>
      <g id="bill-1--billing-bills-payment-finance-cash-currency-money-accounting">
        <path
          id="Vector"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M11.607142857142858 0.4642857142857143h-10.214285714285715c-0.5128360714285715 0 -0.9285714285714286 0.41573535714285714 -0.9285714285714286 0.9285714285714286v5.571428571428571c0 0.5128314285714286 0.41573535714285714 0.9285714285714286 0.9285714285714286 0.9285714285714286h10.214285714285715c0.51285 0 0.9285714285714286 -0.41574 0.9285714285714286 -0.9285714285714286v-5.571428571428571c0 -0.5128360714285715 -0.4157214285714286 -0.9285714285714286 -0.9285714285714286 -0.9285714285714286Z"
          strokeWidth="1"></path>
        <path
          id="Vector_2"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M6.5 5.571428571428571c0.7692564285714286 0 1.3928571428571428 -0.6236007142857143 1.3928571428571428 -1.3928571428571428S7.2692564285714285 2.7857142857142856 6.5 2.7857142857142856s-1.3928571428571428 0.6236007142857143 -1.3928571428571428 1.3928571428571428S5.7307435714285715 5.571428571428571 6.5 5.571428571428571Z"
          strokeWidth="1"></path>
        <path
          id="Vector_3"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M1.3928571428571428 10.214285714285715h10.214285714285715"
          strokeWidth="1"></path>
        <path
          id="Vector_4"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M2.3214285714285716 12.535714285714286h8.357142857142858"
          strokeWidth="1"></path>
        <g id="Group 8">
          <path
            id="Ellipse 1"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M2.5766928571428576 4.410714285714286c-0.12820785714285715 0 -0.23214285714285715 -0.103935 -0.23214285714285715 -0.23214285714285715s0.103935 -0.23214285714285715 0.23214285714285715 -0.23214285714285715"
            strokeWidth="1"></path>
          <path
            id="Ellipse 2"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M2.5766928571428576 4.410714285714286c0.12820785714285715 0 0.23214285714285715 -0.103935 0.23214285714285715 -0.23214285714285715s-0.103935 -0.23214285714285715 -0.23214285714285715 -0.23214285714285715"
            strokeWidth="1"></path>
        </g>
        <g id="Group 9">
          <path
            id="Ellipse 1_2"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M10.423307142857142 4.410714285714286c-0.1282357142857143 0 -0.23214285714285715 -0.103935 -0.23214285714285715 -0.23214285714285715s0.10390714285714286 -0.23214285714285715 0.23214285714285715 -0.23214285714285715"
            strokeWidth="1"></path>
          <path
            id="Ellipse 2_2"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M10.423307142857142 4.410714285714286c0.1282357142857143 0 0.23214285714285715 -0.103935 0.23214285714285715 -0.23214285714285715s-0.10390714285714286 -0.23214285714285715 -0.23214285714285715 -0.23214285714285715"
            strokeWidth="1"></path>
        </g>
      </g>
    </Svg>
  );
}
