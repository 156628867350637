import BALogo from '@/assets/logos/BA-logo-full-white.svg?react';
import BPLogo from '@/assets/logos/BProtocol.svg?react';
import { Link } from '@/components';
import { VaultName } from '@/components/vault';
import { Flex, formatPercentage } from '@blockanalitica/ui';
import Card from '../Card/Card';
import CurrencyValue from '../CurrencyValue/CurrencyValue';
import styles from './VaultCard.module.scss';

export default function VaultCard({ data, style, smallBox = false }) {
  return (
    <Link to={`/vaults/vaults/${data.address}`} style={{ ...style }}>
      <Card style={{ fontSize: 'initial' }}>
        <Flex direction="column" gap="space-s" className={styles.content}>
          <Flex
            direction={smallBox ? 'column' : ['row', 'column', 'column']}
            justifyContent="space-between"
            gap="space-s">
            <VaultName
              assetSymbol={data.asset_symbol}
              symbol={data.symbol}
              name={data.name}
              iconSize={40}
            />
            <Flex justifyContent="space-between" gap="space-m">
              <Flex direction="column" gap="space-3xs" flexBasis="33%">
                <span className="text-muted text-2">Deposits</span>
                <span className="text-3">
                  <CurrencyValue value={data.tvl} currency="USD" />
                </span>
              </Flex>
              <Flex direction="column" gap="space-3xs">
                <span className="text-muted text-2">APY</span>
                <Flex gap="space-2xs" className="text-3">
                  {formatPercentage(data.total_rate_apy)}
                  <span>+</span>
                  <span>
                    <CurrencyValue
                      value={data.morpho_reward_share}
                      currency={'MORPHO'}
                      iconSize="small"
                    />
                  </span>
                </Flex>
              </Flex>
            </Flex>
          </Flex>

          {data.featured && (
            <Flex
              direction={['row', 'row', 'row']}
              justifyContent="space-between"
              gap={['space-m', 'space-m', 'space-m']}
              style={{ opacity: 0.6 }}>
              <BPLogo height={25} />
              <BALogo height={25} />
            </Flex>
          )}
        </Flex>
      </Card>
    </Link>
  );
}
