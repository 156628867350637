import { useApi } from '@/api';
import { CardList } from '@/components';
import CurrencyValue from '@/components/CurrencyValue/CurrencyValue';
import WalletAddress from '@/components/WalletAddress/WalletAddress';
import { EmptyBox } from '@/components/empty';
import { LinkButton } from '@/components/forms';
import { CardLoader, Flex, List, formatPercentage } from '@blockanalitica/ui';

export default function TopDepositors({ vaultAddress, totalSupply }) {
  const [data, error] = useApi({
    url: `/vaults/${vaultAddress}/depositors/`,
    queryParams: {
      p_size: 5,
      order: '-balance'
    }
  });

  if (error) {
    throw error;
  }

  if (data) {
    let content = undefined;
    if (data['results'].length > 0) {
      content = (
        <List>
          {data['results'].map((item) => (
            <a
              key={item.wallet_address}
              href={`${vaultAddress}/depositors/${item.wallet_address}`}>
              <Flex justifyContent="space-between">
                <WalletAddress
                  options={{
                    size: 'xSmall'
                  }}
                  address={item.wallet_address}
                />
                <Flex direction="column" gap="space-3xs" alignItems="flex-end">
                  <span className="text-2">
                    {formatPercentage(item.balance / totalSupply)}
                  </span>
                  <span className="text-2 text-muted">share</span>
                </Flex>
                <Flex direction="column" gap="space-3xs" alignItems="flex-end">
                  <CurrencyValue
                    value={item.balance_asset}
                    currency={item.asset_symbol}
                    iconSize="small"
                  />
                  <span className="text-2 text-muted">
                    <CurrencyValue value={item.balance_asset_usd} currency="USD" />
                  </span>
                </Flex>
              </Flex>
            </a>
          ))}
        </List>
      );
    } else {
      content = <EmptyBox />;
    }

    return (
      <CardList
        style={{ width: '100%' }}
        title="Top Depositors"
        actions={<LinkButton to="depositors">view all</LinkButton>}>
        {content}
      </CardList>
    );
  }

  return <CardLoader headerHeight="3rem" bodyHeight="7rem" />;
}
