import apiFetcher, { apiFetcherArgs } from '@/api/apiFetcher';
import { useLocation } from 'react-router-dom';
import useSWR from 'swr';

export default function useApi<T>(
  args: apiFetcherArgs
): [T | undefined, Error | undefined] {
  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const key = pathSegments[1];
  if (['ethereum', 'base'].includes(key)) {
    args.queryParams = { ...args.queryParams, network: key };
  }
  const { data, error } = useSWR<T, Error, apiFetcherArgs>(args, apiFetcher);
  return [data, error];
}
