import { useApi } from '@/api';
import { CryptoIcon, Link } from '@/components';
import CurrencyValue from '@/components/CurrencyValue/CurrencyValue';
import PoolName from '@/components/PoolName/PoolName';
import { Card, Flex, textShortener } from '@blockanalitica/ui';
import classnames from 'classnames';
import styles from './SearchResults.module.scss';

export default function SearchResults({ searchTerm }) {
  const [data, error] = useApi({
    url: `/search/`,
    queryParams: {
      search: searchTerm
    }
  });

  if (error) {
    throw error;
  }
  let content = null;
  if (data) {
    const { markets, tokens, vaults } = data;
    content = (
      <Flex direction="column" gap="space-s">
        <Flex direction="column" gap="space-2xs">
          <Flex justifyContent="space-between">
            <h4>Markets</h4>
            <span className="text-2 text-muted">TVL</span>
          </Flex>
          {markets?.length > 0 ? (
            markets.map((row, index) => (
              <Link
                to={`/markets/${row.uid}`}
                key={row.uid}
                className={classnames(styles.itemWrapper, {
                  [styles.noBorder]: index === markets.length - 1
                })}>
                <Flex justifyContent="space-between">
                  <PoolName
                    loanSymbol={row.loan_token_symbol}
                    collateralSymbol={row.collateral_token_symbol}
                    iconSize="small"
                  />
                  <CurrencyValue value={row.tvl} currency="USD" />
                </Flex>
              </Link>
            ))
          ) : (
            <span className="text-2 text-muted">No tokens</span>
          )}
        </Flex>

        <Flex direction="column" gap="space-2xs">
          <Flex justifyContent="space-between">
            <h4>Tokens</h4>
            <span className="text-2 text-muted">TVL</span>
          </Flex>
          {tokens?.length > 0 ? (
            tokens.map((row, index) => (
              <Link
                to={`/tokens/${row.address}`}
                key={row.address}
                className={classnames(styles.itemWrapper, {
                  [styles.noBorder]: index === tokens.length - 1
                })}>
                <Flex justifyContent="space-between">
                  <Flex alignItems="center" gap="space-2xs">
                    <CryptoIcon name={row.symbol} size="small" />
                    {textShortener(row.symbol, {
                      threshold: 8,
                      firstSliceLength: 6,
                      secondSliceLength: 0
                    })}
                  </Flex>
                  <CurrencyValue value={row.tvl} currency="USD" />
                </Flex>
              </Link>
            ))
          ) : (
            <span className="text-2 text-muted">No tokens</span>
          )}
        </Flex>

        <Flex direction="column" gap="space-2xs">
          <Flex justifyContent="space-between">
            <h4>Vaults</h4>
            <span className="text-2 text-muted">TVL</span>
          </Flex>
          {vaults?.length > 0 ? (
            vaults.map((row, index) => (
              <Link
                to={`/vaults/vaults/${row.address}`}
                key={row.address}
                className={classnames(styles.itemWrapper, {
                  [styles.noBorder]: index === vaults.length - 1
                })}>
                <Flex justifyContent="space-between">
                  <Flex alignItems="center" gap="space-2xs">
                    <CryptoIcon name={row.asset_symbol} size="small" />
                    {textShortener(row.symbol, {
                      threshold: 8,
                      firstSliceLength: 6,
                      secondSliceLength: 0
                    })}
                  </Flex>
                  <CurrencyValue value={row.tvl} currency="USD" />
                </Flex>
              </Link>
            ))
          ) : (
            <span className="text-2 text-muted">No tokens</span>
          )}
        </Flex>
      </Flex>
    );
  } else {
    content = <>Loading TODO</>;
  }

  return (
    <div className={styles.container}>
      <Card>{content}</Card>
    </div>
  );
}
