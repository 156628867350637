import Svg from './Svg';

export default function LiquidationIcon({
  size = 32,
  color = 'currentColor',
  ...props
}) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="-0.5 -0.5 14 14"
      width={size + 'px'}
      height={size + 'px'}
      {...props}>
      <g id="justice-hammer--hammer-work-mallet-office-company-gavel-justice-judge-arbitration-court">
        <path
          id="Vector"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M0.4642857142857143 12.340714285714286H7.428571428571429"
          strokeWidth={1}
        />
        <path
          id="Vector_2"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M6.5 12.340714285714286v-2.3214285714285716H1.3928571428571428v2.3214285714285716"
          strokeWidth={1}
        />
        <path
          id="Vector_3"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M7.230516428571429 0.9304750000000002 3.9015507142857144 4.259431428571429c-0.3626257142857143 0.362635 -0.3626257142857143 0.9505692857142858 0 1.3132042857142858l1.4707828571428572 1.4707828571428572c0.362635 0.3626257142857143 0.9505692857142858 0.3626257142857143 1.3132042857142858 0l3.3289192857142855 -3.3289657142857143c0.3627 -0.3626257142857143 0.3627 -0.9505692857142858 0 -1.3131950000000001L8.543711428571429 0.9304750000000002c-0.362635 -0.3626285 -0.9505692857142858 -0.3626285 -1.3131950000000001 0Z"
          strokeWidth={1}
        />
        <path
          id="Vector_4"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M8.357142857142858 5.376465714285715 12.535714285714286 9.555"
          strokeWidth={1}
        />
      </g>
    </Svg>
  );
}
