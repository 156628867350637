import { formatCurrency as uiFormatCurrency, formatNumber } from '@blockanalitica/ui';

export function formatCurreny(value, currency = 'USD') {
  const num = Number(value);

  const maximumFractionDigits = Math.abs(num) < 0.01 ? 5 : 2;

  if (currency === 'USD') {
    return uiFormatCurrency(num, {
      maximumFractionDigits: maximumFractionDigits,
      currency: currency
    });
  }

  const formatted = formatNumber(num, {
    maximumFractionDigits: maximumFractionDigits
  });
  return `${formatted} ${currency}`;
}
