import { CryptoIcon } from '@/components';
import { Flex, formatPercentage, textShortener } from '@blockanalitica/ui';
import { useMemo } from 'react';
import styles from './PoolName.module.scss';

const sizeMap = {
  small: 16,
  medium: 20,
  large: 24
};

export default function PoolName({
  collateralSymbol,
  loanSymbol,
  lltv,
  iconSize = 30,
  short = true
}) {
  const imgSize = useMemo(
    () => (typeof iconSize === 'string' ? sizeMap[iconSize] : iconSize),
    [iconSize]
  );

  const halfSize = useMemo(() => imgSize / 2, [imgSize]);
  return (
    <Flex alignItems="center" gap="space-2xs">
      <Flex className={styles.container} style={{ paddingRight: `${halfSize}px` }}>
        {collateralSymbol ? (
          <>
            <CryptoIcon
              name={collateralSymbol}
              size={imgSize}
              className={styles.collateralIcon}
            />
            <CryptoIcon
              name={loanSymbol}
              size={imgSize}
              className={styles.loanIcon}
              style={{ left: `${halfSize}px` }}
            />
          </>
        ) : (
          <CryptoIcon name={loanSymbol} size={imgSize} />
        )}
      </Flex>
      <div>
        <Flex direction="column" gap="space-3xs" alignItems="flex-start">
          {collateralSymbol
            ? short
              ? `${textShortener(collateralSymbol, {
                  threshold: 8,
                  firstSliceLength: 6,
                  secondSliceLength: 0
                })} / `
              : `${collateralSymbol}/`
            : '[idle] '}
          {textShortener(loanSymbol, {
            threshold: 8,
            firstSliceLength: 6,
            secondSliceLength: 0
          })}
          {lltv ? (
            <span className="text-1 text-muted">LLTV: {formatPercentage(lltv)}</span>
          ) : null}
        </Flex>
      </div>
    </Flex>
  );
}
